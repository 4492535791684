import { useLayoutEffect, useRef } from 'react';

const useRunOnce = ({ fn, sessionKey }) => {
  const triggered = useRef(false);

  useLayoutEffect(() => {
    const hasBeenTriggered = sessionKey ? sessionStorage.getItem(sessionKey) : triggered.current;

    if (!hasBeenTriggered) {
      fn();
      triggered.current = true;

      if (sessionKey) {
        sessionStorage.setItem(sessionKey, 'true');
      }
    }
  }, [fn, sessionKey]);

  return null;
};

export default useRunOnce;
