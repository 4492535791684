import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';

import Modal from '../../reusable/NewModal';
import AsyncSelect from '../../reusable/Selects/AsyncSelect';
import Select from '../../reusable/Selects/Select';
import CustomButton from '../../reusable/Buttons/Button';
import Label from '../../reusable/Label';

import raterRoles from '../../../constants/raterRoles';

import { addRaters } from '../../../store/sessions/actions';
import isChineseEnv from '../../../utils/isChineseEnv';

const PAGE_SIZE = 20;

const AddRatersModal = ({ onClose, onSuccess }) => {
  const project = useSelector((state) => state.projects.activeProjectInView);
  const session = useSelector((state) => state.sessions.activeSessionInView);
  const dispatch = useDispatch();
  const [dynamicKey, setDynamicKey] = useState(0);

  const [error, setError] = useState(null);
  const [respondent, setRespondent] = useState(null);
  const [role, setRole] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectValue, setSelectValue] = useState('');

  const [selectedRespondents, setSelectedRespondents] = useState([]);

  const fetchRespondents = async (inputValue, pageIndex) => {
    try {
      const response = await apiInstance2.get(
        `/api/v2/360/projects/${project.projectId}/sessions/${session.sessionId}/available-respondents-for-raters?PageNumber=${pageIndex}&PageSize=${PAGE_SIZE}&SearchText=${inputValue}`,
      );
      return { respondents: response.data.data, totalCount: response.data.totalCount };
    } catch (e) {
      console.log('Error while fetching respondents');
    }
  };

  const loadOptions = async (inputValue, loadedOptions) => {
    const { respondents, totalCount } = await fetchRespondents(inputValue, pageIndex);
    const filtered = respondents.filter(
      (item) => !selectedRespondents.find((resp) => item.respondentId === resp.value) && item.available,
    );
    const options = filtered.map((item) => ({
      value: item.respondentId,
      label: `${isChineseEnv ? `${item.familyName || ''}${item.firstName}` : `${item.firstName} ${item.familyName}`} (${
        item.email ? item.email : I18n.t('No email')
      })`,
      name: `${isChineseEnv ? `${item.familyName || ''}${item.firstName} ` : `${item.firstName}${item.familyName} `}`,
      email: item.email,
    }));
    setPageIndex((prev) => prev + 1);
    return {
      options,
      hasMore: loadedOptions.length < totalCount - PAGE_SIZE,
    };
  };

  const onAsyncSelectChange = (data) => {
    setRespondent(data);
    setError(null);
    if (!data.email) setError(I18n.t('Make sure selected record has a valid email address added'));
  };

  const onSelectInputChange = (value) => {
    setSelectValue(value);
    setPageIndex(0);
  };

  const onRoleChange = (data) => {
    setRole(data);
  };

  const onAddRespondent = () => {
    setSelectedRespondents((prev) => [
      ...prev,
      { ...respondent, role: role.value, backendValueReference: role.backendValueReference },
    ]);
    setRespondent(null);
    setRole(null);
    setDynamicKey((prev) => prev + 1);
    setPageIndex(0);
  };

  const removeRespondent = (id) => {
    setSelectedRespondents((prev) => prev.filter((item) => item.value !== id));
    setDynamicKey((prev) => prev + 1);
  };

  const onSave = () => {
    const data = selectedRespondents.map((item) => ({ respondentID: item.value, role: item.backendValueReference }));
    dispatch(
      addRaters(project.projectId, session.sessionId, data, () => {
        onClose();
        onSuccess();
      }),
    );
  };

  const raterRolesOptions = raterRoles(project).slice(1);

  const managers = selectedRespondents.filter((item) => item.role === 'manager');
  const peers = selectedRespondents.filter((item) => item.role === 'peer');
  const directs = selectedRespondents.filter((item) => item.role === 'direct');
  const other = selectedRespondents.filter((item) => item.role === 'other');

  const renderSection = (title, respondents) =>
    Boolean(respondents.length) && (
      <Section>
        <SectionTitle>{title}</SectionTitle>
        <LabelsWrapper>
          {respondents.map((resp) => (
            <Label key={resp.value} value={resp.name} onDelete={() => removeRespondent(resp.value)} />
          ))}
        </LabelsWrapper>
      </Section>
    );

  return (
    <StyledModal title={I18n.t('Add raters')} isVisible onClose={onClose}>
      <Body>
        <SelectsWrapper>
          <StyledAsyncSelect
            placeholder={I18n.t('Search existing records')}
            loadOptions={loadOptions}
            selectProps={{
              onChange: onAsyncSelectChange,
              value: respondent,
              onInputChange: onSelectInputChange,
              inputValue: selectValue,
            }}
            error={error}
            key={dynamicKey}
          />
          <StyledSimpleSelect
            placeholder={I18n.t('Role')}
            options={raterRolesOptions}
            selectProps={{ value: role, onChange: onRoleChange }}
          />
          <CustomButton handler={onAddRespondent} disabled={!respondent || !respondent.email || !role}>
            {I18n.t('Add')}
          </CustomButton>
        </SelectsWrapper>
        <SectionsContainer>
          {renderSection((project && project.managerAlias) || I18n.t('Managers'), managers)}
          {renderSection((project && project.peerAlias) || I18n.t('Peers'), peers)}
          {renderSection((project && project.directReportAlias) || I18n.t('Direct reports'), directs)}
          {renderSection((project && project.otherAlias) || I18n.t('Other'), other)}
        </SectionsContainer>
      </Body>
      <ButtonsWrapper>
        <Button handler={onSave} disabled={!selectedRespondents.length}>
          {I18n.t('Confirm')}
        </Button>
      </ButtonsWrapper>
    </StyledModal>
  );
};

AddRatersModal.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
};

const StyledModal = styled(Modal)`
  height: 45rem;
  width: 75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SelectsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  min-width: 12rem;
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  width: 55%;
  margin: 0;
`;

const StyledSimpleSelect = styled(Select)`
  width: 25%;
  margin: 0;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 22rem;
  overflow: auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 0.8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey2};
  margin-bottom: 1rem;
`;

const LabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export default AddRatersModal;
