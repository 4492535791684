export const customThemes = {
  base: {
    defaultLanguage: 'en',
    logos: {
      white: 'https://media.genesysonline.net/gs2020container/logo-psytech.svg',
      fullColor: 'https://media.genesysonline.net/gs2020container/logo-psytech.svg',
    },
    colors: {
      menuBg: '#17A9E1',
      mediumBlue: '#008AC0',
      lightBlue: '#17A9E1',
      lightBlue1: '#DFF6FF',
      lightBlueHover: '#3DB7E6',
      darkBlue2: '#295368',
    },
  },
  ciicmc: {
    defaultLanguage: 'ch',
    switchFeatures: {
      solutionUsers: {
        disableAssesamentsBatteries: true,
        disableCreateIdealProfileFromGroup: true,
        disableAnswerSheet: true,
        disableEthnicityCategory: true,
        disableLanguageCategory: true,
      },
    },
    logos: {
      white: 'https://media.genesysonline.net/gs2020container/CMC_Logo_White.png',
      fullColor: 'https://media.genesysonline.net/gs2020container/CMC_Logo_Full_Color.png',
    },
    colors: {
      menuBg: '#1C55FE',
      mediumBlue: '#0134CB',
      lightBlue: '#1C55FE',
      lightBlue1: '#9AB3FE',
      lightBlueHover: '#7194FE',
      darkBlue2: '#547FFE',
    },
  },
};
