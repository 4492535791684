import React, { useState } from 'react';
import { array, func, number, oneOfType, string } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import Accordion from './ImportSessionsAccordion';
import CustomButton from '../../reusable/Buttons/Button';
import ImportEmptyMessage from '../../reusable/ImportEmptyMessage';

import apiInstance2 from '../../../services/apiService';
import raterRoles from '../../../constants/raterRoles';

import createToastNotification from '../../../utils/createToastNotification';
import isChineseEnv from '../../../utils/isChineseEnv';

const createImportSessionsRequestBody = (initData, selectedIds) => {
  const filteredSelves = initData.filter((selfObj) => {
    return selfObj.raters.filter((rater) => selectedIds.includes(rater.id)).length;
  });
  return filteredSelves.map((selfObj) => {
    return { ...selfObj, raters: selfObj.raters.filter((rater) => selectedIds.includes(rater.id)) };
  });
};

const ImportSessionsPreviewStep = ({ data, goNext, onClose, setImportedAmount, onSuccess, projectId }) => {
  const [selectedData, setSelectedData] = useState([]);

  const importSessions = async () => {
    try {
      const validSessions = createImportSessionsRequestBody(data, selectedData);
      const response = await apiInstance2.post(`/api/v2/360/projects/${projectId}/import/sessions`, validSessions);
      if (response.status === 200) {
        goNext();
        setImportedAmount(response.data.length);
        onSuccess();
      } else {
        throw new Error('Sessions import error');
      }
    } catch (e) {
      createToastNotification({
        title: 'Error',
        message: I18n.t('importSessionsErrorToast'),
        type: 'error',
      });
      onClose();
    }
  };

  const accordionColumns = [
    {
      title: I18n.t('Rater'),
      Cell: ({ row }) => (
        <span>
          {isChineseEnv ? `${row.familyName || ''} ${row.firstName}` : `${row.firstName} ${row.familyName || ''}`}
        </span>
      ),
    },
    {
      title: I18n.t('Role'),
      Cell: ({ row }) => (
        <span>{row.role ? raterRoles().find((item) => item.backendValueReference === row.role).label : ''}</span>
      ),
    },
    { title: I18n.t('Email'), accessor: 'email' },
    {
      title: I18n.t('Number of raters'),
      Cell: () => <span />,
    },
  ];

  const groupHeaderColumns = [
    {
      Cell: ({ row }) => (
        <span>
          {isChineseEnv
            ? `${row.familyName || ''}${row.firstName || ''}`
            : `${row.firstName || ''} ${row.familyName || ''}`}
        </span>
      ),
    },
    {
      Cell: () => <span>{I18n.t('Self')}</span>,
    },
    { title: 'Email', accessor: 'email' },
    {
      title: 'Number of raters',
      Cell: ({ row }) => <span style={{ marginLeft: '2rem' }}>{row.raters.length}</span>,
    },
  ];

  const groupedByEmail = data.reduce((acc, item) => {
    if (acc[item.id]) return acc;
    return { ...acc, [item.id]: item.raters };
  }, {});

  const headersRows = data.reduce((acc, item) => {
    if (acc[item.id]) return acc;
    return { ...acc, [item.id]: item };
  }, {});

  return (
    <>
      <ContentWrapper>
        <Accordion
          setSelectedItems={setSelectedData}
          groupedItems={groupedByEmail}
          items={data.reduce((acc, item) => [...acc, ...item.raters], [])}
          columns={accordionColumns}
          selectedItems={selectedData}
          groupHeaderColumns={groupHeaderColumns}
          headersRows={headersRows}
          itemID="id"
          customEmptyMessage={<ImportEmptyMessage />}
        />
      </ContentWrapper>
      <ButtonsWrapper>
        <StyledButton handler={importSessions} disabled={selectedData.length <= 0}>
          {I18n.t('Import')}
        </StyledButton>
      </ButtonsWrapper>
    </>
  );
};

ImportSessionsPreviewStep.propTypes = {
  data: array.isRequired,
  goNext: func.isRequired,
  onClose: func.isRequired,
  setImportedAmount: func.isRequired,
  onSuccess: func.isRequired,
  projectId: oneOfType([string, number]),
};

ImportSessionsPreviewStep.defaultProps = {
  projectId: '',
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default ImportSessionsPreviewStep;
