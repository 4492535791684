import React, { useState, useEffect } from 'react';
import { func, string, instanceOf, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { setHours, setMinutes, getMinutes, getHours, isToday, isBefore, isSameDay } from 'date-fns';
import * as allLocales from 'date-fns/locale';
import DatePicker, { registerLocale, setDefaultLocale, getDefaultLocale } from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import OutsideClickHandler from 'react-outside-click-handler';

import 'react-datepicker/dist/react-datepicker.css';

import { I18n } from 'react-redux-i18n';
import localLocalesMap from '../../../i18n/localLocalesMap';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-blue.svg';

import CustomInputWithForwardedRef from '../FormComponents/InputWithForwardedRef';

const DateTimeSelect = ({
  date,
  onDateChange,
  minDate,
  maxDate,
  inputName,
  disabled,
  className,
  inputWidth,
  warningText,
  moveLeftBy,
  error,
  popperPlacement,
}) => {
  const currentLocale = useSelector((state) => state.i18n.locale);
  const now = new Date();
  const [isCalendarVisible, setCalendarStatus] = useState(false);

  useEffect(() => {
    const defLocale = getDefaultLocale();
    const currentMappedLocale = localLocalesMap[currentLocale] || currentLocale;
    if (defLocale !== currentMappedLocale) {
      registerLocale(currentMappedLocale, allLocales[currentMappedLocale]);
      setDefaultLocale(currentMappedLocale);
    }
  }, []);

  const toggleCalendar = () => {
    if (disabled) return;
    setCalendarStatus((prev) => !prev);
  };
  const onChange = (newDate) => {
    let finalDate = newDate;
    if (isToday(newDate) && isBefore(newDate, now))
      finalDate = setHours(setMinutes(newDate, getMinutes(now)), getHours(now));
    onDateChange(finalDate);
    if (isSameDay(newDate, date)) setCalendarStatus(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setCalendarStatus(false)}>
      <DateTimeSelectContainer
        className={className}
        disabled={disabled}
        isOpen={isCalendarVisible}
        moveLeftBy={moveLeftBy}
      >
        <InputWrapper id="ICON_REF">
          <Icon isSelected={date !== null} />
        </InputWrapper>
        <DatePicker
          popperPlacement={popperPlacement}
          placeholderText={inputName}
          disabled={disabled}
          selected={date}
          minDate={minDate}
          maxDate={maxDate || new Date(moment(new Date()).add(6, 'months'))}
          minTime={minDate && date && isToday(date) ? setMinutes(now, getMinutes(minDate)) : null}
          maxTime={minDate && date && isToday(date) ? setHours(setMinutes(now, 45), 23) : null}
          onChange={onChange}
          timeInputLabel={I18n.t('Time:')}
          timeCaption={I18n.t('Time')}
          dateFormat="yyyy/MM/dd, p"
          showTimeSelect
          // inline
          disabledKeyboardNavigation
          timeIntervals={15}
          customInput={
            <StyledInput
              error={error}
              inputName={inputName}
              inputWidth={inputWidth}
              onContainerClick={toggleCalendar}
            />
          }
        />
        {warningText && (
          <WarningContainer>
            <WarningIcon data-tip data-for="warning" />
            <StyledTooltip id="warning" place="top" offset={{ left: 140, top: 5 }} arrowColor="rgba(0,0,0,0)">
              {warningText}
            </StyledTooltip>
          </WarningContainer>
        )}
      </DateTimeSelectContainer>
    </OutsideClickHandler>
  );
};

DateTimeSelect.propTypes = {
  date: instanceOf(Date),
  minDate: instanceOf(Date),
  maxDate: instanceOf(Date),
  onDateChange: func.isRequired,
  inputName: string.isRequired,
  inputWidth: string,
  className: string,
  moveLeftBy: string,
  disabled: bool,
  warningText: string,
  error: string,
  popperPlacement: string,
};

DateTimeSelect.defaultProps = {
  date: null,
  minDate: null,
  maxDate: null,
  className: '',
  moveLeftBy: undefined,
  disabled: false,
  warningText: '',
  inputWidth: '',
  error: '',
  popperPlacement: '',
};

const InputWrapper = styled.div`
  position: relative;
`;

const Icon = styled(CalendarIcon)`
  position: absolute;
  left: 1rem;
  top: ${(props) => (props.isSelected ? '28px' : '19px')};
  transform: translateY(-50%);
`;

const StyledInput = styled(CustomInputWithForwardedRef)`
  > div {
    border: 1px solid ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.grey2)};
  }
  input {
    margin-left: 2.5rem;
    border-radius: ${(props) => (props.isCalendarVisible ? '7px 7px 0 0' : '7px')};
  }

  & {
    ${(props) =>
      props.inputWidth &&
      css`
        width: ${(props) => props.inputWidth} !important;
      `}
  }
`;

const DateTimeSelectContainer = styled.div`
  position: relative;
  ${(props) => props.isOpen && 'z-index: 10'};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  .react-datepicker {
    font-size: 1em;
    display: flex;
  }

  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
    outline: none;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 9rem !important;
  }
`;

const WarningContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;
const StyledTooltip = styled(ReactTooltip)`
  width: 32rem;
`;

export default DateTimeSelect;
