import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setLocale, I18n } from 'react-redux-i18n';
import storageService from '../../services/storageService';

import CustomSelect from './Selects/Select';

import langList from '../../constants/selectableLanguages';
import isChineseEnv from '../../utils/isChineseEnv';
import useRunOnce from '../../hooks/useRunOnce';

const StyledCustomSelect = styled(CustomSelect)`
  width: calc(100% - 4rem);
  margin-top: 2rem;
`;

const SelectLanguage = () => {
  const defaultLang = storageService.getItem('psLang');
  const [userLang, setUserLang] = useState(defaultLang);
  const locale = useSelector((state) => state.i18n.locale);
  const dispatch = useDispatch();

  useRunOnce({
    fn: () => {
      if (isChineseEnv) {
        window.location.reload(false);
        storageService.setItem('psLang', 'ch');
        setUserLang('ch');
        setLocale('ch');
      } else {
        window.location.reload(false);
        storageService.setItem('psLang', 'en');
        setUserLang('en');
        setLocale('en');
      }
    },
    sessionKey: 'changeMeAndFnWillRerun',
  });

  useLayoutEffect(() => {
    if (!langList.includes(userLang)) {
      if (isChineseEnv) {
        storageService.setItem('psLang', 'ch');
        setUserLang('ch');
        setLocale('ch');
      } else {
        storageService.setItem('psLang', defaultLang);
        setUserLang(defaultLang);
        setLocale(defaultLang);
      }
    }

    return () => {};
  }, [userLang]);

  const defaultSelectedLang = langList.find(
    (lang) => lang.value.toLowerCase() === defaultLang || lang.value.toLowerCase() === locale,
  );

  return (
    <StyledCustomSelect
      placeholder={I18n.t('Language')}
      options={langList}
      selectProps={{
        value: defaultSelectedLang,
        onChange: (option) => {
          if (option.value) {
            const lang = option.value.toLowerCase();

            dispatch(setLocale(lang));
            window.location.reload(false);
          }
        },
        menuPlacement: 'auto',
      }}
    />
  );
};

export default SelectLanguage;
