import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PageWrapper from '../PageWrapper';
import SETTINGS_MENU from './settings.const';

const Settings = () => {
  const { user, partnerInfo } = useSelector((state) => state.user);
  const [menu, setMenu] = useState(SETTINGS_MENU({ user, shouldDisableFeature: partnerInfo?.switchFeatures }));

  useLayoutEffect(() => {
    const initialMenu = SETTINGS_MENU({ user, shouldDisableFeature: partnerInfo?.switchFeatures || false });
    setMenu([...initialMenu]);
    return () => {};
  }, [partnerInfo]);

  return (
    <PageWrapper title={I18n.t('Settings')}>
      <RootNav>
        {menu.map(({ to, label, Icon, nested, disabled }) => (
          <li key={`${to}${label}`}>
            <StyledItem disabled={disabled}>
              <Icon />
              <span>{I18n.t(label)}</span>
            </StyledItem>
            {nested && (
              <NestedNav>
                {nested.map(({ to, label }) => (
                  <div key={to}>
                    <StyledLink disabled={disabled} to={to}>
                      {I18n.t(label)}
                    </StyledLink>
                  </div>
                ))}
              </NestedNav>
            )}
          </li>
        ))}
      </RootNav>
    </PageWrapper>
  );
};

Settings.propTypes = {};

export default Settings;

const StyledItem = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #295368;
  text-decoration: none;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  span {
    margin-left: 15px;
  }

  svg {
    fill: ${(props) => props.theme.colors.menuBg};
  }
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #295368;
  text-decoration: none;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  span {
    margin-left: 15px;
  }
`;

const RootNav = styled.ul`
  margin-top: 20px;
  list-style-type: none;

  li + li {
    margin-top: 23px;
  }
`;

const NestedNav = styled.ul`
  padding-left: 35px;

  ${StyledLink} {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #008ac0;
    margin-top: 8px;
  }
`;
