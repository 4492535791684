import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { merge } from 'lodash';
import storageService from '../services/storageService';

import theme from '../theme';
import { customThemes } from '../custom_themes';
import { setCustomThemes } from '../store/user/actions';

const useCustomTheme = ({ partnerPrefix }) => {
  const dispatch = useDispatch();
  const [customTheme, setCustomTheme] = useState(theme);

  const getHostCustomTheme = (hostID) => {
    const isSubDomain = partnerPrefix.includes('-');
    const hostName = isSubDomain ? hostID.split('-')[0] : hostID;
    const hasCustomTheme = Object.keys(customThemes).includes(hostName);
    return hasCustomTheme ? customThemes[hostName] : customThemes.base;
  };
  useLayoutEffect(() => {
    storageService.removeItem('customColors');
    storageService.removeItem('customLogos');
    const domainURL = window.location.hostname.split('.')[0];
    const { logos, colors, switchFeatures, defaultLanguage = {} } = getHostCustomTheme(domainURL);
    dispatch(setCustomThemes({ colors, logos, switchFeatures, defaultLanguage }));
    storageService.setItem('customColors', JSON.stringify(colors));
    storageService.setItem('customLogos', JSON.stringify(logos));

    const storageCustomColors = JSON.parse(storageService.getItem('customColors'));

    const newTheme = merge({ ...customTheme }, { colors: storageCustomColors });
    setCustomTheme(newTheme);

    return () => {};
  }, []);

  return { customTheme };
};

export default useCustomTheme;
