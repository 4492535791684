import React, { useState, useCallback, useEffect } from 'react';
import { func, array } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { I18n } from 'react-redux-i18n';
import Modal from '../../reusable/NewModal';
import Steps from '../../reusable/Steps';
import CustomButton from '../../reusable/Buttons/Button';
import Spinner from '../../reusable/Spinner';

import apiInstance2 from '../../../services/apiService';
import RespondentsStep from '../CreateInvite/RespondentsStep';
import AssessmentSelectionStep from './AssessmentSelectionStep';
import AnswersStep from './AnswersStep';

import useStep from '../../../hooks/useStep';
import createToastNotification from '../../../utils/createToastNotification';

import { fetchAnswerSheetTestsInfo } from '../../../store/respondents/actions';
import { selectAnswerSheetAssessments, selectAnswerSheetLanguages } from '../../../store/respondents/selectors';
import { resolveAssessmentName } from '../../../utils/assessmentsNames';
import { findAssessment } from '../CreateInvite';
import isChineseEnv from '../../../utils/isChineseEnv';

const createSteps = (respondents) =>
  respondents.length > 1 ? ['respondents', 'assessment', 'answers'] : ['assessment', 'answers'];

const findLanguage = (languages, defaultLang) => languages.find((item) => item.code === (defaultLang || 'EN'));

const generateItemsList = (answers, questionsCount) => {
  const sortedAnswers = sortBy(answers, 'itemNumber');
  return new Array(questionsCount).fill(null).map((item, index) => {
    const answer = sortedAnswers.find((answer) => answer.itemNumber - 1 === index);
    return answer?.answerNumber || item;
  });
};

const AnswerSheet = ({ onClose, respondents, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const steps = createSteps(respondents);
  const { step, goNext } = useStep({ steps });
  const [isLoading, setLoadingState] = useState(false);

  const assessments = useSelector(selectAnswerSheetAssessments);
  const languages = useSelector(selectAnswerSheetLanguages);
  const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [assessment, setAssessment] = useState(findAssessment(assessments, language));
  // to keep track of who (which respondent) is currently getting his assessment completed (answered)
  const [activeRespondentIndex, setActiveRespondentIndex] = useState(0);
  // if single test - only one item is present, if battery - array of all the tests that battery includes
  const [questionsSets, setQuestionsSets] = useState([]);
  // which of the tests (of all battery tests) is being completed right now
  const [activeQuestionsSetStep, setActiveQuestionsSetStep] = useState(0);
  // information about currently answered questions; needed here for proper buttons states and data submission
  const [answersCopy, setAnswersCopy] = useState([]);

  useEffect(() => {
    setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
  }, [languages.length]);

  useEffect(() => {
    dispatch(fetchAnswerSheetTestsInfo());
    // if (!assessments.length || !languages.length) dispatch(fetchAnswerSheetTestsInfo());
  }, []);

  useEffect(() => {
    if (assessments) {
      setAssessment(findAssessment(assessments, language));
    }
  }, [assessments, language]);

  const moreInBattery = activeQuestionsSetStep < questionsSets.length - 1;
  const moreRespondents = activeRespondentIndex < respondents.length - 1;

  const closeModal = ({ shouldResetOnClose }) => {
    onClose();
    if (shouldResetOnClose) {
      onSuccess();
    }
  };

  const submitTestResults = async () => {
    const questionSet = questionsSets[activeQuestionsSetStep];
    const data = {
      respondentID: respondents[activeRespondentIndex].respondentID,
      langID: language.code,
      batteryID: assessment.batteryID,
      testID: questionsSets[activeQuestionsSetStep].testID,
      itemsList: generateItemsList(answersCopy, questionSet.numberOfItems),
    };
    setLoadingState(true);
    const { firstName, familyName } = respondents[activeRespondentIndex];
    const { testID } = questionsSets[activeQuestionsSetStep];
    try {
      const response = await apiInstance2.post('/api/v2/results/administer', data);
      if (response.status === 200) {
        const respondentInfo = isChineseEnv
          ? `${familyName || ''}${firstName} ${testID}`
          : `${firstName} ${familyName} ${testID}`;
        createToastNotification({
          message: `${I18n.t('resultsSuccessToast', { respondentInfo })}`,
        });
        if (moreInBattery) return setActiveQuestionsSetStep((prev) => prev + 1);
        if (moreRespondents) {
          setActiveRespondentIndex((prev) => prev + 1);
          setActiveQuestionsSetStep(0);
        }
        if (!moreInBattery && !moreRespondents) {
          closeModal({ shouldResetOnClose: true });
        }
      }
    } catch (e) {
      console.log('error while submitting assessment answers', e);
      createToastNotification({
        type: 'error',
        message: `${I18n.t('resultsSavingErrorToast', {
          respondentname: isChineseEnv ? `${familyName || ''}${firstName}` : `${firstName} ${familyName}`,
          assessment: testID,
        })}`,
      });
    } finally {
      setLoadingState(false);
    }
  };

  const keyListener = (e) => {
    if (e.code === 'Enter' && steps[step] === 'answers') {
      const currentTestInfo = questionsSets[activeQuestionsSetStep];
      if (currentTestInfo.hasOptionalAnswers || answersCopy.length === currentTestInfo.numberOfItems)
        submitTestResults();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  }, [step, activeQuestionsSetStep, answersCopy]);

  const fetchAssessmentQuestions = async () => {
    const isBattery = Boolean(assessment.batteryID);
    const query = isBattery
      ? `batteryID=${encodeURIComponent(assessment.batteryID)}`
      : `testID=${encodeURIComponent(assessment.testID)}`;
    setLoadingState(true);
    try {
      const response = await apiInstance2.get(`/api/v2/results/administer/tests/answer-sheets?${query}`);
      if (response.status === 200) {
        setQuestionsSets(response.data);
        goNext();
      }
    } catch (e) {
      console.log('error while fetching assessment questions', e);
      createToastNotification({ type: 'error', message: I18n.t('assessmentDataFailToast') });
    } finally {
      setLoadingState(false);
    }
  };

  const resolveModalTitle = (step) => {
    if (step === 'answers') {
      const { firstName, familyName } = respondents[activeRespondentIndex];
      const { testID } = questionsSets[activeQuestionsSetStep];
      return isChineseEnv
        ? `${I18n.t('Administer answer sheet for')} ${familyName || ''}${firstName}: ${testID}`
        : `${I18n.t('Administer answer sheet for')} ${firstName} ${familyName}: ${testID}`;
    }
    const respondentName =
      respondents.length && isChineseEnv
        ? `${respondents[0].familyName || ''}${respondents[0].firstName}`
        : `${respondents[0].firstName} ${respondents[0].familyName}`;
    return respondents.length > 1
      ? `${I18n.t('Administer answer sheet for selected respondents')} (${respondents.length})`
      : `${I18n.t('Administer answer sheet for')} ${respondentName}`;
  };

  const onValueChange = (name, value) => {
    if (name === 'language') setLanguage(value);
    if (name === 'assessment') setAssessment(value);
  };

  const renderStep = (step) => {
    switch (step) {
      case 'respondents':
        return <RespondentsStep respondents={respondents} disableEmailValidation />;
      case 'assessment':
        return (
          <AssessmentSelectionStep
            assessments={assessments}
            languages={languages}
            language={language}
            assessment={
              assessment && {
                ...assessment,
                label: resolveAssessmentName(assessment),
                value: assessment.batteryID ? assessment.batteryID : assessment.testID,
              }
            }
            onValueChange={onValueChange}
          />
        );
      case 'answers':
        return (
          <AnswersStep
            questionsSetInfo={questionsSets[activeQuestionsSetStep]}
            setParentAnswers={setAnswersCopy}
            respondent={respondents[activeRespondentIndex]}
          />
        );
      default:
        return <RespondentsStep respondents={respondents} />;
    }
  };

  const resolveButtonText = (step) => {
    switch (step) {
      case 'respondents':
        return I18n.t('Next');
      case 'assessment':
        return I18n.t('Next');
      case 'answers': {
        return I18n.t('Submit');
      }
      default:
        return I18n.t('Next');
    }
  };

  const isNextButtonDisabled = () => {
    if (steps[step] === 'assessment') return !assessment;
    if (steps[step] === 'answers') {
      const currentTestInfo = questionsSets[activeQuestionsSetStep];
      return currentTestInfo.hasOptionalAnswers ? false : answersCopy.length < currentTestInfo.numberOfItems;
    }
    return false;
  };

  const onNextButtonClick = useCallback(() => {
    const currentStep = steps[step];
    if (currentStep === 'respondents') goNext();
    if (currentStep === 'assessment') fetchAssessmentQuestions();
    if (currentStep === 'answers') submitTestResults();
  }, [step, assessment, steps]);

  return (
    <StyledModal isVisible onClose={closeModal} title={resolveModalTitle(steps[step])}>
      <Steps steps={steps} stepIndex={step} />
      <Spinner
        isLoading={isLoading}
        text={steps[step] === 'assessment' ? 'Loading assessment data...' : 'Submitting results...'}
      />
      <Content>{renderStep(steps[step])}</Content>
      <Footer>
        {steps[step] === 'answers' && (
          <FooterRespondentsInfo>
            <span>{I18n.t('Respondent')}</span>
            <span>
              {activeRespondentIndex + 1} / {respondents.length}
            </span>
          </FooterRespondentsInfo>
        )}
        {steps[step] !== 'invitation' && (
          <Button handler={onNextButtonClick} disabled={isNextButtonDisabled() || isLoading}>
            {resolveButtonText(steps[step])}
          </Button>
        )}
      </Footer>
    </StyledModal>
  );
};

AnswerSheet.propTypes = {
  onClose: func.isRequired,
  respondents: array.isRequired,
  onSuccess: func.isRequired,
};

const StyledModal = styled(Modal)`
  min-width: 120rem;
  max-width: 140rem;
  height: 76rem;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1.5rem;
`;

const Footer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  min-width: 15rem;
`;

const FooterRespondentsInfo = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};

  span:last-child {
    font-weight: bold;
  }
`;

export default AnswerSheet;
