import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace, push } from 'redux-first-history';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Redirect } from '@reach/router';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { fetchUserRegion, loginUser } from '../store/user/actions';

import storageService from '../services/storageService';

import Spinner from './reusable/Spinner';
import LogoSVG from './reusable/Logo_SVG';
import useCustomTheme from '../hooks/useCustomTheme';

const LoginFormSchema = Yup.object().shape({
  password: Yup.string().required(I18n.t('Password is required')),
});

const LoginPassword = () => {
  const domainURL = window.location.hostname.split('.')[0];
  useCustomTheme({ partnerPrefix: domainURL });
  const dispatch = useDispatch();
  const { isLoading, partnerInfo } = useSelector((state) => state.user);
  const searchParams = new URLSearchParams(document.location.search);
  const userEmail = searchParams.get('email').toString();
  const langId = searchParams.get('langId');

  const [customLogo, setCustomLogo] = useState('');

  useEffect(() => {
    storageService.setItem('psLang', langId);
  }, [userEmail]);

  useLayoutEffect(() => {
    const storageCustomLogos = partnerInfo?.logos || '';
    const partnerLogo = storageCustomLogos?.fullColor;
    setCustomLogo(partnerLogo);

    dispatch(fetchUserRegion({ email: userEmail }, () => {}));

    return () => {};
  }, [partnerInfo]);

  const { handleSubmit, handleChange, values, errors, setErrors } = useFormik({
    validationSchema: LoginFormSchema,
    validateOnChange: false,
    initialValues: {
      password: '',
    },
    onSubmit: ({ password }) => {
      dispatch(
        loginUser({ userEmail, password }, (err) => {
          if (err) {
            setErrors({ major: err, password: true });
          } else dispatch(replace('/respondents'));
        }),
      );
    },
  });
  const onInputChange = (event) => {
    handleChange(event);
    setErrors({ password: '', major: '' });
  };

  if (!userEmail) {
    return <Redirect noThrow to="/login" />;
  }

  return (
    <BgContainer>
      <Spinner isLoading={isLoading} />
      <LoginContainer>
        {/* {!customLogo ? <StyledLogo /> : <LogoImage src={customLogo} alt="Custom Logo" />} */}
        {customLogo?.includes('psytech.svg') ? <StyledLogo /> : <LogoImage src={customLogo} alt="Custom Logo" />}
        <StyledForm onSubmit={handleSubmit}>
          <InputsWrapper>
            <DisabledInputContainer>
              <InputLabel>{I18n.t('Email')}</InputLabel>
              <StyledInput type="text" name="email" value={userEmail} disabled />
            </DisabledInputContainer>
            <InputContainer>
              <InputLabel error={errors.password}>{I18n.t('Password')}</InputLabel>
              <StyledInput
                error={errors.password}
                type="password"
                name="password"
                id="password"
                onChange={onInputChange}
                value={values.password}
              />
            </InputContainer>
          </InputsWrapper>
          <Error>{errors.major || errors.password}</Error>
          <SubmitButton type="submit">{I18n.t('LoginSubmit')}</SubmitButton>
        </StyledForm>
        <ForgotPasswordLink onClick={() => dispatch(push('/login'))}>{I18n.t('SwitchUser')}</ForgotPasswordLink>
        <ForgotPasswordLink onClick={() => dispatch(push('/forgot-password'))}>
          {I18n.t('ForgotPassword')}
        </ForgotPasswordLink>
      </LoginContainer>
    </BgContainer>
  );
};

const BgContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`;

const LoginContainer = styled.div`
  display: flex;
  width: 42rem;
  flex-direction: column;
  padding: 30px 60px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 44px #00000012;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.span`
  color: ${(props) => (props.error ? 'red' : props.theme.colors.text)};
  font-size: 12px;
  margin-left: 15px;
  margin-bottom: 5px;
  transition: all 0.2s;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 300px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid ${(props) => (props.error ? 'red' : props.theme.colors.text)};
  border-radius: 20px;
  padding: 13px 15px;
  opacity: 0.5;
  transition: all 0.2s;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  :hover {
    ${StyledInput} {
      border-color: ${(props) => (props.error ? 'red' : props.theme.colors.menuBg)};
    }
    ${InputLabel} {
      color: ${(props) => (props.error ? 'red' : props.theme.colors.menuBg)};
    }
  }
`;

const DisabledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  padding: 15px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.menuBg};
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s;
  border-radius: 20px;
  box-shadow: 0 0 21px #00000014;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.menuBg};
  }
`;

const ForgotPasswordLink = styled.span`
  margin-top: 10px;
  font-size: ${(props) => props.theme.fontSizes.default};
  color: ${(props) => props.theme.colors.mediumBlue};
  text-align: center;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  color: red;
  height: 18px;
  font-size: 12px;
  margin: 0 0 10px 0;
`;

const LogoImage = styled.img`
  height: 15rem;
  color: ${(props) => props.theme.colors.menuBg};
  scale: 0.4;
`;

const StyledLogo = styled(LogoSVG)`
  margin-bottom: 30px;
  margin-left: 1rem;
  min-height: 35px;

  svg {
    fill: ${(props) => props.theme.colors.menuBg};
    height: 80px;
    scale: 1.3;
  }
`;

export default LoginPassword;
