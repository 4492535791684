import React, { useEffect } from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as CloudsImage } from '../../assets/images/warning-clouds.svg';

const ErrorMessage = ({ onConfirm }) => {
  useEffect(() => {
    setTimeout(() => {
      onConfirm();
    }, 2000);
  }, []);
  return (
    <ErrorWrapper>
      <CloudsImage />
      <Message>
        {I18n.t('Oops, something went wrong')}.
        <br />
        <br />
        {I18n.t('You will be redirected shortly')}.
      </Message>
    </ErrorWrapper>
  );
};

ErrorMessage.propTypes = {
  onConfirm: func.isRequired,
};

const ErrorWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
`;

const Message = styled.p`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin: 0 0 2rem 0;
  text-align: center;
`;

export default ErrorMessage;
