import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import styled from 'styled-components';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import CustomInput from '../../reusable/FormComponents/Input';
import Spinner from '../../reusable/Spinner';
import * as actions from '../../../table/actionsList';
import EditorSummernote from '../../reusable/TextEditor/EditorSummernote';

import {
  fetchWelcomeTextsVariables,
  fetchWelcomeTextById,
  setActiveWelcomeText,
  createWelcomeText,
  editWelcomeText,
} from '../../../store/settings/welcome-texts/actions';

const welcomeTextScreenTableActions = (isEdit, handler, hasDataChanged) => [
  {
    name: actions.CREATE_WELCOME_TEXT,
    label: I18n.t('Save'),
    handler,
    isDisabled: isEdit && !hasDataChanged,
  },
];

const WelcomeTextScreen = ({ welcomeTextId }) => {
  const dispatch = useDispatch();
  const activeWelcomeText = useSelector((state) => state.welcomeTexts.activeWelcomeText);
  const [isLoading, setLoadingStatus] = useState(false);
  const [welcomeTextName, setWelcomeTextName] = useState('');
  const [welcomeTextBody, setWelcomeTextBody] = useState('');
  const [errors, setErrors] = useState({
    welcomeTextName: '',
    welcomeTextBody: '',
  });
  const goBackToWelcomeTexts = () => dispatch(push('/settings/welcome-texts'));

  useEffect(() => {
    dispatch(fetchWelcomeTextsVariables());
    if (welcomeTextId) {
      setLoadingStatus(true);
      dispatch(fetchWelcomeTextById(welcomeTextId, () => setLoadingStatus(false)));
    }
    return () => dispatch(setActiveWelcomeText(null));
  }, []);

  useEffect(() => {
    if (activeWelcomeText) {
      setWelcomeTextName(activeWelcomeText.name);
      setWelcomeTextBody(activeWelcomeText.welcomeText);
    }
  }, [activeWelcomeText]);

  const onTemplateBodyChange = (value) => {
    setWelcomeTextBody(value);
    setErrors((prev) => ({ ...prev, welcomeTextBody: '' }));
  };

  const hasDataChanged = () => {
    if (!activeWelcomeText) return false;
    return activeWelcomeText.name !== welcomeTextName || activeWelcomeText.welcomeText !== welcomeTextBody;
  };

  const isDataValid = () => {
    const list = [
      { name: 'welcomeTextName', value: welcomeTextName },
      { name: 'welcomeTextBody', value: welcomeTextBody },
    ];
    let isInvalid;
    const errors = {};
    list.forEach((item) => {
      if (!item.value) {
        isInvalid = true;
        errors[item.name] = I18n.t('Field is required');
      }
    });
    setErrors(errors);
    return !isInvalid;
  };

  const onSaveClick = () => {
    const isValid = isDataValid();
    if (!isValid) return;
    const data = {
      name: welcomeTextName,
      welcomeText: welcomeTextBody,
      ...(welcomeTextId && { templateID: Number(welcomeTextId) }),
    };
    const method = welcomeTextId ? editWelcomeText : createWelcomeText;
    dispatch(
      method(data, () => {
        goBackToWelcomeTexts();
      }),
    );
  };

  return (
    <PageWrapper
      title={welcomeTextId ? I18n.t('Edit welcome text') : I18n.t('Add new welcome text')}
      backButtonHandler={goBackToWelcomeTexts}
      buttons={welcomeTextScreenTableActions(Boolean(welcomeTextId), onSaveClick, hasDataChanged())}
    >
      <Spinner isLoading={isLoading} full />
      <Container>
        <Input
          inputName={I18n.t('Template name')}
          name="templateName"
          value={welcomeTextName}
          onChange={(e) => setWelcomeTextName(e.target.value)}
          error={errors.welcomeTextName}
        />
        <EditorSummernote
          initialValue={activeWelcomeText && activeWelcomeText.welcomeText}
          onChange={onTemplateBodyChange}
          error={errors.welcomeTextBody}
        />
      </Container>
    </PageWrapper>
  );
};

WelcomeTextScreen.propTypes = {
  welcomeTextId: string,
};

WelcomeTextScreen.defaultProps = {
  welcomeTextId: '',
};

const Container = styled.div`
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
`;

const Input = styled(CustomInput)`
  margin-bottom: 3rem;
  width: 70rem;
`;

export default WelcomeTextScreen;
