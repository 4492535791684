import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { string } from 'prop-types';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';

import PageWrapper from '../PageWrapper';
import Spinner from '../reusable/Spinner';
import Information from './Information';
import RespondentReports from './RespondentReports';
import RespondentTests from './RespondentTests';
import RespondentInvites from './RespondentInvites';
import RespondentGroups from './RespondentGroups';
import TableTabs from '../reusable/TableTabs';

import { deleteInvites } from '../../store/invites/actions';
import { deleteReports, markReportsAsViewed } from '../../store/reports/actions';
import { editRespondent, deleteRespondentResults, fetchRespondentById } from '../../store/respondents/actions';
import isChineseEnv from '../../utils/isChineseEnv';

const EditRespondent = ({ respondentId }) => {
  const respondent = useSelector((state) => state.respondents.respondentToEdit);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const memoizedEditRespondent = useCallback((id, data, cb) => dispatch(editRespondent(id, data, cb)), [dispatch]);
  const memoizedDeleteInvites = useCallback((data, cb) => dispatch(deleteInvites(data, cb)), [dispatch]);
  const memoizedDeleteRespondentResults = useCallback(
    (data, cb) => dispatch(deleteRespondentResults(data, respondent.respondentID, cb)),
    [dispatch, respondent],
  );
  const memoizedDeleteReports = useCallback((data, cb) => dispatch(deleteReports(data, cb)), [dispatch]);
  const memoizedMarkReportsAsViewed = useCallback((reportsIds) => dispatch(markReportsAsViewed(reportsIds)), [
    dispatch,
  ]);

  const [isInfoFormDirty, setInfoFormDirtyStatus] = useState(false);
  const [isConfirmModalVisible, setConfirmModalState] = useState(false);
  const [tabIndexToNavigate, seTabIndexToNavigate] = useState(0);
  const [isLoading, setLoadingStatus] = useState(true);

  const fetchFunc = useCallback(() => {
    dispatch(
      fetchRespondentById(respondentId, () => {
        setLoadingStatus(false);
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    fetchFunc();
  }, []);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const onTabClick = (tab, index) => {
    if (isInfoFormDirty) {
      seTabIndexToNavigate(index);
      setConfirmModalState(true);
    } else setActiveTabIndex(index);
  };

  const proceedTabNavigation = () => setActiveTabIndex(tabIndexToNavigate);

  const renderMainContent = (respondent) => {
    switch (activeTabIndex) {
      case 0:
        return (
          <Information
            respondent={respondent}
            editRespondent={memoizedEditRespondent}
            dirtyStateHandler={setInfoFormDirtyStatus}
            isConfirmModalVisible={isConfirmModalVisible}
            proceedTabNavigation={proceedTabNavigation}
            closeConfirmModal={() => setConfirmModalState(false)}
          />
        );
      case 1:
        return (
          <RespondentInvites
            deleteInvites={memoizedDeleteInvites}
            respondentId={respondentId}
            fetchRespondent={fetchFunc}
          />
        );
      case 2:
        return (
          <RespondentTests
            tests={respondent.doneTests}
            deleteTests={memoizedDeleteRespondentResults}
            respondent={respondent}
          />
        );
      case 3:
        return (
          <RespondentReports
            reports={respondent.reports}
            deleteReports={memoizedDeleteReports}
            markReportsAsViewed={memoizedMarkReportsAsViewed}
            fetchRespondent={fetchFunc}
          />
        );
      case 4:
        return <RespondentGroups respondent={respondent} />;
      default:
        return null;
    }
  };

  const { invites = [], reports = [], groups = [], doneTests = [] } = respondent || {};

  const tabs = useMemo(() => {
    return [
      {
        label: I18n.t('Respondent Information'),
        name: 'info',
      },
      {
        label: `${I18n.t('Invites')} (${invites.length})`,
        name: 'invites',
      },
      {
        label: `${I18n.t('Assessments')} (${doneTests.length})`,
        name: 'assessments',
      },
      ...(user?.userType === 3
        ? []
        : [
            {
              label: `${I18n.t('Reports')} (${reports.length})`,
              name: 'reports',
            },
          ]),
      {
        label: `${I18n.t('Groups')} (${groups.length})`,
        name: 'groups',
      },
    ];
  }, [invites.length, reports.length, groups.length, doneTests.length, user]);

  if (isLoading) return <Spinner isLoading={isLoading} />;
  const respondentName = isChineseEnv
    ? `${respondent.familyName || ''} ${respondent.firstName}`
    : `${respondent.firstName} ${respondent.familyName || ''}`;

  const onBackBtnClick = () => {
    dispatch(push('/respondents'));
  };

  return (
    <PageWrapper title={respondentName} backButtonHandler={onBackBtnClick}>
      <Content>
        <TableTabs activeTab={tabs[activeTabIndex]} tabs={tabs} clickHandler={onTabClick} />
        {renderMainContent(respondent)}
      </Content>
    </PageWrapper>
  );
};

EditRespondent.propTypes = {
  respondentId: string,
};

EditRespondent.defaultProps = {
  respondentId: undefined,
};

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
`;

export default EditRespondent;
