import React from 'react';
import { func, array, string, oneOf } from 'prop-types';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-white.svg';
import isChineseEnv from '../../../utils/isChineseEnv';

export const findUniqueTests = (tests) => {
  const used = [];
  return tests.filter((test) => {
    if (used.includes(test.testID)) return false;
    used.push(test.testID);
    return true;
  });
};

const OldRespondentsStep = ({ respondents, onValueChange, className, tableTitle, secondColumn }) => {
  const onRowClick = (respondentId) => {
    onValueChange(
      'respondents',
      respondents.map((item) => ({
        ...item,
        isChecked: item.respondentID === respondentId ? !item.isChecked : item.isChecked,
      })),
    );
  };

  return (
    <Container className={className}>
      <TableTitle>{tableTitle}</TableTitle>
      <RespondentsList>
        {respondents.map((resp) => {
          const uniqueTests = findUniqueTests(resp.doneTests);
          return (
            <Item onClick={() => onRowClick(resp.respondentID)} isActive={resp.isChecked} key={resp.respondentID}>
              <CheckboxWrapper>
                <Checkbox type="checkbox" isActive={resp.isChecked} onClick={() => {}}>
                  {resp.isChecked && <CheckIcon />}
                </Checkbox>
                <span>
                  {isChineseEnv ? `${resp.familyName}${resp.firstName}` : `${resp.firstName} ${resp.familyName}`}
                </span>
              </CheckboxWrapper>
              {secondColumn === 'email' ? (
                <span>{resp.email}</span>
              ) : (
                <LabelsWrapper>
                  {uniqueTests.map((test, i) => (
                    <Label isSelected={resp.isChecked} key={test.testID}>
                      {test.testID}
                      {i === uniqueTests.length - 1 ? '' : ','}
                    </Label>
                  ))}
                </LabelsWrapper>
              )}
            </Item>
          );
        })}
      </RespondentsList>
    </Container>
  );
};

OldRespondentsStep.propTypes = {
  respondents: array.isRequired,
  onValueChange: func.isRequired,
  className: string,
  tableTitle: string,
  secondColumn: oneOf(['email', 'tests']).isRequired,
};

OldRespondentsStep.defaultProps = {
  className: '',
  tableTitle: I18n.t('Respondents'),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableTitle = styled.h5`
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightBlue};
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const RespondentsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 43rem;
  max-height: 43rem;
  overflow: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  justify-content: space-between;

  cursor: default;
  padding: 10px;

  background-color: ${(props) => (props.isActive ? 'rgba(64, 196, 255, 0.4)' : props.theme.colors.white)};

  border-bottom: 1px solid rgba(241, 241, 241, 0.5);
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.div`
  width: 17px;
  height: 17px;
  min-height: 17px;
  min-width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.white)};
  border: ${(props) => (props.isActive ? '1px solid transparent' : `1px solid ${props.theme.colors.text}`)};
  border-radius: 2px;
  margin-right: 10px;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  position: relative;
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-right: 3px;
  margin-bottom: 3px;
`;

export default OldRespondentsStep;
