import React, { useState, useEffect, useMemo } from 'react';
import { func, array } from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import Modal from '../../reusable/NewModal';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import Spinner from '../../reusable/Spinner';

import { selectAllAssessments, selectCategories } from '../../../store/tests/selectors';
import { createQuestionnaireSession } from '../../../store/respondents/actions';

import { selectAvailableLanguages, selectAvailableMappedBiodataTemplates } from '../../../store/user/selectors';

import CustomSelect from '../../reusable/Selects/Select';
import useAvailableTests from '../../../hooks/useAvailableTests';
import RadioButton from '../../reusable/FormComponents/RadioButton';
import CustomButton from '../../reusable/Buttons/Button';
import createTestsOptions from '../CreateInvite/createInviteUtils';
import { findLanguage, findAssessment } from '../CreateInvite';

import { ReactComponent as QuestionIcon } from '../../../assets/icons/question-mark.svg';
import isChineseEnv from '../../../utils/isChineseEnv';

const AdministerAssessment = ({ onClose, respondents }) => {
  const user = useSelector((state) => state.user.user);
  const languages = useSelector(selectAvailableLanguages);
  const groupedAssessments = useSelector(selectAllAssessments);
  const categories = useSelector(selectCategories);
  const [activeCategory, setActiveCategory] = useState(categories.length ? categories[0] : null);
  const [assessments, setAssessments] = useState(groupedAssessments ? groupedAssessments[activeCategory] : []);
  const dispatch = useDispatch();

  const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [assessment, setAssessment] = useState(findAssessment(assessments, language));
  const [isConfirmationModalVisible, setConfirmationModalState] = useState(false);
  const [isLoading, setLoadingState] = useState(false);

  const availableBiodataTemplates = useSelector(selectAvailableMappedBiodataTemplates);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );
  const [biodataTemplate, setBiodataTemplate] = useState(hasDefaultTemplate || availableBiodataTemplates[0]);

  useEffect(() => {
    if (languages.length) setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
  }, [languages]);

  useEffect(() => {
    if (categories.length) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);
  useEffect(() => {
    if (groupedAssessments) {
      const assessments = groupedAssessments[activeCategory];
      setAssessments(assessments);
      setAssessment(findAssessment(assessments, language));
    }
  }, [groupedAssessments, activeCategory, language]);

  const onLanguageChange = (data) => {
    setLanguage(data);
  };

  const onCategoryChange = (option) => {
    setActiveCategory(option.value);
    setAssessment(findAssessment(assessments, language));
  };
  const onAssessmentValueChange = (item) => {
    setAssessment(item);
  };

  const onBiodataTemplateChange = (item) => {
    setBiodataTemplate(item);
  };

  const onConfirmationClose = () => {
    setConfirmationModalState(false);
    setLoadingState(false);
  };
  const onStartClick = () => {
    const data = {
      batteryID: assessment.batteryID || -1,
      langID: language.value,
      testID: assessment.testID,
      respondentID: respondents[0].respondentID,
      isAdministered: true,
      biodatatemplateid: biodataTemplate.value,
    };
    setLoadingState(true);
    dispatch(
      createQuestionnaireSession(data, () => {
        onClose();
        onConfirmationClose();
      }),
    );
  };

  const availableAssessments = useAvailableTests({
    language,
    assessments,
  });

  const assessmentsOptions = useMemo(() => {
    return assessments ? createTestsOptions(availableAssessments) : [];
  }, [availableAssessments]);

  const assessmentWithLabel = useMemo(() => {
    if (assessment) {
      return assessment.label ? assessment : createTestsOptions([assessment])[0];
    }
    return null;
  }, [assessment]);

  // eslint-disable-next-line no-nested-ternary
  const respondentName = respondents[0]
    ? isChineseEnv
      ? `${respondents[0].familyName || ''}${respondents[0].firstName}`
      : `${respondents[0].firstName} ${respondents[0].familyName}`
    : '';

  const checkedName = assessment && (assessment.name || assessment.title);
  const checkedTitle =
    assessment && assessment.batteryID ? `${checkedName} (${assessment.uses.split(';').join(', ')})` : checkedName;
  const categoriesOptions = categories.map((category) => ({ name: I18n.t(category), value: category }));
  const activeCategoryOption = { name: activeCategory, value: activeCategory };

  return (
    <StyledModal md isVisible onClose={onClose} title={I18n.t('Administer assessment for', { respondentName })}>
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onClose={onConfirmationClose}
        description={`${I18n.t('Administer')} ${checkedTitle} ${I18n.t('to')} ${respondentName}?`}
        onConfirm={onStartClick}
        title={I18n.t('Assessment')}
      />
      <Spinner isLoading={isLoading} />
      <AssessmentContainer>
        <StyledCustomSelect
          options={languages}
          placeholder={I18n.t('Language')}
          selectProps={{ value: language, onChange: onLanguageChange }}
        />
        <AssessmentTypes>
          {categoriesOptions.map((item) => (
            <RadioOption
              key={item.value}
              isActive={item.value === activeCategoryOption.value}
              onClick={() => onCategoryChange(item)}
            >
              <RadioButton isChecked={item.value === activeCategoryOption.value} readOnly />
              <span>{item.name}</span>
            </RadioOption>
          ))}
        </AssessmentTypes>
        <StyledCustomSelect
          options={assessmentsOptions}
          placeholder={I18n.t(activeCategoryOption.name)}
          selectProps={{
            value: assessmentWithLabel,
            onChange: onAssessmentValueChange,
          }}
        />
        <InfoSelectWrapper>
          <StyledCustomSelect
            options={availableBiodataTemplates}
            placeholder={I18n.t('Bio data template')}
            selectProps={{
              value: biodataTemplate,
              onChange: onBiodataTemplateChange,
            }}
          />
          <StyledQuestionIcon data-tip data-for="biodata" />
          <StyledTooltip id="biodata">{I18n.t('biodataTooltip')}</StyledTooltip>
        </InfoSelectWrapper>
      </AssessmentContainer>
      <ButtonWrapper>
        <Button handler={() => setConfirmationModalState(true)} disabled={!assessment || !language}>
          {I18n.t('Start')}
        </Button>
      </ButtonWrapper>
    </StyledModal>
  );
};

AdministerAssessment.propTypes = {
  onClose: func.isRequired,
  respondents: array.isRequired,
};

const StyledModal = styled(Modal)`
  min-height: 40rem;
  min-width: 65rem;
  width: 65rem;
  display: flex;
  flex-direction: column;
`;

const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex-grow: 1;
`;

const AssessmentTypes = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  user-select: none;
  margin-bottom: 0.5rem;

  :hover {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  width: 11rem;
`;

const InfoSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 30rem;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 95%;
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-bottom: 20px;
`;

export default AdministerAssessment;
