import React, { useState } from 'react';
import { array, func, object } from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';

import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import Avatar from '../../reusable/Avatar';
import CustomSelect from '../../reusable/Selects/Select';
import RadioButton from '../../reusable/FormComponents/RadioButton';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';
import CustomButton from '../../reusable/Buttons/Button';

import { ReactComponent as SendInviteIcon } from '../../../assets/icons/send-invite.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-green.svg';
import { ReactComponent as CopyLinkIcon } from '../../../assets/icons/copy_link.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-invites.svg';

import { selectTemplatesAsOptions } from '../../../store/settings/templates/selectors';
import isChineseEnv from '../../../utils/isChineseEnv';

export const sendInviteOptions = [
  {
    value: 'immediately',
    label: 'Immediately',
  },
  {
    value: 'later',
    label: 'Later',
  },
];

const InvitationStep = ({ createdInvites, respondents, sendInvite, assessment }) => {
  const templates = useSelector(selectTemplatesAsOptions);

  const [copiedInviteId, setCopiedInviteId] = useState(null);

  const [body, setBody] = useState(templates[0]);

  const [invitationType, setInvitationType] = useState(sendInviteOptions[0]);
  const [invitationDate, setInvitationDate] = useState(null);

  const [isInviteSent, setInviteStatus] = useState(false);

  const copyLink = (invite) => {
    copy(invite.url);
    setCopiedInviteId(invite.inviteID);
  };

  const areEmailsMissing = respondents.every((resp) => !resp.email);
  const isFormDisabled = isInviteSent || areEmailsMissing;

  const onSendInvite = () => {
    if (isFormDisabled) return;
    const data = {
      inviteType: 0,
      inviteIDs: createdInvites.map((item) => item.inviteID),
      sendInviteDate: invitationType.value === 'immediately' ? null : invitationDate,
      emailTID: body && body.value,
    };
    sendInvite(data, () => setInviteStatus(true));
  };

  const onDownloadInvites = (invites) => {
    try {
      const mappedInvites = [];

      invites.forEach(({ email, familyName, firstName, langID, testID, url }) => {
        // eslint-disable-next-line eqeqeq
        const parsedFirstName = firstName == undefined ? '' : firstName;
        // eslint-disable-next-line eqeqeq
        const parsedFamilyName = familyName == undefined ? '' : familyName;
        return mappedInvites.push({
          [I18n.t('fullName')]: `${parsedFirstName} ${parsedFamilyName}`,
          [I18n.t('email')]: email,
          [I18n.t('inviteURL')]: url,
          [I18n.t('testID')]: testID || assessment.label,
          [I18n.t('langID')]: langID,
        });
      });

      const csvInvitesReportFileName = I18n.t('csvReportName', {
        date: isChineseEnv ? '' : moment(new Date()).format('YYYY-MM-DD HH:mm'),
      });

      const inviteValuesArray = mappedInvites.map((invite) => Object.values(invite));
      inviteValuesArray.unshift(Object.keys(mappedInvites[0])); // Insert row headers

      const invitesToCSV = inviteValuesArray.map((row) => row.join(',')).join('\n'); // Insert line break

      const csvBlob = new Blob([invitesToCSV], { type: 'text/csv' });

      const urlObject = URL.createObjectURL(csvBlob);

      const link = document.createElement('a');
      link.setAttribute('href', urlObject);
      link.setAttribute('download', csvInvitesReportFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      throw new Error('Something went wrong ', e);
    }
  };

  return (
    <InvitationStepContainer>
      <TopSection>
        <Section>
          {createdInvites.length > 1 && <Title>{I18n.t('Copy and share generated link')}</Title>}
          {createdInvites.length > 1 ? (
            <LinksList>
              {createdInvites.map((invite) => {
                const isCopied = copiedInviteId === invite.inviteID;
                return (
                  <InviteItem key={invite.inviteID}>
                    <Centering>
                      {!isChineseEnv && <StyledAvatar firstName={invite.firstName} familyName={invite.familyName} />}
                      <RespondentInfo>
                        <span>{`${invite.firstName} ${invite.familyName}`}</span>
                        <span>
                          {invite.email || I18n.t('Email address is missing or not valid in respondent record')}
                        </span>
                      </RespondentInfo>
                    </Centering>
                    <CopyLinkWrapper isCopied={isCopied} onClick={() => copyLink(invite)}>
                      <StyledCopyLinkIcon isCopied={isCopied} />
                      <span>{isCopied ? I18n.t('Copied') : I18n.t('Copy link')}</span>
                    </CopyLinkWrapper>
                  </InviteItem>
                );
              })}
            </LinksList>
          ) : (
            <SingleLinkHolder>
              <Title>{I18n.t('Copy and share generated link')}</Title>
              <Button
                variant="secondary"
                handler={() => copyLink(createdInvites[0])}
                isLinkCopied={createdInvites[0].inviteID === copiedInviteId}
              >
                <CopyLinkIcon />
                {createdInvites[0].inviteID === copiedInviteId ? I18n.t('Copied') : I18n.t('Copy link')}
              </Button>
            </SingleLinkHolder>
          )}
        </Section>
        <Divider>
          <Title>{I18n.t('OR')}</Title>
          <DividerLine />
        </Divider>
        <Section>
          <Title>{I18n.t('Send invitation link via email to:')}</Title>
          <EmailMessage>
            {respondents.length > 1
              ? I18n.t('Invite will be sent to respondents with valid email address only')
              : `${respondents[0].email}`}
          </EmailMessage>
          {areEmailsMissing && <ErrorMessage>{I18n.t('missingEmailError')}</ErrorMessage>}
          <CustomSelect
            options={templates}
            placeholder={I18n.t('Email template')}
            selectProps={{ value: body, onChange: setBody }}
            isDisabled={isFormDisabled}
          />
          <Label>{I18n.t('When do you want to send an invite?')}</Label>
          <InvitationTypesWrapper isDisabled={isFormDisabled}>
            {sendInviteOptions.map((item) => (
              <RadioOption
                key={item.value}
                isActive={item.value === invitationType.value}
                onClick={() => !isFormDisabled && setInvitationType(item)}
              >
                <RadioButton isChecked={item.value === invitationType.value} readOnly />
                <span>{I18n.t(item.label)}</span>
              </RadioOption>
            ))}
          </InvitationTypesWrapper>
          {invitationType.value === 'later' && (
            <StyledTimeSelect
              date={invitationDate}
              onDateChange={setInvitationDate}
              inputName={I18n.t('Invitation date')}
              minDate={new Date()}
              disabled={isFormDisabled}
            />
          )}
          <ButtonWrapper>
            <Button variant="secondary" handler={onSendInvite} isInviteSent={isInviteSent} isDisabled={isFormDisabled}>
              {isInviteSent ? <CheckIcon /> : <SendInviteIcon />}
              {isInviteSent ? I18n.t('Email sent') : I18n.t('Email invite')}
            </Button>
          </ButtonWrapper>
        </Section>
      </TopSection>
      <BottomSection>
        {createdInvites.length >= 2 && (
          <SingleLinkHolder>
            <Button
              variant="primary"
              handler={() => onDownloadInvites(createdInvites)}
              isLinkCopied={createdInvites[0].inviteID === copiedInviteId}
            >
              <DownloadIcon />
              {I18n.t('downloadInvitesReport')}
            </Button>
          </SingleLinkHolder>
        )}
      </BottomSection>
    </InvitationStepContainer>
  );
};

InvitationStep.propTypes = {
  createdInvites: array.isRequired,
  respondents: array.isRequired,
  sendInvite: func.isRequired,
  assessment: object.isRequired,
};

const InvitationStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 48%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 48%;
  width: 100%;
  margin-top: 2rem;
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;
const DividerLine = styled.div`
  width: 0.1rem;
  height: 45rem;
  background-color: ${(props) => props.theme.colors.grey2};
`;

const LinksList = styled.div`
  display: flex;
  flex-direction: column;
  height: 45rem;
  overflow: auto;
`;

const SingleLinkHolder = styled.div`
  display: flex;
  height: 45rem;
  flex-direction: column;
  align-items: center;
`;

const InviteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.2rem 1.2rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.grey6};
  ${(props) =>
    props.withBorderBtm &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
    `}
`;

const RespondentInfo = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
  }

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    margin-bottom: 0.5rem;
  }

  span:last-child {
    font-size: 1rem;
    color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.primary)};
  }
`;

const Centering = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.8rem;
`;

const StyledCopyLinkIcon = styled(CopyLinkIcon)`
  margin-right: 0.6rem;

  ${(props) =>
    props.isCopied &&
    css`
      path {
        fill: ${(props) => props.theme.colors.green};
      }
    `}
`;

const CopyLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
  color: ${(props) => (props.isCopied ? props.theme.colors.green : props.theme.colors.mediumBlue)};
  font-weight: 600;
`;

const EmailMessage = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey4};
  text-align: center;
  margin-bottom: 3rem;
`;

const ErrorMessage = styled.span`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 500;
  color: ${(props) => props.theme.colors.red};
  margin-bottom: 2rem;
  text-align: center;
`;

const Label = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const InvitationTypesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  }
`;
const StyledTimeSelect = styled(DateTimeSelect)`
  margin-bottom: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(CustomButton)`
  svg {
    margin-right: 0.6rem;
  }

  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

  ${(props) =>
    (props.isLinkCopied || props.isInviteSent) &&
    css`
      color: ${(props) => props.theme.colors.green};
      border-color: ${(props) => props.theme.colors.green};
      svg path {
        stroke: ${(props) => props.theme.colors.green};
      }

      :hover {
        border-color: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.green};
      }
    `}
`;

export default InvitationStep;
