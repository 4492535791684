import React, { useState } from 'react';
import { array } from 'prop-types';
import copy from 'copy-to-clipboard';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as CopyLinkIcon } from '../../../assets/icons/copy_link.svg';
import Avatar from '../../reusable/Avatar';
import isChineseEnv from '../../../utils/isChineseEnv';

const MissingEmailsStep = ({ createdInvites }) => {
  const [copiedInviteId, setCopiedInviteId] = useState(null);

  const copyLink = (invite) => {
    copy(invite.url);
    setCopiedInviteId(invite.inviteID);
  };

  const invitesWithEmailMissing = createdInvites.filter((invite) => !invite.email);

  // TODO translations

  return (
    <Container>
      <Title>
        <span>{I18n.t('Copy and share generated links for')}</span>{' '}
        <RedSpan>
          {invitesWithEmailMissing.length} {I18n.t('respondents')}
        </RedSpan>{' '}
        <span>{I18n.t('that have email address missing')}</span>
      </Title>
      {invitesWithEmailMissing.map((invite) => {
        const isCopied = copiedInviteId === invite.inviteID;
        return (
          <InviteItem key={invite.inviteID}>
            <Centering>
              {!isChineseEnv && <StyledAvatar firstName={invite.firstName} familyName={invite.familyName} />}
              <RespondentInfo>
                <span>
                  {isChineseEnv
                    ? `${invite.familyName || ''}${invite.firstName}`
                    : `${invite.firstName} ${invite.familyName}`}
                </span>
                <span>{invite.email || I18n.t('Email address is missing or not valid in respondent record')}</span>
              </RespondentInfo>
            </Centering>
            <CopyLinkWrapper isCopied={isCopied} onClick={() => copyLink(invite)}>
              <StyledCopyLinkIcon iscopied={isCopied.toString()} />
              <span>{isCopied ? I18n.t('Copied') : I18n.t('Copy link')}</span>
            </CopyLinkWrapper>
          </InviteItem>
        );
      })}
    </Container>
  );
};

MissingEmailsStep.propTypes = {
  createdInvites: array.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 50%;
  align-self: center;
  height: 58rem;
  overflow: auto;
`;

const Title = styled.h3`
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
`;

const RedSpan = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const InviteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.2rem 1.2rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.grey6};
  ${(props) =>
    props.withBorderBtm &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
    `}
`;

const RespondentInfo = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
  }

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    margin-bottom: 0.5rem;
  }

  span:last-child {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.red};
  }
`;

const Centering = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 1.5rem;
`;

const StyledCopyLinkIcon = styled(CopyLinkIcon)`
  margin-right: 0.6rem;

  ${(props) =>
    props.iscopied === 'true' &&
    css`
      path {
        fill: ${(props) => props.theme.colors.green};
      }
    `}
`;

const CopyLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
  color: ${(props) => (props.isCopied ? props.theme.colors.green : props.theme.colors.mediumBlue)};
  font-weight: 600;
`;

export default MissingEmailsStep;
