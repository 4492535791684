import React, { useState } from 'react';
import { func, number, string, oneOfType } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../services/apiService';

import Modal from '../reusable/NewModal';
import AsyncSelect from '../reusable/Selects/AsyncSelect';
import CustomButton from '../reusable/Buttons/Button';

import { addSession } from '../../store/sessions/actions';
import isChineseEnv from '../../utils/isChineseEnv';

const PAGE_SIZE = 20;

const AddSessionModal = ({ onClose, projectId }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [selectedRespondent, setSelectedRespondent] = useState(null);
  const [isSubmitting, setSubmittingState] = useState(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [selectValue, setSelectValue] = useState('');

  const fetchRespondents = async (inputValue, pageIndex) => {
    try {
      const response = await apiInstance2.get(
        `/api/v2/360/projects/${projectId}/available-respondents-for-sessions?PageNumber=${pageIndex}&PageSize=${PAGE_SIZE}&SearchText=${inputValue}`,
      );
      return { respondents: response.data.data, totalCount: response.data.totalCount };
    } catch (e) {
      console.log('Error while fetching respondents');
    }
  };

  const loadOptions = async (inputValue, loadedOptions) => {
    const { respondents, totalCount } = await fetchRespondents(inputValue, pageIndex);
    const options = respondents.map((item) => ({
      value: item.respondentId,
      label: `${isChineseEnv ? `${item.familyName || ''}${item.firstName}` : `${item.firstName} ${item.familyName}`} (${
        item.email ? item.email : I18n.t('No email')
      })`,
      email: item.email,
    }));
    setPageIndex((prev) => prev + 1);
    return {
      options,
      hasMore: loadedOptions.length < totalCount - PAGE_SIZE,
    };
  };

  const onChange = (data) => {
    setSelectedRespondent(data);
    setError(null);
    if (!data.email) setError(I18n.t('Make sure selected record has a valid email address added'));
  };

  const onSave = () => {
    setSubmittingState(true);
    dispatch(
      addSession(projectId, selectedRespondent.value, (data) => {
        setSubmittingState(false);
        if (typeof data === 'object') {
          dispatch(push(`/projects/360/${projectId}/session/${data.sessionId}/session-raters`));
        } else if (typeof data === 'string' && data.includes('422')) {
          return setError(I18n.t('Sorry, session with selected respondent already exists.'));
        }
      }),
    );
  };

  const onSelectInputChange = (value) => {
    setSelectValue(value);
    setPageIndex(0);
  };

  return (
    <StyledModal title={I18n.t('Add session')} isVisible onClose={onClose}>
      <SelectWrapper>
        <AsyncSelect
          placeholder={
            selectedRespondent ? I18n.t('Session SELF') : I18n.t('Search existing record to select session SELF')
          }
          loadOptions={loadOptions}
          selectProps={{ onChange, onInputChange: onSelectInputChange, inputValue: selectValue }}
          error={error}
        />
      </SelectWrapper>
      <ButtonsWrapper>
        <Button handler={onSave} disabled={!selectedRespondent || !selectedRespondent.email || isSubmitting}>
          {I18n.t('Add')}
        </Button>
      </ButtonsWrapper>
    </StyledModal>
  );
};

AddSessionModal.propTypes = {
  onClose: func.isRequired,
  projectId: oneOfType([string, number]).isRequired,
};

const StyledModal = styled(Modal)`
  max-height: 33rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SelectWrapper = styled.div`
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  min-width: 12rem;
`;

export default AddSessionModal;
