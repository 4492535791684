import React from 'react';
import { func, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import * as Sentry from '@sentry/react';
import ErrorMessage from './ErrorMessage';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line react/state-in-constructor
  state = { hasError: false };

  componentDidCatch(error, info) {
    Sentry.captureException(error, { extra: info });
    console.log(`Error caught in boundary: ${error} = ${info}`);
  }

  navigateToRespondents = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.push('/respondents');
    document.location.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorMessage onConfirm={this.navigateToRespondents} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: any,
  push: func.isRequired,
};

ErrorBoundary.defaultProps = {
  children: null,
};

const mapDispatchToProps = {
  push,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
