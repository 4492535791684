import React from 'react';
import { toast } from 'react-toastify';
import Notification from '../components/Notification';

const typesMap = {
  success: 'toast-success',
  failure: 'toast-failure',
  info: 'toast-info',
  copy: 'toast-success-copy',
};

let activeToasts = [];

function nofificationIsPresent(notification) {
  return activeToasts.find((activeNotification) => activeNotification.notificationID === notification.notificationID);
}

function removeNotification(notification) {
  activeToasts = activeToasts.filter(
    (activeNotification) => activeNotification.notificationID !== notification.notificationID,
  );
}

const createToastNotification = ({ notification, title, message, type = 'success', options }) => {
  if (notification && notification.notificationID) {
    if (nofificationIsPresent(notification)) {
      return;
      // eslint-disable-next-line no-else-return
    } else {
      activeToasts.push(notification);

      const originalfunction = options.onClose;

      options.onClose = originalfunction
        ? async () => {
            await originalfunction();
            removeNotification(notification);
          }
        : () => removeNotification(notification);
    }
  }

  toast(<Notification title={title} body={message} type={type} />, {
    type,
    className: typesMap[type],
    ...options,
  });
};

export default createToastNotification;
