import { I18n } from 'react-redux-i18n';
import { createAction } from 'redux-actions';
import apiInstance2 from '../../../services/apiService';
import isChineseEnv from '../../../utils/isChineseEnv';

/**
 * Get All User Norms
 */
export const getUserNormsStart = createAction('GET_USER_NORMS_START');
export const getUserNormsFailure = createAction('GET_USER_NORMS_FAILURE');
export const getUserNormsSuccess = createAction('GET_USER_NORMS_SUCCESS', (norms) => ({ norms }));

const buildFilteringOptions = () => {
  const filteringOptionsCheck = {
    testID: [],
    language: [],
    countryOfOrigin: [],
  };
  const filteringOptions = {
    testID: [],
    language: [],
    countryOfOrigin: [],
  };

  const testAndPushProp = (propName, norm) => {
    if (!filteringOptionsCheck[propName].includes(norm[propName].toUpperCase())) {
      filteringOptionsCheck[propName].push(norm[propName]);
      filteringOptions[propName].push({
        label: isChineseEnv && norm[propName] === 'CH' ? '中文' : norm[propName],
        value: norm[propName],
      });
    }
  };

  return [
    (norm) => {
      if (filteringOptions.testID) {
        // norm.testID = norm.testID.toUpperCase();
        testAndPushProp('testID', norm);
      }

      if (filteringOptions.language) {
        if (!norm?.language) norm.language = isChineseEnv ? 'CH' : 'EN';
        testAndPushProp('language', norm);
      }

      if (filteringOptions.countryOfOrigin) {
        if (!norm?.countryOfOrigin) norm.countryOfOrigin = I18n.t('Mixed');
        testAndPushProp('countryOfOrigin', norm);
      }
    },
    filteringOptions,
  ];
};

export const setFilteringOptions = createAction('SET_FILTERING_OPTIONS');

export const setNormVisibility = createAction('SET_NORM_VISIBILITY');

export const updateNormVisibility = (data, cb) => async (dispatch, getState) => {
  const {
    user: { user },
  } = getState();

  try {
    const response = await apiInstance2.post(
      `/api/v2/user/norms/visibility?accountId=${user.accountID}&userId=${user.userID}&normId=${data.normID}&visible=${data.visible}`,
    );

    if (response.status === 200) {
      dispatch(setNormVisibility(data));
    }
    if (cb) cb();
  } catch (err) {
    console.warn(err);
    if (cb) cb();
  }
};
export const getUserNorms = (cb) => async (dispatch) => {
  dispatch(getUserNormsStart());

  try {
    const response = await apiInstance2.get(`/api/v2/norms`);

    const [getNormFilteringOptions, filteringOptions] = buildFilteringOptions();
    response.data.forEach((norm) => {
      getNormFilteringOptions(norm);
    });
    dispatch(setFilteringOptions(filteringOptions));

    if (response.status === 200) {
      dispatch(getUserNormsSuccess(response.data));
    }
  } catch (err) {
    dispatch(getUserNormsFailure());
  } finally {
    if (cb) cb();
  }
};

/**
 * Get Tests with System Norms
 */
export const getTestsWithSystemNormsStart = createAction('GET_TESTS_WITH_SYSTEM_NORMS_START');
export const getTestsWithSystemNormsFailure = createAction('GET_TESTS_WITH_SYSTEM_NORMS_FAILURE');
export const getTestsWithSystemNormsSuccess = createAction('GET_TESTS_WITH_SYSTEM_NORMS_SUCCESS', (tests) => ({
  tests,
}));

export const getTestsWithSystemNorms = () => async (dispatch) => {
  dispatch(getTestsWithSystemNormsStart());

  try {
    const response = await apiInstance2.get(`/api/v2/norms/testswithsystemnorms`);

    if (response.status === 200) {
      dispatch(getTestsWithSystemNormsSuccess(response.data));
    }
  } catch (err) {
    dispatch(getTestsWithSystemNormsFailure());
  }
};

export const setSelectedTestId = createAction('SET_SELECTED_TEST_ID');
