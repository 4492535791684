import React, { useState, useCallback, useEffect } from 'react';
import { func, array } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { addMonths } from 'date-fns';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import * as Sentry from '@sentry/react';
import Modal from '../../reusable/NewModal';
import Steps from '../../reusable/Steps';
import CustomButton from '../../reusable/Buttons/Button';
import storageService from '../../../services/storageService';

import RespondentsStep from './RespondentsStep';
import AssessmentStep from './AssessmentStep';
import InvitationStep from './InvitationStep';

import useStep from '../../../hooks/useStep';
import { selectAvailableLanguages, selectAvailableMappedBiodataTemplates } from '../../../store/user/selectors';
import { selectUnsupervisedAssessments, selectCategories } from '../../../store/tests/selectors';
import { createInvite, sendInvite } from '../../../store/invites/actions';
import { selectWelcomeTextsOptions } from '../../../store/settings/welcome-texts/selectors';
import { resolveAssessmentName } from '../../../utils/assessmentsNames';
import isChineseEnv from '../../../utils/isChineseEnv';

const createSteps = (respondents) =>
  respondents.length > 1 ? ['respondents', 'assessment', 'invitation'] : ['assessment', 'invitation'];

export const findLanguage = (languages, defaultLang) => {
  const interfaceLanguage = storageService.getItem('psLang');
  // if (interfaceLanguage === 'ch') interfaceLanguage = 'ch-prc';
  if (interfaceLanguage) {
    const found = languages.find((item) => item.code.toLocaleLowerCase() === interfaceLanguage);
    return found;
  }

  const eng = languages.find((item) => item.code === 'EN');
  if (!defaultLang) return eng;
  const defaultUserLanguage = languages.find((item) => item.code === defaultLang);
  return defaultUserLanguage || eng;
};

export const findAssessment = (assessments, language) => {
  if (!assessments) return null;
  const withCurrentLang = language
    ? assessments.filter((item) => item.langAvailability && item.langAvailability.includes(language?.code))
    : assessments;
  return withCurrentLang.length ? withCurrentLang[0] : null;
};

const Invite = ({ onClose, respondents, successCallback }) => {
  const dispatch = useDispatch();

  const steps = createSteps(respondents);
  const { step, goNext } = useStep({ steps });
  const user = useSelector((state) => state.user.user);
  const groupedAssessments = useSelector(selectUnsupervisedAssessments);
  const categories = useSelector(selectCategories);
  const [activeCategory, setActiveCategory] = useState(categories.length ? categories[0] : null);
  const [assessments, setAssessments] = useState(groupedAssessments ? groupedAssessments[activeCategory] : []);
  const languages = useSelector(selectAvailableLanguages);
  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const availableBiodataTemplates = useSelector(selectAvailableMappedBiodataTemplates);
  const [welcomeText, setWelcomeText] = useState(welcomeTexts[0]);
  const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [assessment, setAssessment] = useState(findAssessment(assessments, language));
  const [expiryDate, setExpiryDate] = useState(addMonths(new Date(), 1));
  const [isLoading, setLoadingState] = useState(false);
  const [createdInvites, setCreatedInvites] = useState([]);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );
  const [biodataTemplate, setBiodataTemplate] = useState(hasDefaultTemplate || availableBiodataTemplates[0]);

  useEffect(() => {
    setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
  }, [languages.length]);

  useEffect(() => {
    if (groupedAssessments) {
      const assessments = groupedAssessments[activeCategory];
      setAssessments(assessments);
      setAssessment(findAssessment(assessments, language));
    }
  }, [groupedAssessments, activeCategory, language]);

  useEffect(() => {
    if (categories.length) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);

  try {
    // eslint-disable-next-line
    var respondentName =
      respondents.length && isChineseEnv
        ? `${respondents[0].familyName || ''}${respondents[0].firstName}`
        : `${respondents[0].firstName} ${respondents[0].familyName}`;
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setContext('respondents', respondents);
      scope.setFingerprint(['CustomErrorTypeTrack', 'CreateInviteModalFirstName']);
      Sentry.captureException(err);
    });
    throw err;
  }
  const title =
    respondents.length > 1
      ? `${I18n.t('Invite respondents')} (${respondents.length})`
      : `${I18n.t('Invite')} ${respondentName}`;

  const onValueChange = (name, value) => {
    if (name === 'language') setLanguage(value);
    if (name === 'assessment') setAssessment(value);
    if (name === 'expiryDate') setExpiryDate(value);
    if (name === 'welcomeText') setWelcomeText(value);
    if (name === 'category') {
      setActiveCategory(value);
    }
    if (name === 'biodataTemplate') setBiodataTemplate(value);
  };

  const isDirectReportAvailable = Boolean(assessment?.directFeedbackReportLanguagesAvailable?.includes(language?.code));

  const [isDirectReportChecked, setDirectReportStatus] = useState(false);
  const toggleDirectReportStatus = () => setDirectReportStatus((prev) => !prev);

  const onCreateInvite = () => {
    const data = {
      respondentIDs: respondents.map((item) => item.respondentID),
      testID: assessment.testID ? assessment.testID : null,
      batteryID: assessment.batteryID ? assessment.batteryID : null,
      langID: language.code,
      expiryDate: expiryDate.toISOString(),
      welcomeTextTID: welcomeText.value,
      sendDirectFeedbackReport: isDirectReportChecked,
      biodatatemplateid: biodataTemplate.value,
    };
    setLoadingState(true);
    dispatch(
      createInvite(data, (invites, err) => {
        if (err) return;
        if (successCallback) successCallback();
        setCreatedInvites(invites);
        goNext();
        setLoadingState(false);
      }),
    );
  };

  const onSendInvite = (data, callback) => {
    dispatch(
      sendInvite(data, () => {
        callback();
      }),
    );
  };

  const renderStep = (step) => {
    switch (step) {
      case 'respondents':
        return <RespondentsStep respondents={respondents} />;
      case 'assessment':
        return (
          <AssessmentStep
            assessments={assessments}
            activeCategory={activeCategory}
            categories={categories}
            languages={languages}
            language={language}
            assessment={
              assessment && {
                ...assessment,
                label: resolveAssessmentName(assessment),
                value: assessment.batteryID ? assessment.batteryID : assessment.testID,
              }
            }
            onValueChange={onValueChange}
            expiryDate={expiryDate}
            welcomeText={welcomeText}
            welcomeTexts={welcomeTexts}
            biodataTemplate={biodataTemplate}
            biodataTemplates={availableBiodataTemplates}
            isDirectReportAvailable={isDirectReportAvailable}
            isDirectReportChecked={isDirectReportChecked}
            toggleDirectReportStatus={toggleDirectReportStatus}
          />
        );
      case 'invitation':
        return (
          <InvitationStep
            createdInvites={createdInvites}
            respondents={respondents}
            sendInvite={onSendInvite}
            assessment={
              assessment && {
                ...assessment,
                label: resolveAssessmentName(assessment),
                value: assessment.batteryID ? assessment.batteryID : assessment.testID,
              }
            }
          />
        );
      default:
        return <RespondentsStep respondents={respondents} />;
    }
  };

  const resolveButtonText = (step) => {
    switch (step) {
      case 'respondents':
        return I18n.t('Next');
      case 'assessment':
        return I18n.t('Create invite and go next');
      case 'invitation':
        return I18n.t('Finish');
      default:
        return I18n.t('Next');
    }
  };

  const isNextButtonDisabled = steps[step] === 'assessment' ? !assessment || !expiryDate : false;

  const onNextButtonClick = useCallback(() => {
    const currentStep = steps[step];
    if (currentStep === 'respondents') goNext();
    if (currentStep === 'assessment') onCreateInvite();
    if (currentStep === 'invitation') onClose();
  }, [step, assessment, steps]);

  const showTestInfo = (steps[step] === 'assessment' || steps[step] === 'invitation') && assessment;
  return (
    <StyledModal isVisible onClose={onClose} title={title}>
      <Steps steps={steps} stepIndex={step} />
      <Content>{renderStep(steps[step])}</Content>
      <Footer>
        {showTestInfo ? (
          <FooterInfo>
            <span>{I18n.t('You are sending an invitation for:')}</span>
            <span>
              {assessment.testID || assessment.title} {I18n.t('in')} {I18n.t(language?.label)}
            </span>
          </FooterInfo>
        ) : null}
        {steps[step] !== 'invitation' && (
          <Button handler={onNextButtonClick} disabled={isNextButtonDisabled || isLoading}>
            {resolveButtonText(steps[step])}
          </Button>
        )}
      </Footer>
    </StyledModal>
  );
};

Invite.propTypes = {
  onClose: func.isRequired,
  respondents: array.isRequired,
  successCallback: func,
};

Invite.defaultProps = {
  successCallback: undefined,
};

const StyledModal = styled(Modal)`
  width: 110rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 200px);
  overflow: auto;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.showTestInfo ? 'space-between' : 'flex-end')};
  position: relative;
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  span {
    font-size: 1.4rem;
  }
  span:first-child {
    font-weight: 500;
  }
  span:last-child {
    font-weight: bold;
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
`;

export default Invite;
