import React, { useState } from 'react';
import { func, oneOfType, string, number } from 'prop-types';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { I18n, Translate } from 'react-redux-i18n';

import { ReactComponent as FirstStepIcon } from '../../../assets/icons/import-respondents-step-1.svg';
import { ReactComponent as SecondStepIcon } from '../../../assets/icons/import-respondents-step-2.svg';
import { ReactComponent as ThirdStepIcon } from '../../../assets/icons/import-respondents-step-3.svg';
import { ReactComponent as SuccessfulImportIcon } from '../../../assets/icons/import-file-success.svg';

import apiInstance2 from '../../../services/apiService';
import ID from '../../../utils/id';

import createToastNotification from '../../../utils/createToastNotification';

import Checkbox from '../../reusable/FormComponents/Checkbox';
import CustomButton from '../../reusable/Buttons/Button';
import isChineseEnv from '../../../utils/isChineseEnv';

const instructionSteps = [
  {
    Icon: FirstStepIcon,
    Description: () => (
      <StyledDescription>
        {I18n.t('Prepare a csv file yourself or')}
        <PseudoLink
          href={
            isChineseEnv
              ? 'https://media.genesysonline.net/gs2020container/360批量导入模板.csv'
              : 'https://gs2020storage.blob.core.windows.net/gs2020container/sample360Import.csv'
          }
        >
          {I18n.t('download the sample import file')}
        </PseudoLink>
      </StyledDescription>
    ),
    key: 1,
  },
  {
    Icon: SecondStepIcon,
    Description: () => (
      <StyledDescription>
        <Translate value="Make sure the data order is set out correctly" />
      </StyledDescription>
    ),
    key: 2,
  },
  {
    Icon: ThirdStepIcon,
    Description: () => (
      <StyledDescription>
        {' '}
        <Translate value="Upload the file" />
      </StyledDescription>
    ),
    key: 3,
  },
];

const csvMimeTypes =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values';

const ImportSessionsPrepareStep = ({ goNext, onClose, setImportedValues, projectId }) => {
  const [files, setFiles] = useState([]);
  const [includeFirstLine, setShouldIncludeFirstLine] = useState(false);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: csvMimeTypes,
    onDropAccepted: (files) => setFiles(files),
  });

  const importFile = async () => {
    try {
      const file = new FormData();
      file.append('file', files[0]);
      const response = await apiInstance2.post(
        `/api/v2/360/projects/${projectId}/import/file?skipFirstLine=${includeFirstLine}${
          isChineseEnv ? '&madeInChina=true' : ''
        }`,
        file,
        {
          headers: {
            'Content-Type': csvMimeTypes,
          },
        },
      );
      if (response.status === 200) {
        setImportedValues(
          response.data.map((item) => {
            const parentId = ID();
            return {
              ...item,
              id: parentId,
              raters: item.raters.map((rater) => ({ ...rater, id: ID(), parentId })),
            };
          }),
        );
        goNext();
      } else {
        throw new Error('Import error');
      }
    } catch (e) {
      createToastNotification({
        title: 'Error',
        message: I18n.t('importSessionsErrorToast'),
        type: 'error',
      });
      onClose();
    }
  };

  const onDeleteFile = () => setFiles([]);

  return (
    <>
      <ContentWrapper>
        <InstructionsWrapper>
          <Row>
            {instructionSteps.map((step) => (
              <IconWrapper key={step.key}>
                <step.Icon />
              </IconWrapper>
            ))}
          </Row>
          <ProgressBar>
            {instructionSteps.map((item, i) => {
              const leftOffset = 50 * i;
              return <Dot key={item.key} left={leftOffset} />;
            })}
          </ProgressBar>
          <Row>
            {instructionSteps.map((step) => (
              <step.Description key={step.key} />
            ))}
          </Row>
        </InstructionsWrapper>
        <StyledDropZone {...getRootProps()} isActive={isDragActive} isFileSelected={Boolean(files.length)}>
          {files.length ? (
            <>
              <SuccessfulImportIcon />
              <Label>{files[0].name}</Label>
              <PseudoLink onClick={onDeleteFile}>{I18n.t('Delete')}</PseudoLink>
            </>
          ) : (
            <>
              <input {...getInputProps()} accept={csvMimeTypes} />
              <MiniTitle>
                <Translate value="Drag and drop file here or browse the file" />
              </MiniTitle>
              <Label>
                <Translate value="Upload the csv file to import respondents" />
              </Label>
              <ChooseButton onClick={open}>
                {' '}
                <Translate value="Choose file" />
              </ChooseButton>
            </>
          )}
        </StyledDropZone>
        <CheckboxWrapper onClick={() => setShouldIncludeFirstLine((prev) => !prev)}>
          <Checkbox isChecked={includeFirstLine} />
          <span>
            {' '}
            <Translate value="The first line contains field headers" />
          </span>
        </CheckboxWrapper>
      </ContentWrapper>
      <ButtonsWrapper>
        <StyledButton handler={importFile} disabled={!files.length}>
          <Translate value="Next" />
        </StyledButton>
      </ButtonsWrapper>
    </>
  );
};

ImportSessionsPrepareStep.propTypes = {
  goNext: func.isRequired,
  onClose: func.isRequired,
  setImportedValues: func.isRequired,
  projectId: oneOfType([string, number]),
};

ImportSessionsPrepareStep.defaultProps = {
  projectId: '',
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1.5rem;
`;

const StyledDescription = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  width: 18rem;
  text-align: center;
`;

const PseudoLink = styled.a`
  color: ${(props) => props.theme.colors.lightBlue};
  text-decoration: underline;
  font-weight: bold;

  :hover {
    cursor: pointer;
  }
`;

const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 18rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const ProgressBar = styled.div`
  height: 4px;
  width: 76%;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  margin: 0 auto 1rem;
  position: relative;
`;

const Dot = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  left: ${(props) => `${props.left}%`};
  top: 50%;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  transform: translateY(-50%);
`;

const StyledDropZone = styled.div`
  margin-top: 1rem;
  border: 2px dashed ${(props) => (props.isFileSelected ? '#27AE60' : '#3DB7E6')};
  background-color: ${(props) => {
    if (props.isFileSelected) return '#E7FFF1';
    return props.isActive ? props.theme.colors.lightBlue1 : '#f3fcff';
  }};
  border-radius: 0.8rem;
  height: 12rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  margin-bottom: 1.5rem;
`;

const MiniTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: bold;
`;

const Label = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
`;

const ChooseButton = styled.button`
  outline: none;
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 6rem;
  transition: 0.2s all;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  span {
    margin-left: 1rem;
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
  }

  :hover {
    cursor: pointer;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default ImportSessionsPrepareStep;
