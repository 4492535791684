/* eslint-disable no-param-reassign,no-unused-vars */
import handleActions from '../immerHandleActions';
import * as actions from './actions';
import storageService from '../../services/storageService';

export const initialUserState = {
  isLoggedIn: !!storageService.getItem('accessToken'),
  isLoading: false,
  user: null,
  tests: [],
  batteries: [],
  categories: [],
  assessmentsLanguages: [],
  tags: [],
  languages: [],
  creditsHistory: [],
  requestedReports: [],
  usedAssessments: [],
  notifications: [],
  biotemplates: [],
  partnerInfo: null,
};

const userReducer = handleActions(
  {
    [actions.loginStart]: (draft) => {
      draft.isLoading = true;
    },
    [actions.loginSuccess]: (draft) => {
      draft.isLoggedIn = true;
      draft.isLoading = false;
    },
    [actions.fetchUserSuccess]: (draft, { payload: { user } }) => {
      draft.user = user;
    },
    [actions.changePinSuccess]: (draft, { payload: { newPin } }) => {
      draft.user.pin = newPin;
    },
    [actions.changeReportFormatSuccess]: (draft, { payload: { newReportFormat } }) => {
      draft.user.reportFormat = newReportFormat;
    },
    [actions.changeEmailReceivingStateSuccess]: (draft, { payload: { newState } }) => {
      draft.user.receiveSystemEmailNotifications = newState;
    },
    [actions.fetchTestsAndLanguagesSuccess]: (draft, { payload: { data } }) => {
      draft.tests = data.tests;
      draft.batteries = data.batteries.map((battery) => {
        if (battery.batteryID < 1000000) return battery;
        const tests = battery.uses.split(';').map((testId) => data.tests.find((item) => item.testID === testId));
        return { ...battery, tests };
      });
      draft.assessmentsLanguages = data.languages;
      draft.categories = data.categories;
    },
    [actions.deleteBatteriesSuccess]: (draft, { payload: { batteriesIds } }) => {
      draft.batteries = draft.batteries.filter((battery) => !batteriesIds.includes(battery.batteryID));
    },
    [actions.fetchTagsSuccess]: (draft, { payload: { tags } }) => {
      draft.tags = tags;
    },
    [actions.fetchLanguagesSuccess]: (draft, { payload: { languages } }) => {
      draft.languages = languages;
    },
    [actions.addCreditsSuccess]: (draft, { payload: { amount } }) => {
      draft.user.credits = amount;
    },
    [actions.fetchCreditsHistorySuccess]: (draft, { payload: { creditsHistory } }) => {
      draft.creditsHistory = creditsHistory;
    },
    [actions.fetchRequestedReportsSuccess]: (draft, { payload: { requestedReports } }) => {
      draft.requestedReports = requestedReports;
    },
    [actions.fetchUsedAssessmentsSuccess]: (draft, { payload: { assessments } }) => {
      draft.usedAssessments = assessments;
    },
    [actions.loginFailure]: (draft) => {
      draft.isLoading = false;
    },
    [actions.logoutSuccess]: (draft) => {
      draft.isLoggedIn = false;
    },
    [actions.resetUserState]: (draft) => {
      draft = initialUserState;
    },
    [actions.saveUserNotifications]: (draft, { payload }) => {
      draft.notifications = payload;
    },
    [actions.updateUserNotification]: (draft, { payload }) => {
      draft.notifications = draft.notifications.map((notification) => {
        if (notification.id === payload.id) {
          return payload;
        }
        return notification;
      });
    },
    [actions.fetchUserBioTemplatesSuccess]: (draft, { payload: { biodataTemplates } }) => {
      draft.biotemplates = biodataTemplates;
      draft.isLoading = false;
    },
    [actions.setCustomTheme]: (draft, { payload: { customTheme } }) => {
      draft.partnerInfo = { ...customTheme };
    },
  },
  initialUserState,
);

export default userReducer;
