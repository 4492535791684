import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'summernote/dist/summernote';
import 'summernote/dist/summernote.css';
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-summernote/dist/react-summernote.css';
import $ from 'jquery';
import { I18n } from 'react-redux-i18n';

const randomUid = () => Math.floor(Math.random() * 100000);

const InsertVariables = (variables) => {
  return (context) => {
    const { ui } = $.summernote;
    const event = ui.buttonGroup([
      ui.button({
        contents: I18n.t('Insert variable'),
        data: {
          toggle: 'dropdown',
        },
      }),
      ui.dropdown({
        items: variables.map((entry) => entry.value),
        callback: (items) => {
          $(items)
            .find('li a')
            .on('click', function(e) {
              context.invoke('editor.insertText', $(this).html());
              e.preventDefault();
            });
        },
      }),
    ]);

    return event.render();
  };
};

export default ({ initialValue = '', onChange = () => {}, error, variables = [], settings = {} }) => {
  const editorId = useRef(randomUid());

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    $(`#editor-${editorId.current}`).summernote('destroy');

    $(`#editor-${editorId.current}`).summernote({
      callbacks: {
        onChange: (contents) => {
          onChange(contents);
        },
      },
      height: 350,
      disableResizeEditor: true,
      dialogsInBody: true,
      buttons: {
        ...(variables?.length
          ? {
              insertVariables: InsertVariables(variables),
            }
          : {}),
      },
      toolbar: [
        ['style'],
        ['font', ['bold', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link']],
        ['view', ['fullscreen', 'codeview']],
        ['insertVariables'],
      ],
    });

    const editorArea = document.querySelector('.note-editable');

    if (settings.editorStyles) {
      const styles = document.createElement('div');
      styles.innerHTML = settings.editorStyles;
      editorArea.after(styles);
    }

    if (settings.prepend) {
      const prepend = document.createElement('div');
      prepend.innerHTML = settings.prepend;
      editorArea.parentNode.insertBefore(prepend, editorArea);
    }

    if (settings.append) {
      const append = document.createElement('div');
      append.innerHTML = settings.append;
      editorArea.after(append);
    }
    $(`#editor-${editorId.current}`).summernote('code', initialValue);
  }, [initialValue]);

  return (
    <Container error={error}>
      <div id={`editor-${editorId.current}`} />
      <ErrorMsg>{error}</ErrorMsg>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  .note-editor {
    border: 1px solid ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.grey2)} !important;

    ul {
      list-style-position: inside;
    }
  }
`;

const ErrorMsg = styled.span`
  position: absolute;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  margin-top: 0.5rem;
  min-height: 1.2rem;
  bottom: -1.5rem;
  left: 0;
`;
