import React from 'react';
import { func, oneOfType, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from '@reach/router';
import * as Sentry from '@sentry/browser';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user);
  if (user.user) {
    const payload = {
      ...(user.user.accountID && { id: user.user.userID }),
      ...(user.user.userName && { username: user.user.userName }),
    };

    if (payload.id || payload.username) {
      Sentry.setUser(payload);
    }
  }
  return isLoggedIn ? <Component {...rest} /> : <Redirect noThrow to="/login" />;
};

PrivateRoute.propTypes = {
  component: oneOfType([func, object]).isRequired,
};

export default PrivateRoute;
