import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { orderBy } from 'lodash';
import apiInstance2 from '../../../services/apiService';
import PageWrapper from '../../PageWrapper';
import normsTableActions from '../../../table/tableActions/norms';
import { getUserNorms } from '../../../store/settings/norms/actions';
import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import AddNormModal from '../AddNormModal';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import { ReactComponent as AddNormIcon } from '../../../assets/icons/norm_add.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as ShowIcon } from '../../../assets/icons/eye-show-white.svg';
import { ReactComponent as HideIcon } from '../../../assets/icons/eye-hide.svg';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import Spinner from '../../reusable/Spinner';
import TableTabs from '../../reusable/TableTabs';
import createToastNotification from '../../../utils/createToastNotification';
import Table from '../../reusable/Tables/Table';
import normsColumns from '../../../table/columns/norms';
import AdvancedFilter from './AdvancedFilter';

import TEST_WITHOUT_NORMS from '../../../constants/assessmentWithoutNorm';

const Norms = () => {
  const dispatch = useDispatch();
  const { accountID, userID } = useSelector((state) => state.user.user);
  const [renderKey, setRenderKey] = useState('');
  const norms = useSelector((state) => {
    return state.settingsNorms.norms;
  });

  const filterOptions = useSelector((state) => {
    return state.settingsNorms.filteringOptions;
  });

  const filterOptionsReady = useMemo(() => {
    return !!Object.keys(filterOptions).length;
  }, [filterOptions]);

  const modals = [actions.ADD_NEW_NORM, actions.DELETE_NORMS, actions.SHOW_NORM_REPORTS, actions.HIDE_NORM_REPORTS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const [selectedRawNorms, setSelectedRawNorms] = useState([]);
  const [selectedNorms, setSelectedNorms] = useState([]);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [globalFilter, setGlobalFilter] = useState([]);
  const [filters, setFilters] = useState([]);

  const selectedTestId = useSelector((state) => {
    return state.settingsNorms.selectedTestId;
  });

  const orderedNorms = orderBy(norms, 'name');

  const tableColumns = useMemo(() => {
    return normsColumns;
  }, []);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedNorms });

  useEffect(() => {
    setRenderKey(Math.random() * 10);
    dispatch(
      getUserNorms(() => {
        setShouldResetPageStatus(false);
        setIsLoading(false);
      }),
    );
  }, [shouldResetPage]);

  const onNormsDelete = useCallback(() => {
    apiInstance2
      .delete('/api/v2/norms', { data: selectedNorms })
      .then(() => {
        closeModal(actions.DELETE_NORMS);
        createToastNotification({ message: I18n.t('normsDeletionToast') });
        setSelectedNorms([]);
        setShouldResetPageStatus(true);
      })
      .catch(() => {
        closeModal(actions.DELETE_NORMS);
      });
  }, [selectedNorms]);

  const tableActions = useMemo(() => {
    return normsTableActions.map((item) => ({
      ...item,
      handler: () => openModal(actions.ADD_NEW_NORM),
    }));
  }, []);

  const normsBulkActions = [
    {
      name: actions.ADD_NEW_NORM,
      label: I18n.t('Add new norm'),
      Icon: AddNormIcon,
    },
    {
      name: actions.SHOW_NORM_REPORTS,
      label: I18n.t('visibleNormForReport'),
      Icon: ShowIcon,
      isDisabled: selectedRawNorms.every((item) => item.visible === true),
    },
    {
      name: actions.HIDE_NORM_REPORTS,
      label: I18n.t('hideNormForReport'),
      Icon: HideIcon,
      isDisabled: selectedRawNorms.every((item) => item.visible === false),
    },
    {
      name: actions.DELETE_NORMS,
      label: I18n.t('Delete norm(s)'),
      Icon: DeleteIcon,
    },
  ];

  const bulkActionsHandlers = {
    [actions.ADD_NEW_NORM]: () => openModal(actions.ADD_NEW_NORM),
    [actions.DELETE_NORMS]: () => openModal(actions.DELETE_NORMS),
    [actions.SHOW_NORM_REPORTS]: () => openModal(actions.SHOW_NORM_REPORTS),
    [actions.HIDE_NORM_REPORTS]: () => openModal(actions.HIDE_NORM_REPORTS),
  };

  const bulkActions = useMemo(() => {
    return normsBulkActions.map((item) => ({ ...item, handler: bulkActionsHandlers[item.name] }));
  }, [selectedNorms]);

  const navigateToNorm = ({ normID }) => {
    dispatch(push(`/settings/norms/${normID}`));
  };

  const tabs = [
    { name: 'assessments-and-batteries', label: 'Assessment and Batteries' },
    { name: 'ideal-profiles', label: 'Ideal Profiles' },
    { name: 'norms', label: 'Norms' },
  ];

  const rowCheckedOnChangeHandler = (rows) => {
    setSelectedRawNorms(rows);
    setSelectedNorms(rows.map((item) => item.normID));
  };

  const allVisibleNorms = orderedNorms.filter(
    (norm) => String(norm.testID).toUpperCase() === selectedTestId && norm.visible === true,
  ).length;
  const areAllNormsSelected =
    orderedNorms.filter((norm) => String(norm.testID).toUpperCase() === selectedTestId).length === selectedNorms.length;

  const handleUpdateNormVisibility = useCallback(
    (modal) => {
      const visibilityAction = modal === 'SHOW_NORM_REPORTS';
      if (!visibilityAction && allVisibleNorms === 1 && !TEST_WITHOUT_NORMS.includes(selectedTestId)) {
        createToastNotification({ type: 'error', message: I18n.t('oneRemainingNorm') });
        closeModal(actions[modal]);
        return;
      }
      if (!visibilityAction && areAllNormsSelected && !TEST_WITHOUT_NORMS.includes(selectedTestId)) {
        createToastNotification({ type: 'error', message: I18n.t('oneRemainingNorm') });
        closeModal(actions[modal]);
        return;
      }
      const normsIDs = selectedRawNorms.filter((norm) => norm.visible !== visibilityAction).map((norm) => norm.normID);
      apiInstance2
        .post(
          `/api/v2/user/norms/visibility?accountId=${accountID}&userId=${userID}&visible=${visibilityAction}`,
          normsIDs,
        )
        .then(() => {
          closeModal(actions[modal]);
          createToastNotification({ message: I18n.t('normsVisibilityToast') });
          setSelectedNorms([]);
          setShouldResetPageStatus(true);
        })
        .catch(() => {
          closeModal(actions[modal]);
        });
    },
    [selectedRawNorms],
  );

  return (
    <PageWrapper
      key={renderKey}
      title={I18n.t('Assessment settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs
        tabs={tabs}
        activeTab={tabs[2]}
        clickHandler={(tab) => {
          dispatch(push(tab.name));
        }}
      />
      {norms.length && filterOptionsReady ? (
        <AdvancedFilter
          columns={tableColumns}
          filterOptions={filterOptions}
          setFilters={setFilters}
          setGlobalFilter={setGlobalFilter}
        />
      ) : (
        ''
      )}
      {norms.length && filterOptionsReady ? (
        <Table
          columns={tableColumns}
          data={orderedNorms}
          totalCount={norms.length}
          onRowClick={navigateToNorm}
          onSelectChange={rowCheckedOnChangeHandler}
          interceptCheckboxClick
          manualSortBy={false}
          withFilters={{ value: filters }}
          withGlobalFilter={{ value: globalFilter }}
          localData
        />
      ) : (
        ''
      )}
      {modalsState[actions.ADD_NEW_NORM] && (
        <AddNormModal
          onClose={() => closeModal(actions.ADD_NEW_NORM)}
          setShouldResetPageStatus={setShouldResetPageStatus}
        />
      )}
      {modalsState[actions.SHOW_NORM_REPORTS] && (
        <ConfirmationModal
          isVisible={modalsState[actions.SHOW_NORM_REPORTS]}
          title={I18n.t('showNormTitle')}
          description={`${I18n.t('showConfirmationMessageTitle')} ${
            selectedNorms.length > 1
              ? I18n.t('showhideConfirmationMessageDescription1')
              : I18n.t('showhideConfirmationMessageDescription2')
          }`}
          onClose={() => closeModal(actions.SHOW_NORM_REPORTS)}
          onConfirm={() => handleUpdateNormVisibility('SHOW_NORM_REPORTS')}
          caution
        />
      )}
      {modalsState[actions.HIDE_NORM_REPORTS] && (
        <ConfirmationModal
          isVisible={modalsState[actions.HIDE_NORM_REPORTS]}
          title={I18n.t('hideNormTitle')}
          description={`${I18n.t('hideConfirmationMessageTitle')} ${
            selectedNorms.length > 1
              ? I18n.t('showhideConfirmationMessageDescription1')
              : I18n.t('showhideConfirmationMessageDescription2')
          }`}
          onClose={() => closeModal(actions.HIDE_NORM_REPORTS)}
          onConfirm={() => handleUpdateNormVisibility('HIDE_NORM_REPORTS')}
          caution
        />
      )}
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_NORMS]}
        title={I18n.t('Delete Norm')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedNorms.length > 1 ? I18n.t('selected norms?') : I18n.t('this norm?')
        }`}
        onClose={() => closeModal(actions.DELETE_NORMS)}
        onConfirm={onNormsDelete}
        caution
      />
      <Spinner isLoading={isLoading} full />
    </PageWrapper>
  );
};

export default Norms;
