const translations = {
  About: '에 대해',
  Launch: '로그인합니다',
  Tutorial: '도움말',

  // Login Page (Login.js)
  Email: '이메일 주소',
  Submit: '로그인합니다',
  Password: '암호입니다',
  'Email is required': '이메일 주소를 입력하십시오',
  'Password is required': '암호를 입력하십시오',
  'Should be a proper email address': '유효한 이메일 주소를 입력하십시오',

  // (AddCompetencyCategory.js)
  Save: '저장합니다',
  'Enter Name': '이름을 입력합니다',
  'Add competency category': '역량 범주를 추가합니다',
  'Add description for the category': '설명을 추가합니다',

  // (CompetencyGroupNavbar.js)
  'Main info': '기본 정보',

  // (AddCompetencyGroup.js)
  Add: '추가합니다',
  'Name is required': '이름은 필수입니다',
  'Add 360 assessment': '360도 평가를 추가합니다',
  'Custom competencies': '사용자 정의 역량',
  'Inbuilt competencies': '표준 역량',

  // (AddCompetencyItem.js)
  Edit: '编辑',

  // (AddCompetencyQuestion.js)
  'Add question': '添加问题',

  // (AddCompetencyQuestionForm.js)
  'Item text': '输入问题',
  'Free field': '开放题',
  'Multiple choice': '选择题',
  'Add another language': '添加另一种语言',

  // (AddCreditsFailureModal.js)
  'Credits addition error': '信用点添加失败',
  'Try again': '请再试一次',

  //  (AddCreditsSuccessModal.js)
  Done: '完成',
  'Adding credits': '添加信用点',
  'Credits successfully added!': '信用点添加成功！',
  'Now your credit balance is:': '当前信用点剩余：',

  // (AddCredits.js)
  Cancel: '取消',
  'Add credits': '添加信用点',
  'Your credits balance': '信用点剩余：',
  'How many credits do you want to add?': '您要添加多少信用点？',
  'Purchase ID': '备注',
  'Error occurred during an attempt to add credits to your account': '添加信用点时出现错误',

  // Edit Respondent (Index.js)
  Groups: '分组',
  Invites: '已发送邀请',
  Reports: '报告',
  Assessments: '已完成测评',
  IAssessments: '已完成测评',
  SAssessments: '已完成测评',
  'Respondent Information': '受测者信息',

  // Edit Respondent (AddTagModal.js)
  'Add tags': '添加标签',
  'Select tags': '选择标签',

  // Edit Respondent (Information.js)
  Sex: '성별',
  Title: '직책의 이름입니다',
  Sector: '회사의 성격',
  sector: '회사의 성격',
  Company: '회사 이름입니다',
  Industry: '산업',
  industry: '산업',
  Education: '교육 수준',
  education: '교육 수준',
  Reference: '참조 정보',
  Ethnicity: '인종',
  ethnicity: '인종',
  'Job area': '기능',
  jobArea: '기능',
  'First Name': '이름입니다',
  'Family Name': '성입니다',
  'Save changes': '변경 내용을 저장합니다',
  'Date of Birth': '생년월일',
  'First Language': '모국어',
  'Additional Info': '추가 정보',
  'Main Information': '주요 정보',

  // Edit Respondent (RespondentGroups.js)
  group: '分组',
  'Remove from groups': '从分组中移除受测者',
  'Are you sure you want to remove respondent from selected group(s)': '您确定要从分组中移除所选的受测者吗？',

  // Edit Respondent (RespondentInvites.js)
  invite: '测评邀请',
  'Invite Deletion': '删除测评邀请',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': '报告删除',
  'Are you sure you want to delete selected report?': '您确定要删除所选报告吗？',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': '删除测评',
  'Are you sure you want to delete selected test?': '您确定要删除所选测评吗？',

  // Edit Respondent (TagsManagement.js)
  Tags: '标签',
  '+ Add tag': '+ 添加标签',

  // Groups (AddNewGroup.js)
  Create: '创建',
  'Group name': '分组名称',
  'Add new group': '添加新分组',
  'Group description': '分组描述',

  // Groups (GroupHeader.js)
  Type: '类型',
  'Creation date': '创建日期',

  // Groups (Groups.js)
  List: '受测者名单',
  Respondents: '受测者',
  'Delete group': '删除分组',
  'Are you sure you want to delete this group(s)': '您确定要删除分组吗？',

  // Invites (Invites.js)
  'Delete invite': '删除测评邀请',
  'selected invites?': '所选的测评邀请吗？',
  'this invite?': '这个测评邀请吗？',

  // LaunchScreen (LaunchScreen.js)
  Language: '语言',
  language: '语言',

  // Notifications (Notifications.js)
  Results: '结果',
  notification: '通知',
  Notifications: '通知',

  // Projects (AddProjectsModal.js)
  Min: '最少评估人数',
  Info: '信息',
  Raters: '评估者',
  Deadline: '截止日期',
  'Peer Alias': '同事',
  Description: '描述',
  'Project Name': '项目名称',
  'All standard': '标准版',
  'Edit Project': '编辑项目',
  'Manager Alias': '上级',
  'Project Alias': '评估者信息',
  'Direct Report Alias': '下属',
  'Choose Project Type': '选择项目类型',
  '360 assessment has to be fully supported in at least 1 language': '需要为360度评估的所有项目配置至少一种语言',

  // Projects (projectList.js)
  'Add Assessment Project': '添加测评项目',
  'Add 360 Appraisal Project': '添加360度评估项目',
  'Manage your assessment projects here. Plan and implement the right assessment approach and tests. Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool.':
    '在这里管理您的测评项目。选择和部署合适的测评方式和工具。使用这个简单易用的功能来高效和一致地管理整个测评过程。',
  'Manage your entire 360 appraisal process here. Easy to use, allowing appraisals to be set up in minutes and results generated instantly. Choose from Psytech&apos;s existing competency framework or fully customise your 360 project.':
    '在这里管理您地360度评估项目。使用这个简单易用的功能来轻松创建评估项目和生成结果。您可以使用系统内嵌的PsyTech标准胜任力框架，也可以根据需要来自定义胜任力模型。',

  // Projects (Projects.js)
  All: '所有项目',
  Project: '项目',
  Projects: '项目',
  'this project?': '这个项目？',

  '360 Appraisal': '360度评估',
  'Delete project': '删除项目',
  'selected projects?': '所选的项目吗？',
  'Are you sure you want to delete': '您确定要删除',

  // Raters (Raters.js)
  rater: '评估者',
  'Delete raters': '删除评估者',
  'Loading raters': '加载中……',
  'selected raters?': '所选的评估者？',
  'this rater?': '这个评估者？',

  // Raters (AddRatersModal.js)
  Role: '角色',
  Other: '其他人',
  Peers: '同事',
  Confirm: '确认',
  Managers: '上级',
  'No email': '没有电子邮箱信息',
  'Add raters': '添加评估者',
  'Direct reports': '下属',
  'Search existing records': '在受测者名单中搜索',
  'Make sure selected record has a valid email address added': '请确保所选记录已添加有效的电子邮箱信息',

  // Raters Invites (columns.js)
  Name: '名称',
  PersonName: '姓名',
  'Expiry Date': '到期日期',
  'Invite status': '邀请状态',
  'Email Address': '电子邮箱',
  'Hide expired': '隐藏过期邀请',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': '所选的邀请',
  'selected invite(s)?': '所选的邀请',
  'Loading invites': '加载中……',

  // Raters Reports (Reports.js)
  Pending: '生成中',
  Unread: '未查看',
  Viewed: '已查看',
  Error: '错误',
  'Support ticket raised': '已提交支持请求信息',
  'Loading reports': '加载中……',

  // Raters Results (Results.js)
  result: '结果',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?': '您确定要删除所选报告吗？',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': '请求报告',
  'Report Request': '报告请求',
  credits: '信用点数',
  'Total Credits:': '信用点总数：',
  Request: '请求',
  Self: '被评估者',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': '公司名称',
  'Job Area': '职能',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': '添加受测者',
  'Main Details': '主要信息',
  'Extra Details': '附加信息',
  'Group Membership': '分组信息',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': '选择分组',

  // Respondents AdministerAssessment (index.js)
  Start: '开始',
  Administer: '施测人',
  to: '受测人',

  // Respondents (AnswersStep.js)
  'Test:': '测评：',

  // Respondents AnswerSheet (index.js)
  Previous: '上一步',

  // Respondents (AssessmentStep.js)
  'Welcome text': '欢迎辞',
  'Expiry date': '到期日期',

  // Invite (index.js)
  'Invite respondents': '邀请受测者',
  Invite: '邀请：',
  'Create invite and go next': '创建测评邀请并继续',
  Finish: '完成：',
  'You are sending an invitation for:': '您正在发送测评至：',
  in: 'in',

  // Invite (InvitationStep.js)
  Immediately: '即刻发出',
  Later: '稍后发出',
  'Email address is missing or not valid in respondent record': '候选人电子邮箱信息缺失或无效',
  'Copy link': '复制测评链接',
  Copied: '已复制',
  'Copy and share generated link': '复制并分享测评链接',
  'Send invitation link via email to:': '向以下邮箱发送测评邀请：',
  'Email template': '电子邮件模板',
  'When do you want to send an invite?': '您想要何时发送测评邀请？',
  'Email invite': '邮件邀请',
  'Email sent': '邮件已发送',

  // RequestReportModal (index.js)
  'Request report': '请求报告',
  'Request report for': '请求报告对象',
  for: '对象：',
  'Request Reports': '请求报告',
  'Common tests: ': '可用测评数据：',
  'At least 1 assessment must be common': '要生成报告，请确保所选受测者必须至少有1项相同测评数据',
  'Selected respondents': '所选受测者',
  tests: '测评',

  // RequestReportModal (ReportsList.js)
  'Available Reports': '可选测评报告',
  Norm: '常模',
  Options: '选项',
  'Ideal Profile': '理想剖析图',

  // Respondents (AddToGroup.js)
  'Add to group': '添加至分组',
  'Add respondent(s) to group': '将受测者添加至分组',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': '管理受测者标签',
  'Select from existing tags or add new': '从现有标签中选择或添加新标签',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': '添加单个受测者',
  'Import respondents from file': '从文件导入受测者',
  'Edit respondent': '编辑受测者',
  'Delete respondent': '删除受测者',

  // Respondents  (Respondents.js)
  'Delete respondents from group': '从分组中删除受测者',
  'Loading respondents': '加载中……',
  'Are you sure you want to remove': '你确定要将',
  'from the group?': '从当前组中移除吗？',

  // Reusable Accordion (Accordion.js)
  INBUILT: '标准胜任力',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': '收起全部',
  'Expand all': '展开全部',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': '日期范围',

  // Reusable  Filter (Filter.js)
  Filter: '筛选',
  'Clear all': '清除全部',
  Apply: '应用',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': '筛选类型',

  // Reusable Filter (Search.js)
  Search: '搜索',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: '自定义',

  // Reusable TextEditor (Editor.js)
  'Insert variable': '插入变量',

  // Reusable  (ConfirmationModal.js)
  No: '否',
  Yes: '是',

  // Reusable  (DatePicker.js)
  'Select the date': '选择日期',

  // Reusable  (DateTimeSelect.js)
  'Time:': '时间：',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong.': '载入中......',
  'Please try again.': '如长时间未相应，请重新刷新页面',
  Ok: '确认',

  // Reusable (Quantity.js)
  Quantity: '数量',

  // Reusable (StatusCell.js)
  'In Progress': '进行中',
  'On Hold': '暂停',
  Canceled: '已取消',
  Completed: '已完成',

  // Session SessionInvite (index.js)
  'Send invite to raters': '发送邀请给评估者',
  'Send invite to raters who have not yet completed': '发送邀请给尚未完成的评估者',
  'Send invite to raters who have not yet been invited': '发送邀请给尚未邀请的评估者',
  'Send invite to raters who have already been invited but haven&apos;t yet completed':
    '重新发送邀请给已邀请但尚未完成的评估者',
  Send: '发送',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': '电子邮件正文',
  'Email subject': '电子邮件主题',
  'Invitation date': '邀请日期',
  'Email body for Self Raters': '给自评者的电子邮件正文',
  'Email body for Other Raters': '给他评者的电子邮件正文',
  'Email subject for Other Raters': '给他评者的电子邮件主题',
  'Email subject for Self Raters': '给自评者的电子邮件主题',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.': '您所选的被评估者已在评估组中。',
  'Add session': '添加评估组',
  'Session SELF': '被评估者',
  'Search existing record to select session SELF': '从受测者名单中选择被评估者',

  // Session (SessionHeader.js)
  'All Standard': '标准版',
  '360 Assessment:': '360度评估',
  'Type:': '类型：',
  'Status:': '状态：',
  'Deadline:': '截止日期：',

  // Session (Sessions.js)
  Sessions: '评估组',
  session: '评估组',
  'Loading sessions': '加载中……',
  'Delete session': '删除评估组',
  'Delete sessions': '删除评估组',
  'this session?': '这个评估组吗？',
  'Are you sure you want to delete this project': '您确定要删除这个项目吗？',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': '加载中……',

  // Settings (AddScaleForm.js)
  'Add response scale': '添加应答选项',

  // Settings Components   (EditTitle.js)
  'Edit Name': '编辑名称',
  'Edit 360 assessment': '编辑360度评估',

  // Settings Components  (QuestionItem.js)
  Question: '问题：',
  'No translation available for': '缺少对应的翻译给',

  // Settings Components  (ReviewCompetencies.js)
  'Loading competencies': '加载中……',
  Competencies: '胜任力',
  CONFIRM: '确认',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': '名称为必填',
  'All response scales should be filled': '所有评分选项为必填',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': '您确定要移除所选',
  Collapse: '收起列表',
  Expand: '打开列表',
  '360 settings': '360度评估设置',

  // Settings (Settings.js)
  Settings: '设置',

  // (BulkActionsSidebar.js)
  'Bulk actions': '操作',

  // Sidebar.js
  'Are you sure you want to log out?': '您确定要退出登录吗？',
  'Credits balance:': '信用点余额',

  // Settings  (Settings.const.js)
  'Assessments and batteries': '测评和成套测评',
  'Ideal Profiles': '理想剖析图',
  Norms: '常模',
  '360 Assessments': '360度评估',
  'Response scales': '应答选项',
  Templates: '模板',
  'Email templates': '电子邮件模板',
  Account: '账户',
  'General info': '基本信息',
  'Credits History': '信用点记录',
  'Reports Usage': '报告生成情况',
  'Assessments Stats': '测评使用情况',

  // Settings (AssessmentsAndBatteries.js)
  assessment: '测评',
  'this battery?': '这个成套测评？',
  'Delete battery': '删除成套测评',
  'Delete batteries': '删除成套测评',
  'Assessment settings': '测评设置',
  'selected batteries?': '所选成套测评？',
  // Settings (AddNormMode.js)
  'Add New Norm': '添加新常模',
  'Norm Name': '常模名称',
  'Assessment on which the norm profile is based': '常模对应的测评',
  Frequency: '从分组中提取',
  SD: '手动输入',
  'Sample Size': '样本量',
  'Sample size must be greater than or equal to 1': '样本量必须大于或等于1',
  'Select group': '请选择组',

  // Settings (CompetencyGroups.js)
  'Copy of': '副本',
  'Competency Group': '胜任力组',
  'Delete assessment': '删除测评',
  'selected assessments': '所选测评',
  'this assessment': '这个测评',
  'Duplicate assessment': '复制测评',
  'Are you sure you want to duplicate': '您确定要复制',

  // Settings (CompetencyGroupsDetails.js)
  Structure: '结构',
  'Response scale': '应答选项',
  'Loading Competency Details': '加载中……',
  'Categories:': '胜任力类别',
  'Competencies:': '胜任力',
  'Numbers of items': '问题数',
  'Multiple choice:': '选择题',
  'Free text:': '开放题',
  'Estimated time': '预估时间',
  minutes: '分钟',
  'Available languages': '支持语言',
  'Make sure at least one language is fully supported in assessment': '请确保这个测评完全支持至少一种语言',
  Questions: '问题',
  'Competency deletion': '删除胜任力',
  'competencies?': '胜任力吗？',
  'competency?': '胜任力吗？',
  'Assessment deletion': '删除测评',
  'Are you sure you want to deleted selected the assessment?': '您确定要删除所选测评吗？',
  // Settings Templates (WelcomeText.js)
  'Welcome texts': '欢迎辞',
  'selected welcome texts?': '所选欢迎辞吗？',
  'this welcome text?': '这个欢迎辞吗？',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': '添加新欢迎辞',
  'Template name': '模板名称',
  'welcome text': '欢迎辞',
  'Delete welcome texts': '删除欢迎辞',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': '测评欢迎辞',
  template: '模板',
  'Delete templates': '删除模板',
  'selected templates?': '所选模板吗？',
  'this template?': '这个模板吗？',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': '必填项',
  'Template subject': '模板主题',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': '应答选项',
  'Delete scale(s)': '删除选项',
  'Are you sure you want to delete this scale(s)?': '您确定要删除这个选项吗？',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': '删除所选评分选项',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': '常模类型：',
  'Creation Date:': '创建日期：',

  // Settings Norms (NormsScales.js)
  'Norm Table': '常模对照表',
  'Frequency Histograms': '直方图',
  'Bio Data': '人口统计学信息',
  Stenine: '标准九分',
  Stentable: '标准十分',

  // Settings Norms (Norms.js)
  'Add new norm': '添加新常模',
  'Delete norm(s)': '删除常模',
  'Assessment and Batteries': '测评和成套测评',
  'selected norms?': '所选常模吗？',
  'this norm?': '这个常模吗？',
  'Creation Date': '创建日期',

  // Settings Norms (HistogramView.js)
  FREQUENCY: '频次',
  'RAW SCORE': '原始分',
  'Raw Score': '原始分',

  // Settings ManageBattery (index.js)
  'Battery name': '成套测评名称',
  'Search existing assessments': '搜索成套测评',
  'Add battery': '添加成套测评',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': '理想剖析图类型：',
  'Unsaved data': '未保存的数据：',
  'The current ideal profile has not been saved. Would you like to save it?': '当前理想剖析图尚未保存，您想要保存吗？',

  // Sidebar.js
  Logout: '退出登录',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': '删除理想剖析图',
  'selected ideal profiles?': '所选理想剖析图吗？',
  'this ideal profile?': '这个理想剖析图吗？',

  // Settings AddIdealProfile.js
  'Add ideal profile': '添加理想剖析图',
  'Ideal profile name': '理想剖析图名称',
  'I will manually select the values': '手动配置剖析图值',
  'I will use respondent data to determine the values': '基于受测者数据配置剖析图值',
  Test: '测评',
  // (advancedFilterOptions.js)
  'Is equal to': '等于',
  'Is not equal to': '不等于',
  'Starts with': '为开头',
  Contains: '包含',
  'Does not contain': '不包含',
  'Ends with': '为结尾',
  'Lesser than': '小于',
  'Greater than': '大于',
  '1 day': '1天内',
  '3 days': '3天内',
  '1 week': '1周内',
  '2 weeks': '2周内',
  '1 month': '1个月内',
  '1 year': '1年内',

  // groupsTypes.js
  Unsupervised: '无人监督模式',
  Supervised: '有人监督模式',
  // idealProfileTypes.js
  'Manually configured': '手动配置',
  'Based on respondent data': '基于受测者数据配置',

  // inviteCreationCategories.js
  'Personality tests': '人格',
  'Interests Values Attitudes': '兴趣/价值观/态度',
  Reasoning: '推理能力',
  Value: '价值观',
  'Preferences/Styles': '偏好/风格',
  'Custom battery': '自定义成套测评',
  'General Solutions': '通用解决方案',
  'Screening Solutions': '筛选解决方案',
  Solution: '解决方案',
  Battery: '成套测评',
  Assessment: '测评',
  'Every 4 days': '每4天',
  'Every 3 days': '每3天',
  'Every 2 days': '每2天',
  'Every day': '每天 ',
  "Don't send": '不发送',
  Immediate: '即刻发送',

  // inviteStatuses.js
  'Not sent': '未发送',
  Scheduled: '已预约',
  Sent: '已发送',
  Failed: '发送失败',

  // raterRoles.js
  Manager: '上级',
  Peer: '同事',
  'Direct report': '下属',

  // respondentSelectOptions.js
  'No Qualification': '无教育程度信息',
  'Secondary School': '初高中',
  'Industry or Trade Training': '行业培训证书',
  'Professional Qualification': '职业资格证书',
  'Tertiary Institute Diploma/Certificate': '大中专',
  'University Bachelor Degree': '本科学位',
  'Postgraduate Diploma/Certificate': '研究生文凭（学历）',
  'Bachelor Honours': '本科荣誉学位',
  'Masters Degree': '硕士学位',
  'Doctorate Degree': '博士学位',
  'African American': '',
  Aborigine: '',
  Algerian: '',
  African: '',
  Afrikaner: '',
  Albanian: '',
  American: '',
  Argentinian: '',
  'Australian Aborigine': '',
  Arab: '',
  Asian: '',
  Austrian: '',
  Australian: '',
  Bangladeshi: '',
  Balinese: '',
  'Black British': '',
  'Black Canadian': '',
  Belgian: '',
  'Black Carribean': '',
  'Black Indian': '',
  'Black African': '',
  Bolivian: '',
  Bengali: '',
  'Black Other': '',
  Bosnian: '',
  Brazilian: '',
  British: '',
  Belarusian: '',
  Bulgarian: '',
  Burmese: '',
  Canadian: '',
  Castilian: '',
  Catalan: '',
  Celtic: '',
  Chinese: '中国人',
  'Channel Islander': '',
  Cossack: '',
  Chilean: '',
  Cambodian: '',
  Chechen: '',
  Colombian: '',
  'Costa Rican': '',
  Croatian: '',
  Corsican: '',
  Cantonese: '',
  Cuban: '',
  Cypriot: '',
  'Cypriot Greek': '',
  Czech: '',
  Danish: '',
  Dutch: '',
  Eurasian: '',
  Ecuadorian: '',
  Egyptian: '',
  English: '',
  Estonian: '',
  Ethiopian: '',
  European: '',
  Fijian: '',
  Filipino: '',
  Flemish: '',
  Finnish: '',
  French: '',
  Gaelic: '',
  Georgian: '',
  German: '',
  Greek: '',
  Hungarian: '',
  Hispanic: '',
  'Hong Kong Chinese': '中国人（香港地区）',
  Icelandic: '',
  Indonesian: '',
  Irani: '',
  Indian: '',
  'Iranian/Persian': '',
  Iraqi: '',
  Irish: '',
  'Iranian Arab': '',
  Iranian: '',
  Italian: '',
  Jamaican: '',
  Jewish: '',
  'Jewish/Israeli': '',
  Jordanian: '',
  Japanese: '',
  Kenyan: '',
  Kiwi: '',
  Kongo: '',
  Korean: '',
  Latin: '',
  Lebanese: '',
  Lithuanian: '',
  Libyan: '',
  'Latin American': '',
  Latino: '',
  Luxembourger: '',
  Latvian: '',
  Macedonian: '',
  'Middle Eastern': '',
  Malaitian: '',
  Maori: '',
  Monacan: '',
  Moroccan: '',
  Maltese: '',
  Mexican: '',
  Malay: '',
  'Mixed Race': '',
  'New Zealand European': '',
  Nigerian: '',
  Norwegian: '',
  Nepalese: '',
  'New Zealander': '',
  'Other Ethnicity': '',
  Paraguayan: '',
  'Pacific Islander': '',
  Persian: '',
  Peruvian: '',
  'Persian/Iranian': '',
  Pakistani: '',
  Palestinian: '',
  Polish: '',
  'Papua New Guinean': '',
  'Puerto Rican': '',
  Portuguese: '',
  Polynesian: '',
  Romanian: '',
  Russian: '',
  Samoan: '',
  'South African Coloured': '',
  Scottish: '',
  Swede: '',
  'South African European': '',
  Serbian: '',
  Swedish: '',
  'South American Indian': '',
  Sicilian: '',
  Slovak: '',
  Slav: '',
  Spanish: '',
  'Sri Lankan': '',
  'Southeast Asian': '',
  Swiss: '',
  Swahili: '',
  Swazi: '',
  Taiwanese: '中国人（台湾地区）',
  Thai: '',
  Turkish: '',
  Tamil: '',
  Tongan: '',
  Rwandan: '',
  Tunisian: '',
  Udi: '',
  Ugandan: '',
  Ukrainian: '',
  Uruguayan: '',
  Vietnamese: '',
  Welsh: '',
  White: '',
  'West Indian': '',
  Zimbabwean: '',
  Zulu: '',
  '- Chief executive': '- C级别高管',
  '- Administrative or commercial manager': '- 管理/商务经理',
  '- Production or specialized services manager': '- 生产/特殊服务经理',
  '- Hospitality, retail or other services manager': '- 酒店/零售/其他服务经理',
  '- Senior manager': '- 高级经理',
  '- Middle manager': '- 中级经理',
  '- Junior manager/supervisor': '- 初级经理/主管',
  '- Other': '- 其他',
  Professional: '专业人员',
  '- Science or engineering professional': '- 科学/工程',
  '- Health professional': '- 医疗卫生专业人员',
  '- Teaching professional': '- 教育/教学专业人员',
  '- Business or administration professional': '- 商业/管理专业人员',
  '- Information and communications technology professional': '- 信息和通信技术专业人员',
  '- Legal, social or cultural professional': '- 法律/社会/文化专业人员',
  'Technician or associate professional': '技术人员或专业人员助理',
  '- Science or engineering associate professional': '- 科学/工程技术人员',
  '- Health associate professional': '- 医疗卫生技术人员',
  '- Business or administration associate professional': '- 商业/管理技术人员',
  '- Legal, social, cultural or related associate professional': '- 法律/社会/文化技术人员',
  '- Information and communications technician': '- 信息和通信技术技术人员',
  'Clerical support worker': '支持人员',
  '- General or keyboard clerk': '- 一般支持人员',
  '- Customer services clerk': '- 客服支持人员',
  '- Numerical or material recording clerk': '- 记录人员',
  'Service or sales worker': '服务及销售人员',
  '- Personal service worker': '- 个人服务人员',
  '- Sales worker': '- 销售人员',
  '- Personal care worker': '- 个人护理人员',
  '- Protective services worker': '- 安保人员',
  'Skilled agricultural, forestry or fishery worker': '农林渔业从业人员',
  '- Market-oriented skilled agricultural worker': '- 农业从业人员（市场导向）',
  '- Market-oriented skilled forestry, fishing or hunting worker': '- 林渔业从业人员（市场导向）',
  '- Subsistence farmer, fisher, hunter or gatherer': '- 务农、畜牧、捕鱼业者',
  'Craft or related trades worker': '工艺及有关人员',
  '- Building or related trades worker, excluding electrician': '- 建筑有关人员',
  '- Metal, machinery or related trades worker': '- 金属和机械有关人员',
  '- Handicraft or printing worker': '- 手工艺和印刷有关人员',
  '- Electrical or electronic trades worker': '- 电子和电器有关人员',
  '- Food processing, wood working, garment or other craft or related trades':
    '- 食品加工/木材/服饰和其他手工艺有关人员',
  'Plant or machine operator, or assembler': '机械机床操作员和装配工',
  '- Stationary plant or machine operator': '- 机械机床操作员',
  '- Assembler': '- 组装员',
  '- Driver or mobile plant operator': '- 驾驶和移动机械操作员',
  'Elementary occupation': '非技术人员',
  '- Cleaner or helper': '- 清洁有关人员',
  '- Agricultural, forestry or fishery labourer': '- 农林渔业劳动者',
  '- Labourer in mining, construction, manufacturing or transport': '- 采矿/建筑/制造/运输有关人员',
  '- Food preparation assistant': '- 食品准备有关人员',
  '- Street or related sales or service worker': '- 街头工作有关人员',
  '- Refuse worker or other elementary worker': '- 垃圾处理有关人员',
  'Armed forces occupation': '军人',
  '- Commissioned armed forces officer': '- 部队军官',
  '- Non-commissioned armed forces officer': '- 非士官',
  '- Armed forces occupation, other rank': '- 部队其他职位',
  'Local Government/Authority': '政府部门和权威机构',
  'Private, Corporate ': '私营和民营企业',
  'Public service, Fed/State Govt': '公共事业管理服务部门',
  'State Owned Corporation': '国有企业',
  Unknown: '未知',
  'Administrative and Support Service Activities': '管理和支持服务',
  'Agriculture, Forestry and Fishing': '农业、林业和渔业',
  'Arts, Entertainment and Recreation': '艺术、娱乐和休闲',
  'Banking & Finance': '银行和金融',
  'Business Services': '商业服务',
  'Architecture and Construction': '建筑',
  'Education and Training': '教育和培训',
  'Utilities (Gas, Water, Electric, etc)': '公共事业（天然气、水、电等）',
  Engineering: '工程制造',
  'Hospitality and Tourism': '酒店和旅游',
  'Human Resources': '人力资源',
  'Health Sector': '健康和卫生',
  'Information and Technology': '信息技术',
  Insurance: '保险',
  'Law Enforcement': '法律',
  Manufacturing: '制造',
  'Mining and Quarrying': '采矿和采石',
  'Military & Defence': '军事和国防',
  'Oil and Gas/Energy Exploration': '石油、天然气和能源勘探',
  'Pharmaceutical Industry': '制药',
  'Public Administration': '公共管理和行政',
  'Real Estate Activities': '房地产',
  'Scientific and Technical Activities': '科技活动',
  Security: '安保',
  'Social Work': '社会工作',
  Telecommunications: '电信',
  'Transportation and Storage': '运输和仓储',
  Media: '媒体',
  'Wholesale and Retail Trade': '批发和零售贸易',
  Arabic: '',
  'Brazilian Portuguese': '',
  'Chinese-Simplified': '中文（简体）',
  'Chinese-Traditional': '中文（繁体）',
  Hebrew: '',
  'Hindi-Urdu': '',
  isiNdebele: '',
  isiXhosa: '',
  isiZulu: '',
  'Latin American Spanish': '',
  Malayalam: '',
  Punjabi: '',
  Sepedi: '',
  'Southern Thai': '',
  Sesotho: '',
  Setswana: '',
  siSwati: '',
  'Tagalog (Filipino)': '',
  Turkmen: '',
  Tshivenda: '',
  Xitsonga: '',
  Male: '男',
  Female: '女',
  'Not Specified': '暂不提供',

  // errorMessages.js
  'Email address is not valid': '无效的电子邮箱',

  // table bulkActions assessments.js
  'Edit battery': '编辑成套测验',
  // table singleRowActions competency.js
  'Add competency': '添加胜任力',
  'Edit competency category': '编辑胜任力大类',
  'Delete competency category': '删除胜任力大类',
  'Edit competency': '编辑胜任力',
  'Delete competency': '删除胜任力',
  'Edit question': '编辑问题',
  'Delete question': '删除问题',

  // table singleRowActions competencyGroup.js
  'Delete competency group': '删除胜任力组',
  'Go to competency groups details': '查看胜任力组信息',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': '邀请受测者完成测评',
  'Administer answer sheet': '答题纸',
  'Administer managed group': '开放组',
  'Create an ideal profile based on selected group': '根据所选分组创建理想剖析图',
  'Create a Norm based on selected group': '根据所选分组创建常模',
  'View or edit': '查看或编辑',
  Delete: '删除',

  // table singleRowActions invites.js
  Remind: '提示：',

  // table singleRowActions projects.js
  'Add to favorites': '添加到收藏夹',
  View: '视图',
  'Delete Project': '删除项目',

  // table singleRowActions raters.js
  'Delete rater': '删除评分者',

  // table singleRowActions reports.jsAdminister questionnaire
  Open: '打开',
  Download: '下载',

  // table singleRowActions respondents.js
  'Administer questionnaire': '监督测验',
  'Invite to questionnaire': '远程邀请测验',
  'Add tag': '添加标签',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': '向未完成的评估者发送邀请',
  'Invite who have not yet been invited': '向未邀请的评估者发送邀请',
  'Send reminder to raters who have already been invited but have not yet completed': '向未完成的评估者发送提醒',
  'Invite to specify own rater': '邀请被评估人自行指定评估者',
  'Send invitation to self': '向被评估人发送邀请',
  'Edit session': '编辑评估组',

  // table bulkActions assessments.js
  'Add custom battery': '添加成套测评',
  'Edit assessment': '编辑成套测评',
  'Delete Assessment(s)': '删除成套测评',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': '根据所选分组创建常模',
  'Create an Ideal profile based on the selected group(s)': '根据所选分组创建理想剖析图',
  'Delete selected group(s)': '删除所选分组',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': '编辑理想画像',

  // table bulkActions invites.js
  'Copy invite link to clipboard': '将邀请链接复制到剪贴板',
  'Delete invites': '删除测评邀请',

  // table bulkActions projects.js
  'Edit project details': '编辑项目信息',
  'Delete projects(s)': '删除项目',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': '向所选评估者发送邀请',
  'Remove selected rater(s) from session': '从评估组中删除所选评估者',

  // table bulkActions reports.js
  Actions: '操作',
  'Delete selected report(s)': '删除所选报告',
  'Download selected report(s)': '下载所选报告',
  'View selected report(s)': '查看所选报告',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': '从所选分组中移除受测者',
  // table bulkActions respondents.js
  'Administer assessment': '监督测验',
  'Administer assessment for': '监督测验',
  'Invite respondent(s) to assessment ': '远程邀请受测者',
  'Request report(s)': '请求报告',
  'Go to respondent details': '查看受测者信息',
  'Manage tags for selected respondent(s) ': '管理所选受测者标签',
  'Add new group from selection': '添加至新分组',
  'Add to existing group ': '添加至现有分组',
  'Delete selected respondents from group': '从分组中删除受测者',
  'Delete selected respondents': '删除所选受测者',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': '删除所选结果',

  // table bulkActions sessions.js
  'Invite to specify own raters': '邀请被评估者自行指定评估人',
  'Delete Session(s)': '删除评估组',

  // table bulkActions templates.js
  'Go to template details': '查看模板信息',
  'Delete selected template(s)': '删除所选模板',
  // table bulkActions welcomeText.js
  'Go to welcome text details': '查看欢迎辞',
  'Delete selected welcome text(s)': '删除所选欢迎辞',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': '编辑标题',
  'Duplicate 360 assessment': '复制360度评估',
  'Delete 360 assessment': '删除360度评估',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': '邀请未完成的评估者',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': '查看胜任力',

  // table tableActions customGroups.js
  'Add new 360 assessment': '添加新360度评估',

  // table tableActions raters.js
  'Add rater': '添加评估者',
  // table tableActions respondents.js
  'Import respondents': '批量添加受测者',

  // table tableActions templates.js
  'Add new template': '添加新模板',
  // QuestionOrder.js
  Default: '默认排序',
  Custom: '自定义排序',
  Random: '随机排序',
  // tables columns sessions.js
  Invited: '已邀请',
  'Invite Not Sent': '邀请未发送',

  'Show expired': '显示已过期邀请',
  'Sorry we could not find any': '抱歉，我们无法找到任何 %{entityName}',
  'Number of items': '问题数',
  Categories: '胜任力类别',
  'User name': '用户名',
  'Update amount': '数量',
  Members: '成员',
  'Invitation Date': '邀请日期',
  'Respondent Name': '受测者姓名',
  Report: '报告',
  'Date submitted': '提交日期',
  Status: '状态',
  'Not invited': '未邀请',
  'Not completed': '未完成',
  'Completed Date': '完成日期',
  'Direct Report': '下属',
  Progress: '完成进度',
  'Assessments Done': '完成的测评',
  'Add new project': '添加新项目',
  Date: '日期',
  'Loading entities': '加载中……',
  Created: '创建',

  // Settings Account ChangePassword.js

  'Passwords must match': '密码必须保持一致',
  'Change password': '更改密码',
  'Confirm new password': '确认新密码',
  'New password': '新密码',
  Change: '确认更改',
  'Pins must match': 'PIN码必须保持一致',
  'Change pin': '更改PIN码',
  'Confirm new pin': '确认新PIN码',
  'New pin': '新PIN码',
  'Total:': '已添加信用点总数：',
  'Loading credits history...': '加载中……',
  'From date': '开始日期',
  'To date': '截止日期',
  Pin: 'PIN码',
  'Receive email notifications': '启用系统提醒邮件',
  'Complete Status': '完成状态',
  'Invite Status': '邀请状态',
  'Not Invited': '未邀请',
  'Sessions count': '评估组数量',

  // ImportSessions
  'Import sessions': '批量添加评估组',
  prepare: '准备导入文件',
  preview: '预览',
  import: '导入',
  'Prepare a csv file yourself or': '您可以自行创建csv文档，或者',
  'download the sample import file': '点此链接下载样例csv文档',
  'Make sure the data order is set out correctly': '请确保csv文档格式正确（可参考样例文档）',
  'Upload the file': '上传文件',
  'Drag and drop file here or browse the file': '请将准备好的csv文档拖到此处，或点击下面的按钮手动选择文件',
  'Upload the csv file to import respondents': '上传csv文档批量添加受测者',
  'The first line contains field headers': '数据包含标题',
  'Choose file': '选择文件',
  Next: '下一步',

  // AccountTabs
  general: '基本设置',
  'credit updates': '信用点统计',
  'Reports Stats': '报告使用统计',
  'assessments stats': '测评工具使用统计',

  // ReportUsage
  total: '合计: %{total}',
  'Report name': '报告',
  'Usage count': '数量',

  // vars
  LoginSubmit: '로그인합니다',
  'Reset assessment deadline': '평가 마감일 재설정',
  Update: '更新',
  updateAssessmentInvitationToast: '实际邀请',
  updateProjectInvitationToast: '实际邀请',
  biodataTooltip: '生物数据模板允许从受访者那里收集定制的传记数据。在“设置”区域中创建生物数据模板',
  'Default Biodata Template has been updated': '默认生物数据模板已更新',
  DEFAULT: '默认',
  'Bio data template': '生物数据模板',
  biodataDefaultTemplateLabel: '** 无生物数据模板（包括所有类别）**',
  'Add Bio Data template': '添加生物数据模板',
  'Loading biodata templates': '加载生物数据模板',
  'Add Name': '添加姓名',
  'Add description': '添加描述',
  selectEditCategoryValues: '选择要向受访者显示的类别和值：',
  'Add values to category': '将值添加到类别',
  'Select values for this category': '选择该类别的值',
  'Select all': '全选',
  'Biodata Template has been created': '生物数据模板已创建',
  'Biodata Template has been updated': '生物数据模板已更新',
  'Biodata template(s) deleted': '生物数据模板已删除',
  biodataDefaultTemplate: '制作默认生物数据模板',
  'Delete selected biodata template(s)': '删除选定的生物数据模板',
  'Delete biodata templates': '删除生物数据模板',
  'the selected biodata templates?': '所选的生物数据模板',
  'this biodata template?': '这个生物数据模板',
  'Update default biodata template': '更新默认生物数据模板',
  'Are you sure you want to set this template as default?': '您确定要将此模板设为默认模板吗',
  'Biodata Configuration': '详细信息',
  defaultCompleteBioTemplate: '** 모든 이력서 템플릿 (모든 카테고리 포함) **',
  defaultEmptyBioTemplate: '** 빈 이력서 템플릿 (카테고리 포함 안 됨) **',
  invitesSentToast: '초대장이 발송되었습니다',
  selectNormForReport: '규범을 선택해 주세요',
  selectIdealProfileForReport: '이상적인 프로필을 선택하세요',
  visibleNormForReport: '보고서에 규범 사용',
  hideNormForReport: '보고서에 규범 숨기기',
  showNormTitle: '보고서 요청 목록에 표시',
  showConfirmationMessageTitle: '표시하시겠습니까',
  hideNormTitle: '보고서 요청 목록에서 숨기기',
  hideConfirmationMessageTitle: '제거하시겠습니까',
  showhideConfirmationMessageDescription1: '선택된 규범을 보고서 생성에 사용하시겠습니까?',
  showhideConfirmationMessageDescription2: '이 규범을 보고서 생성에 사용하시겠습니까?',
  visible: '표시됨',
  normsVisibilityToast: '규범 가시성이 업데이트되었습니다',
  updateGroupToast: '그룹이 업데이트되었습니다',
  updateGroupErrorToast: '그룹 업데이트 중 오류 발생',
  'Edit Group Details': '그룹 세부 정보 편집',
  'Duplicate Group': '그룹 복제',
  emptyLanguageReports: '죄송합니다. 이 언어로 사용할 수 있는 보고서를 찾을 수 없습니다',
  Notes: '메모',
  'Forgot password?': '비밀번호를 잊으셨나요?',
  'You should receive your reset link soon at': '곧 재설정 링크를 받게 될 것입니다',
  "Can't find the email?": '이메일을 찾을 수 없나요?',
  'You should also check your spam folder': '스팸 폴더도 확인해야 합니다',
  'Email address': '이메일 주소',
  'Request a reset link': '재설정 링크 요청',
  'Back to login page': '로그인 페이지로 돌아가기',
  downloadInvitesReport: 'csv 다운로드',
  csvReportName: '초대 보고서 %{date}.csv',
  'Copy and share generated links for': '생성된 링크를 복사하여 공유',
  'that have email address missing': '이메일 주소가 없는',
  'You have chosen a group of': '당신은 그룹을 선택했습니다',
  'have email address missing': '이메일 주소가 없습니다',
  'This 360 survey is used by one or more project, therefore can not be edited or deleted':
    '이 360 설문조사는 하나 이상의 프로젝트에서 사용되므로 편집하거나 삭제할 수 없습니다',
  and: '그리고',
  more: '더',
  missingRequestedNorms: '선택한 보고서에 대한 규범 및/또는 이상적인 프로필이 누락되었습니다',
  'You will be redirected shortly': '곧 리디렉션됩니다',
  oneremainingnorm: '이 테스트에서는 최소한 하나의 규범이 표시되어야 합니다',
};
export default translations;
