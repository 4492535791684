import { useMemo } from 'react';

import modifyWithColumnNames from '../utils/modifyAppliedInputsWithColumnNames';

const useTableFilter = ({ filter, columns, setFilterValue, setFilterType }) => {
  const inputsWithHandlers = useMemo(() => {
    const createInputHandler = (fieldName, type) => (e) => {
      const validateTextValue = (value) => {
        if (type === 'number' && Number(value) < 0) return;
        return value.trim() === '' ? value.trim() : value;
      };
      const value = type === 'date' ? e : validateTextValue(e.target.value);
      setFilterValue(fieldName, value);
    };
    const createSelectHandler = (fieldName) => (value) => setFilterType(fieldName, value);
    const createSelectTypeHandler = (fieldName) => (value) => {
      setFilterValue(fieldName, value);
      setFilterType(fieldName, value);
    };
    const createDeleteHandler = (fieldName, type) => () => {
      const initValue = type === 'date' ? null : '';
      setFilterType(fieldName, 'initial');
      setFilterValue(fieldName, initValue);
    };
    const inputs = filter.isApplied && !filter.dirty ? filter.appliedInputs : filter.inputs;
    return inputs.map((input) => {
      const column = columns.find((column) => column.name === input.name);
      const finalObj = {
        ...input,
        onChange:
          input.type === 'select' ? createSelectTypeHandler(input.name) : createInputHandler(input.name, input.type),
        onSelectChange: createSelectHandler(input.name),
        onDelete: createDeleteHandler(input.name, input.type),
        columnName:
          // eslint-disable-next-line no-nested-ternary
          (typeof column.Header === 'function' ? column.stringHeader : column.Header) === 'Name'
            ? 'PersonName'
            : typeof column.Header === 'function'
            ? column.stringHeader
            : column.Header,
        uiSelectName: column.uiSelectName,
        options: column.options,
      };
      if (input.type === 'select') {
        finalObj.uiSelectName = column.uiSelectName;
        finalObj.options = column.options;
      }
      return finalObj;
    });
  }, [filter.inputs, columns]);

  const appliedFiltersWithColumnNames = useMemo(() => {
    return modifyWithColumnNames(filter.appliedInputs, columns);
  }, [filter.appliedInputs]);

  return { inputsWithHandlers, appliedFiltersWithColumnNames };
};

export default useTableFilter;
