import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';

import PageWrapper from '../../../PageWrapper';
import TableTabs from '../../../reusable/TableTabs';

import Spinner from '../../../reusable/Spinner';
import { fetchNotifications, resetNotificationsState } from '../../../../store/notifications/actions';

import Button from '../../../reusable/Buttons/Button';

import accountTabs from '../accountTabs';
import ReportsTable from './ReportsTable';
import ResultsTable from './ResultsTable';

const generateTabs = (user) => [
  {
    name: 'results',
    label: I18n.t('tests completed'),
  },
  ...(user?.userType === 3
    ? []
    : [
        {
          name: 'reports',
          label: I18n.t('reports requested'),
        },
      ]),
];

const ActivityLog = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const goBackToSettings = () => dispatch(push('/settings'));

  const onPageTabClick = (tab) => dispatch(push(tab.path));

  const pageTabs =
    user?.userType === 3
      ? accountTabs.filter((item) => item.name === 'general' || item.name === 'assessmentsUsage')
      : accountTabs;

  const notifications = useSelector((state) => state.notifications.notifications);
  const [isLoading, setLoadingState] = useState(false);

  const tabs = generateTabs(user);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [shouldResetPage, setShouldReset] = useState(false);
  const [exportTrigger, setExportTrigger] = useState(0);
  const [haveRows, setHaveRows] = useState(false);

  useEffect(() => {
    setLoadingState(true);
    dispatch(
      fetchNotifications(
        {},
        {
          shouldReset: true,
          notificationType: tabs.findIndex((tab) => tab.name === activeTab.name),
          callback: () => {
            setLoadingState(false);
          },
        },
      ),
    );
  }, [activeTab]);

  useEffect(() => {
    if (activeTab.name === 'results' && notifications?.length) {
      setHaveRows(true);
    }

    if (activeTab.name === 'reports') {
      setHaveRows(false);
    }
  }, [notifications, activeTab]);

  const onTabClick = (newTab) => {
    setActiveTab(newTab);
    dispatch(resetNotificationsState());
    setShouldReset(true);
  };

  return (
    <PageWrapper style={{ overflowY: 'auto' }} title={I18n.t('Account')} backButtonHandler={goBackToSettings}>
      <TableTabs tabs={pageTabs} activeTab={accountTabs[4]} clickHandler={onPageTabClick} />
      <StyledWrapper>
        <div></div>
        <StyledTableTabs tabs={tabs} activeTab={activeTab} clickHandler={onTabClick} />
        <Button
          style={{
            marginLeft: 'auto',
            alignSelf: 'center',
            height: '30px',
            ...(!haveRows && { opacity: '0.6', pointerEvents: 'none' }),
          }}
          siz="s"
          onClick={() => setExportTrigger(exportTrigger + 1)}
        >
          Export
        </Button>
      </StyledWrapper>
      <Spinner isLoading={isLoading} />
      <div>
        {activeTab.name === 'reports' ? (
          <ReportsTable exportTrigger={exportTrigger} onRowsLoaded={() => setHaveRows(true)} />
        ) : (
          <ResultsTable exportTrigger={exportTrigger} rows={notifications} />
        )}
      </div>
    </PageWrapper>
  );
};

const StyledTableTabs = styled(TableTabs)`
  border-bottom: none;

  > div {
    justify-content: center;
  }
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
`;

ActivityLog.propTypes = {};

export default ActivityLog;

// {"pagination":{"PageNumber":0,"PageSize":40},"sortBy":[],"search":"","appliedFilters":[]}
