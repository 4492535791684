import React from 'react';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';

import raterRoles from '../../constants/raterRoles';
import { caseInsensitiveSortingFunc } from './respondents';
import isChineseEnv from '../../utils/isChineseEnv';

const columns = [
  {
    Header: I18n.t('Name'),
    accessor: (data) =>
      isChineseEnv ? `${data.familyName || ''}${data.firstName}` : `${data.firstName} ${data.familyName}`,
    name: 'name',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Name',
  },
  {
    Header: I18n.t('Email Address'),
    accessor: 'email',
    name: 'email',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Email',
  },
  {
    Header: I18n.t('Role'),
    accessor: (data) => {
      const role = raterRoles().find((item) => item.backendValueReference === data.role);
      return role ? role.label : '';
    },
    name: 'role',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Role',
  },
  {
    Header: I18n.t('Completed Date'),
    accessor: (data) => new Date(data.completedDate),
    Cell: ({ row }) => (
      <span>{row.original.completedDate ? format(new Date(row.original.completedDate), 'dd/M/y') : ''}</span>
    ),
    name: 'completedDate',
    sortType: 'datetime',
    type: 'date',
    filteringName: 'CompletedDate',
    width: 100,
  },
];

export default columns;
