import { I18n } from 'react-redux-i18n';
import { ReactComponent as AdministerAssessmentIcon } from '../../assets/icons/sidebar-administer.svg';
import { ReactComponent as NormIcon } from '../../assets/icons/sidebar-group-based-norm.svg';
import { ReactComponent as IdealProfileIcon } from '../../assets/icons/sidebar-group-based-idealprofile.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as InviteIcon } from '../../assets/icons/invite-to-questionnaire.svg';
import { ReactComponent as RequestReportIcon } from '../../assets/icons/request-report.svg';
import { ReactComponent as CopyLinkIcon } from '../../assets/icons/sidebar-copy-link.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicate.svg';

import * as actions from '../actionsList';

const canRequestReports = (groups) => {
  return !groups.every((group) => group.isReportsAvailable);
};

const groupsBulkActions = (user) => {
  const actionsHiddenFromAdmin = [
    actions.CREATE_NORM_BASED_ON_GROUP,
    actions.CREATE_PROFILE_BASED_ON_GROUP,
    actions.GROUP_REQUEST_REPORT,
  ];
  const actionsList = [
    {
      name: actions.EDIT_GROUP_DETAILS,
      label: I18n.t('Edit Group Details'),
      Icon: EditIcon,
      isDisabled: (groups) => groups.length > 1 || groups[0]?.hasActiveAdministeredSession,
    },
    {
      name: actions.DUPLICATE_GROUP,
      label: I18n.t('Duplicate Group'),
      Icon: DuplicateIcon,
      isDisabled: (groups) => groups.length > 1,
    },
    {
      name: actions.ADMINISTER_GROUP_SESSION,
      label: I18n.t('Administer Managed Group Session'),
      Icon: AdministerAssessmentIcon,
      isDisabled: (groups) => groups.length > 1 || groups[0]?.hasActiveAdministeredSession,
    },
    {
      name: actions.ADMINISTER_MANAGED_GROUP,
      label: I18n.t('Generate Open Group Link'),
      Icon: CopyLinkIcon,
      isDisabled: (groups) => groups.length > 1,
    },
    {
      name: actions.CREATE_NORM_BASED_ON_GROUP,
      label: I18n.t('Create Norm from a Group'),
      Icon: NormIcon,
      isDisabled: (groups, user) =>
        user?.userType === 1 || groups.every((item) => item.groupSize === 0) || canRequestReports(groups),
    },

    {
      name: actions.CREATE_PROFILE_BASED_ON_GROUP,
      label: I18n.t('Create an Ideal Profile from a Group'),
      Icon: IdealProfileIcon,
      isDisabled: (groups, user, shouldDisableFeature) =>
        (shouldDisableFeature?.solutionUsers?.disableCreateIdealProfileFromGroup && user?.userType === 1) ||
        groups.every((item) => item.groupSize === 0),
    },

    // {
    //   name: actions.MANAGE_ACTIVE_LINKS,
    //   label: I18n.t('Manage active links'),
    //   Icon: ActiveLinksIcon,
    //   isDisabled: (groups) => groups.length > 1,
    // },
    {
      name: actions.GROUP_CREATE_INVITE,
      label: I18n.t('Invite Respondents for Assessment'),
      Icon: InviteIcon,
      isDisabled: (groups) => groups.length > 1 || groups.every((item) => item.groupSize === 0),
    },
    {
      name: actions.GROUP_REQUEST_REPORT,
      label: I18n.t('Request Reports'),
      Icon: RequestReportIcon,
      isDisabled: (groups) => {
        const areReportsUnavailable = groups.every((item) => !item.isReportsAvailable);
        return groups.length > 1 || groups.every((item) => item.groupSize === 0) || areReportsUnavailable;
      },
    },
    {
      name: actions.DELETE_GROUPS,
      label: I18n.t('Delete selected Group'),
      Icon: DeleteIcon,
    },
  ];

  return user?.userType === 3 ? actionsList.filter((item) => !actionsHiddenFromAdmin.includes(item.name)) : actionsList;
};

export default groupsBulkActions;
