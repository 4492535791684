import { format, isAfter } from 'date-fns';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import inviteStatuses from '../../../constants/inviteStatuses';
import isChineseEnv from '../../../utils/isChineseEnv';

const statusOptions = Object.keys(inviteStatuses).map((key) => ({ value: key, label: inviteStatuses[key] }));

const DateCell = ({ row }) => {
  if (!row.original.expiryDate) return '';
  const hasExpired = isAfter(new Date(), new Date(row.original.expiryDate));
  const color = hasExpired ? '#FFA4A4' : '#40C4FF';
  return <span style={{ color }}>{format(new Date(row.original.expiryDate), 'dd/M/y')}</span>;
};

const columns = () => [
  {
    Header: I18n.t('Name'),
    accessor: (data) =>
      isChineseEnv ? `${data.familyName || ''} ${data.firstName}` : `${data.firstName} ${data.familyName || ''}`,
    name: 'name',
    type: 'text',
    // sortType: caseInsensitiveSortingFunc,
    filteringName: 'Name',
  },
  {
    Header: I18n.t('Email Address'),
    accessor: 'email',
    name: 'email',
    type: 'text',
    // sortType: caseInsensitiveSortingFunc,
    filteringName: 'Email',
  },

  {
    Header: I18n.t('Invite status'),
    name: 'status',
    accessor: (data) => inviteStatuses[data.invitationStatus],
    type: 'select',
    options: statusOptions,
    filteringName: 'InvitationStatus.Value',
  },

  {
    Header: I18n.t('Expiry Date'),
    uiSelectName: I18n.t('Hide expired'),
    name: 'expiryDate',
    accessor: (data) => new Date(data.expiryDate),
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
    type: 'select',
    options: [
      { value: false, label: I18n.t('No') },
      { value: true, label: I18n.t('Yes') },
    ],
    filteringName: 'HideExpired.Value',
    width: 100,
  },
  // {
  //   Header: '',
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler && item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedInvites(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

export default columns;
