import React from 'react';
import { string, array, object, bool } from 'prop-types';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down.svg';

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
  control: (provided) => {
    return {
      ...provided,
      height: '24px',
      minHeight: '24px',
      paddingLeft: 0,
      border: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid hsl(0,0%,80%)',
      borderRadius: 'none',
      '&:hover': {
        borderBottom: '1px solid hsl(0,0%,80%)',
      },
      '& > div:first-of-type': {
        width: '80%',
      },
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    borderRadius: '2rem',
    fontSize: '10px',
    color: '#375563',
    opacity: 0.5,
  }),
  singleValue: () => ({
    color: '#375563',
    fontWeight: 600,
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  }),
  valueContainer: () => ({
    paddingLeft: 0,
    display: 'flex',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? '#40C4FF' : '#FEFCFC',
      color: state.isSelected ? '#fff' : '#375563',
      '&:hover': {
        backgroundColor: state.isSelected ? '#40C4FF' : '#F1F1F1',
      },
    };
  },
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledArrow />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, selectProps, ...props }) => (
  <components.SingleValue {...props}>
    <SelectValueHolder>
      <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{children}</div>
    </SelectValueHolder>
  </components.SingleValue>
);

const CompactSelect = ({ label, options, className, selectProps, allowSearch }) => {
  return (
    <SelectWrapper className={className}>
      {label && <Label>{label}</Label>}
      <Select
        placeholder={`${I18n.t('Select')}...`}
        isSearchable={allowSearch}
        options={options}
        styles={customStyles}
        components={{ DropdownIndicator, SingleValue }}
        menuPosition="fixed"
        maxMenuHeight={170}
        noOptionsMessage={() => I18n.t('No options')}
        {...selectProps}
      />
    </SelectWrapper>
  );
};

CompactSelect.propTypes = {
  options: array.isRequired,
  label: string,
  className: string,
  selectProps: object,
  allowSearch: bool,
};

CompactSelect.defaultProps = {
  label: '',
  className: '',
  selectProps: {},
  allowSearch: true,
};

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.primary};
  font-size: 12px;
  font-weight: 600;

  span {
    color: ${(props) => props.theme.colors.blue};
  }
`;

const StyledArrow = styled(ArrowDown)`
  width: 10px;
  height: 10px;
  path {
    fill: #295368;
  }
`;

const SelectValueHolder = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export default CompactSelect;
