const TEST_WITHOUT_NORMS = [
  '15FQ + BA1',
  '15FQ + HR1',
  '15FQ + HU1',
  '15FQ + J',
  '15FQ + ME1',
  '15FQ + SI1',
  '360D',
  'ARTI2',
  'CMQ',
  'CT1',
  'IPQ',
  'JTI',
  'LPT1Q',
  'LPT2Q',
  'LSJT1',
  'MEX',
  'MINIME20',
  'OPI',
  'OPIC',
  'OPIE',
  'OPIP',
  'OPIPC',
  'PVQPT',
  'RTQ',
  'SJM1',
  'WAI',
  'WAIBG1',
  'WAIMK1',
  'WAISI1',
];

export default TEST_WITHOUT_NORMS;
