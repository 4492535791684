import React from 'react';
import { useSelector } from 'react-redux';

const widgetId = 101000007488;

export const Freshdesk = () => {
  const [mounted, setMounted] = React.useState(false);
  const user = useSelector((state) => state.user.user);

  React.useEffect(() => {
    const handler = (e) => {
      if (e.data.eventName === 'widgetRenderComplete') {
        setMounted(true);
      }
    };

    const scriptFound = document.querySelector('#freshdesk-script');
    if (!scriptFound) {
      window.fwSettings = {
        widget_id: widgetId,
      };
      const script = document.createElement('script');
      script.id = 'freshdesk-script';
      script.src = `https://euc-widget.freshworks.com/widgets/${widgetId}.js`;
      document.body.appendChild(script);
    }
    window.addEventListener('message', handler);

    return () => {
      setMounted(false);
      window.removeEventListener('message', handler);
    };
  }, []);

  React.useEffect(() => {
    if (user && mounted) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${user.firstName} ${user.familyName}`,
        custom_fields: {
          cf_client: user.distributorUserName || '',
          cf_distributorid: user.distributorOwnerID || 0,
          cf_account: user.accountName || '',
          cf_accountid: user.accountID || 0,
          cf_clientuser: user.userName || '',
          cf_credits: user.credits || 0,
          cf_creditlimit: user.creditLimit || 0,
          cf_frozencredits: user.totalFrozenCredits || 0,
        },
      });
      window.FreshworksWidget('hide', 'ticketForm', ['name']);

      const iFrameElement = document.querySelector('iframe');
      if (iFrameElement) {
        const zIndexNewValue = '9';
        iFrameElement.style.zIndex = zIndexNewValue;
      }

      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_client']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_distributorid']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_account']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_accountid']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_clientuser']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_credits']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_creditlimit']);
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_frozencredits']);
    }
  }, [user, mounted]);

  return null;
};
