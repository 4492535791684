import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import Modal from '../../reusable/NewModal';
import ConfirmationModal from '../../reusable/ConfirmationModal';

import Steps from '../../reusable/Steps';
import CommonAssessmentsStep from './CommonAssessmentsStep';
import GroupReportsList from './GroupReportsList';
import Spinner from '../../reusable/Spinner';

import useStep from '../../../hooks/useStep';

import {
  modifyNorms,
  generateReportNorms,
  generateReportIdealProfile,
  parseSolutionNorms,
  getTestsWithoutNormSelected,
  selectedTestsNeedsIdealProfile,
} from '../../Respondents/RequestReportModal';
import { selectAvailableIdealProfiles } from '../../../store/respondents/selectors';
import {
  fetchGroupsCommonAssessments,
  fetchGroupsAssessmentsNorms,
  fetchAvailableGroupsReports,
  requestGroupsReports,
  clearGroupsCommonAssessments,
  clearGroupsAssessmentsNorms,
  clearAvailableGroupsReports,
} from '../../../store/groups/actions';
import {
  selectGroupsCommonAssessments,
  selectAvailableGroupsNorms,
  selectAvailableGroupsReports,
} from '../../../store/groups/selectors';
import { selectUserCredits } from '../../../store/user/selectors';
import CustomButton from '../../reusable/Buttons/Button';
import storageService from '../../../services/storageService';
import createToastNotification from '../../../utils/createToastNotification';

const GroupRequestReport = ({ onClose, group, successCallback }) => {
  let interfaceLanguage = storageService.getItem('psLang');
  if (interfaceLanguage === 'ch') interfaceLanguage = 'ch-prc';
  const steps = ['assessment', 'reports'];
  const { step, goNext, goPrev } = useStep({ steps });
  const dispatch = useDispatch();

  // Assessment step
  const commonsAssessmentsList = useSelector(selectGroupsCommonAssessments);
  const [commonAssessment, setCommonAssessment] = useState(null);

  // Reports step
  const languages = useSelector((state) => state.reports.availableLanguages);
  const reports = useSelector(selectAvailableGroupsReports);
  const norms = useSelector(selectAvailableGroupsNorms);
  const idealProfiles = useSelector(selectAvailableIdealProfiles);
  const credits = useSelector(selectUserCredits);
  const [normsState, changeNormsState] = useState([]);
  const [language, setLanguage] = useState(
    languages.find((item) => item.code.toLowerCase() === interfaceLanguage) ||
      languages.find((item) => item.code === 'EN'),
  );
  const [reportSets, setReportSets] = useState([]);
  const [isSubmitting, setSubmittingState] = useState(false);

  useEffect(() => {
    changeNormsState(modifyNorms(norms));
  }, [norms]);

  const onNormChange = (name, value) => {
    changeNormsState((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };

  const [idealProfilesState, changeIdealProfilesState] = useState([]);
  useEffect(() => {
    changeIdealProfilesState(modifyNorms(idealProfiles));
  }, [idealProfiles]);

  const onIdealProfileChange = (name, value) => {
    changeIdealProfilesState((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };

  const [isConfirmationModalVisible, setConfirmationModalState] = useState(false);
  const [isLoading, setLoadingStatus] = useState(false);

  useEffect(() => {
    setLanguage(
      languages.find((item) => item.code.toLowerCase() === interfaceLanguage) ||
        languages.find((item) => item.code === 'EN'),
    );
  }, [languages.length]);

  useEffect(() => {
    setLoadingStatus(true);
    dispatch(fetchGroupsCommonAssessments(group.groupID, () => setLoadingStatus(false)));
  }, []);

  useEffect(() => {
    if (commonAssessment && steps[step] === 'reports') dispatch(fetchGroupsAssessmentsNorms(commonAssessment.testIDs));
  }, [commonAssessment, step]);

  const onConfirmationClose = () => {
    setConfirmationModalState(false);
  };
  const openConfirmationModal = () => {
    setConfirmationModalState(true);
  };

  const getReportsList = () => {
    setLoadingStatus(true);
    const data = {
      tests: commonAssessment.testIDs,
      language,
      groupId: group.groupID,
    };
    dispatch(
      fetchAvailableGroupsReports(data, () => {
        setLoadingStatus(false);
      }),
    );
  };

  useEffect(() => {
    if (steps[step] === 'reports') getReportsList();
  }, [step, commonAssessment, language]);

  const onModalClose = () => {
    onClose();
    onConfirmationClose();
    dispatch(clearAvailableGroupsReports());
    dispatch(clearGroupsCommonAssessments());
    dispatch(clearGroupsAssessmentsNorms());
  };

  const checkedReportsSets = reportSets.filter((item) => item.isChecked);

  const onSubmit = () => {
    const individualReports = checkedReportsSets.reduce((acc, item) => {
      const items = item.reports
        .filter((item) => item.isChecked)
        .map((reportItem) => ({
          reportID: reportItem.reportID,
          reportSetID: item.reportSetID,
          normIDs: reportItem.isSolutionReport
            ? parseSolutionNorms(reportItem.solutionNorms)
            : generateReportNorms(reportItem, normsState),
          idealProfileID: generateReportIdealProfile(reportItem, idealProfilesState),
          reportType: 0,
        }));
      return [...acc, ...items];
    }, []);
    const data = {
      reportRequests: individualReports,
      testIDs: commonAssessment.testIDs,
    };

    const missingNormPerReport = getTestsWithoutNormSelected(data.reportRequests);
    const missingIdealProfilePerReport = selectedTestsNeedsIdealProfile(data.reportRequests);

    if (missingNormPerReport.length > 0 || missingIdealProfilePerReport.length > 0) {
      createToastNotification({ message: I18n.t('missingRequestedNorms'), type: 'error' });
      onConfirmationClose();
    } else {
      setSubmittingState(true);
      dispatch(
        requestGroupsReports(group.groupID, data, () => {
          setSubmittingState(false);
          onModalClose();
          if (successCallback) successCallback();
        }),
      );
    }
  };

  const title = `${I18n.t('Request report for')} ${group?.description}`;

  const confirmDescSets = checkedReportsSets
    .filter((item) => item.type === 'set')
    .map((item) => item.name)
    .join(', ')
    .toUpperCase();
  const confirmDescription = `${I18n.t('Request')} ${confirmDescSets} ${I18n.t('for')} ${
    commonAssessment?.respondentsCount
  } ${I18n.t('respondent(s)')}`;

  const onNextButtonClick = useCallback(() => {
    const currentStep = steps[step];
    if (currentStep === 'assessment') goNext();
    if (currentStep === 'reports') openConfirmationModal();
  }, [step, steps, commonAssessment]);

  const totalCost = reportSets.filter((item) => item.isChecked).reduce((acc, item) => acc + item.totalCreditCosts, 0);
  const isEnoughCredits = totalCost <= credits;

  const isNextButtonDisabled = useMemo(() => {
    if (steps[step] === 'assessment') return !commonAssessment;
    if (steps[step] === 'reports')
      return isLoading || reportSets.filter((report) => report.isChecked).length === 0 || !isEnoughCredits;
  }, [step, steps, commonAssessment, reportSets]);

  return (
    <StyledModal isVisible onClose={onModalClose} title={title}>
      <Steps steps={steps} stepIndex={step} />
      <Spinner isLoading={isLoading} />
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onClose={onConfirmationClose}
        description={confirmDescription}
        onConfirm={onSubmit}
        title={I18n.t('Request Reports')}
        isConfirmDisabled={isSubmitting}
      />
      <Container>
        {step === 0 ? (
          <>
            <CommonAssessmentsStep
              assessmentsList={commonsAssessmentsList}
              selectedAssessment={commonAssessment}
              onAssessmentChange={setCommonAssessment}
            />
            <Footer>
              <StyledButton variant="secondary" handler={onClose}>
                {I18n.t('Cancel')}
              </StyledButton>
              <StyledButton handler={onNextButtonClick} disabled={isNextButtonDisabled || isLoading}>
                {steps[step] === 'assessment' ? I18n.t('Next') : I18n.t('Request')}
              </StyledButton>
            </Footer>
          </>
        ) : (
          <GroupReportsList
            toPrevStep={() => goPrev()}
            multiStep={commonAssessment?.respondentsCount > 1}
            languages={languages}
            language={language}
            setLanguage={setLanguage}
            reports={reports}
            norms={normsState}
            idealProfiles={idealProfilesState}
            commonTests={commonAssessment?.testIDs}
            onNormChange={onNormChange}
            onIdealProfileChange={onIdealProfileChange}
            setParentReportSets={setReportSets}
            isEnoughCredits={isEnoughCredits}
            totalCost={totalCost}
            credits={credits}
            isLoading={isLoading}
            onSubmit={(reports) => openConfirmationModal(reports)}
            commonAssessment={commonAssessment}
          />
        )}
      </Container>
    </StyledModal>
  );
};

GroupRequestReport.propTypes = {
  onClose: func.isRequired,
  group: object.isRequired,
  successCallback: func,
};

GroupRequestReport.defaultProps = {
  successCallback: null,
};

const StyledModal = styled(Modal)`
  width: 74rem;
  height: 100%;
  max-height: 69rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  // position: relative;
  align-items: center;
  // justify-content: ${(props) => (props.isFirstStep ? 'flex-end' : 'space-between')};
  justify-content: space-between;
`;

// const Button = styled(CustomButton)`
//   text-transform: uppercase;
//   min-width: 12rem;
// `;

const StyledButton = styled(CustomButton)`
  min-width: 12rem;
  text-transform: uppercase;
`;

export default GroupRequestReport;
