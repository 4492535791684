import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default {
  init: () => {
    if (import.meta.env.MODE === 'production') {
      const APP_TYPE = window._env_.REACT_APP_DEPLOYMENT_TARGET?.match(
        /production-eu|production-au|production-cn|preproduction-eu|preproduction-au|preproduction-cn/g,
      )
        ? 'production'
        : 'non-production';
      // eslint-disable-next-line
      const SENTRY_DNS =
        APP_TYPE === 'production' ? import.meta.env.VITE_SENTRY_DSN : import.meta.env.VITE_SENTRY_DSN_NON_PROD;
      Sentry.init({
        dsn: SENTRY_DNS,
        environment: window._env_.REACT_APP_DEPLOYMENT_TARGET || 'unspecified',
        release: import.meta.env.VITE_RELEASE_VERSION || 'unspecified',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        autoSessionTracking: true,
      });
    }
  },
};
