import React from 'react';
import { LocationProvider } from '@reach/router';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { merge } from 'lodash';
import theme from './theme';
import screenSizes from './screenSizes';

import './App.css';
import 'rodal/lib/rodal.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';

import { reachHistory } from './store';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import ContentRouter from './components/ContentRouter';
import useCustomTheme from './hooks/useCustomTheme';

const AppInner = () => {
  const domainURL = window.location.hostname.split('.')[0];
  const { customTheme } = useCustomTheme({ partnerPrefix: domainURL });

  // useEffect(() => {
  //   setCustomTheme(newTheme);

  //   return () => {};
  // }, []);
  const newTheme = merge({ ...theme }, { ...customTheme });

  return (
    <>
      <StyledToastContainer autoClose={3000} position="bottom-right" hideProgressBar />
      <LocationProvider history={reachHistory}>
        <ThemeProvider theme={{ ...newTheme, screenSizes }}>
          <ErrorBoundary>
            <ContentRouter />
          </ErrorBoundary>
        </ThemeProvider>
      </LocationProvider>
    </>
  );
};

const StyledToastContainer = styled(ToastContainer)`
  background-color: transparent;
`;

export default AppInner;
