import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { func, any, number, string, oneOfType } from 'prop-types';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import { fetch360ProjectById } from '../../../store/projects/actions';
import { fetchSessionById } from '../../../store/sessions/actions';
import * as actions from '../../../table/actionsList';
import ratersTableActions from '../../../table/tableActions/raters';
import useModals from '../../../hooks/useModals';
import sessionsDropdownActions from '../../../table/entitiesDropdownActions/sessions';
import { setSelectedRaters } from '../../../store/raters/actions';
import AddRatersModal from './AddRatersModal';
import SessionInvite from '../../Sessions/SessionInvite';
import RequestReport360 from '../../RequestReport360/RequestReport360';
import getRatersByStatus from '../../../utils/getRatersByStatus';
import TableTabs from '../../reusable/TableTabs';

import breadcrumbs from '../raters.breadcrumbs';
import { SpecifyOwnRatersInvite } from '../../Sessions/SpecifyOwnRatersInvite';
import isChineseEnv from '../../../utils/isChineseEnv';

const generateTabs = (user) => [
  { name: 'raters', label: I18n.t('Raters') },
  { name: 'invites', label: I18n.t('Invites') },
  { name: 'results', label: I18n.t('Results') },
  ...(user?.userType === 3 ? [] : [{ name: 'reports', label: I18n.t('Reports') }]),
];

const Title = ({ activeSessionInView }) => {
  return activeSessionInView ? (
    <>
      <div>
        {I18n.t('Session for')}{' '}
        {isChineseEnv
          ? `${activeSessionInView.self.familyName || ''}${activeSessionInView.self.firstName}`
          : `${activeSessionInView.self.firstName} ${activeSessionInView.self.familyName}`}
      </div>
      <Email>{activeSessionInView.self.email}</Email>
    </>
  ) : (
    I18n.t('Raters')
  );
};

const RatersTableWrapper = ({ children, projectId, sessionId, activeTab = 0, setShouldResetPageStatus }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  // Props from State
  const activeSessionInView = useSelector((state) => state.sessions.activeSessionInView);
  const selectedRaters = useSelector((state) => state.raters.selectedRaters);

  const tabs = generateTabs(user);

  // Get current session and current project for page details
  useEffect(() => {
    if (projectId) dispatch(fetch360ProjectById(projectId));
    if (sessionId) dispatch(fetchSessionById(projectId, sessionId));
  }, [projectId, sessionId]);

  // Modals
  const modals = [actions.ADD_RATERS, actions.SESSION_REQUEST_REPORT, actions.SESSION_INVITE_SPECIFY_RATERS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // Main back button handler
  const onBackButtonClick = () => dispatch(push(`/projects/360/${projectId}`));

  // Table Actions
  const tableHandlers = {
    [actions.ADD_RATERS]: () => openModal(actions.ADD_RATERS),
  };

  const tableActions = useMemo(() => {
    return ratersTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // Dropdown Actions
  const [commandType, setCommandType] = useState(0);

  const sessionRaters = activeSessionInView ? [...activeSessionInView.raters, activeSessionInView.self] : [];
  const sessionSelf = activeSessionInView ? activeSessionInView.self : {};

  const dropdownActions = useMemo(() => {
    return sessionsDropdownActions(sessionRaters, sessionSelf).map((item) => ({
      ...item,
      handler: () => {
        if (item.name === actions.SEND_INVITE_TO_RATERS) {
          dispatch(setSelectedRaters([]));
          setCommandType(item.commandType);
        }
        openModal(item.name);
      },
    }));
  }, [sessionRaters, sessionSelf]);

  // Tabs click handler
  const onTabClick = (tab) => {
    if (setShouldResetPageStatus) setShouldResetPageStatus(true);
    dispatch(push(`/projects/360/${projectId}/session/${sessionId}/session-${tab.name}`));
  };

  // session raters filtered by selected command type
  const filteredSessionRaters = useMemo(() => {
    if (!activeSessionInView) return [];
    const { notCompleted, notInvited, invitedNotCompleted } = getRatersByStatus(sessionRaters);
    const byTypes = { 0: notCompleted, 1: notInvited, 2: invitedNotCompleted };
    return byTypes[commandType];
  }, [activeSessionInView, commandType, sessionRaters]);

  return (
    <PageWrapper
      title={<Title activeSessionInView={activeSessionInView} />}
      backButtonHandler={onBackButtonClick}
      buttons={tableActions}
      dropdownActions={dropdownActions}
      breadcrumbs={breadcrumbs(activeProjectInView)}
    >
      <TableTabs tabs={tabs} activeTab={tabs[activeTab]} clickHandler={onTabClick} />
      <MainContent>{children}</MainContent>
      {modalsState[actions.ADD_RATERS] && (
        <AddRatersModal
          onClose={() => closeModal(actions.ADD_RATERS)}
          onSuccess={setShouldResetPageStatus ? () => setShouldResetPageStatus(true) : () => {}}
        />
      )}
      {modalsState[actions.SESSION_REQUEST_REPORT] && (
        <RequestReport360
          onClose={() => closeModal(actions.SESSION_REQUEST_REPORT)}
          eligibleSessions={[activeSessionInView]}
          projectId={projectId}
        />
      )}
      {modalsState[actions.SEND_INVITE_TO_RATERS] && (
        <SessionInvite
          onClose={() => closeModal(actions.SEND_INVITE_TO_RATERS)}
          itemType={selectedRaters.length ? 'rater' : 'session'}
          items={selectedRaters.length ? selectedRaters : filteredSessionRaters}
          commandType={commandType}
          successCallback={setShouldResetPageStatus ? () => setShouldResetPageStatus(true) : () => {}}
          sessionId={selectedRaters.length ? '' : activeSessionInView && activeSessionInView.sessionId}
        />
      )}
      {modalsState[actions.SESSION_INVITE_SPECIFY_RATERS] && (
        <SpecifyOwnRatersInvite
          onClose={() => closeModal(actions.SESSION_INVITE_SPECIFY_RATERS)}
          sessionId={sessionId}
        />
      )}
    </PageWrapper>
  );
};

RatersTableWrapper.propTypes = {
  setShouldResetPageStatus: func,
  children: any.isRequired,
  projectId: oneOfType([string, number]).isRequired,
  sessionId: oneOfType([string, number]).isRequired,
  activeTab: number.isRequired,
};

RatersTableWrapper.defaultProps = {
  setShouldResetPageStatus: undefined,
};

const Email = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #008ac0;
`;

const MainContent = styled.div`
  height: calc(100% - 40px);
`;

export default RatersTableWrapper;
