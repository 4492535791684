import React, { useState } from 'react';
import { func, bool, object } from 'prop-types';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import NewModal from '../reusable/NewModal';
import CustomInput from '../reusable/FormComponents/Input';
import CustomButton from '../reusable/Buttons/Button';
import Spinner from '../reusable/Spinner';

import createToastNotification from '../../utils/createToastNotification';
import { calculateCredits } from '../../store/user/selectors';
import isChineseEnv from '../../utils/isChineseEnv';

const createErrorMessagesMap = (minLimit, maxLimit, creditLimit) => ({
  1: 'Credits update error. Please contact your administrator.',
  2: `Credits update error. Minimum credit update is ${minLimit} credits.`,
  3: `Credits update error. Max credit update is ${maxLimit} credits.`,
  4: `Credits update error. You reached your credit limit of ${creditLimit} credits`,
});

const AddCredits = ({ isVisible, onModalClose, user, addCredits }) => {
  const firstName = user?.firstName ?? '';
  const familyName = user?.familyName ?? '';
  const credits = calculateCredits(user?.credits, user?.totalFrozenCredits);
  const minCreditUpdateLimit = user?.minCreditUpdate ?? 0;
  const maxCreditUpdateLimit = user?.maxCreditUpdate ?? 0;
  const creditLimit = user?.creditLimit ?? 0;
  const fullName = isChineseEnv ? `${familyName || ''}${firstName}` : `${firstName} ${familyName}`;
  const [isLoading, setLoadingStatus] = useState(false);
  const [creditsInputValue, setCreditsInputValue] = useState('');
  const [purchaseIdInputValue, setPurchaseIdInputValue] = useState('');

  const errorMessages = createErrorMessagesMap(minCreditUpdateLimit, maxCreditUpdateLimit, creditLimit);

  const onCreditsChange = (e) => {
    const matched = e.target.value.match(/(\D)/g);
    if (matched && matched[0] !== '+') return;
    const newVal = e.target.value.match(/\d+/g);
    setCreditsInputValue(newVal ? `+${newVal[0]}` : '');
  };

  const closeModal = () => {
    setCreditsInputValue('');
    setPurchaseIdInputValue('');
    onModalClose();
  };

  const onSubmit = () => {
    setLoadingStatus(true);
    const data = {
      requestAmount: Number(creditsInputValue.slice(1)),
      purchaseId: purchaseIdInputValue || null,
    };
    addCredits(data, (data, err) => {
      setLoadingStatus(false);
      if (err || data.status !== 0) {
        const message = errorMessages[data.status] || err;
        createToastNotification({ message, type: 'error' });
      } else {
        closeModal();
        createToastNotification({ message: I18n.t('creditBalanceUpdateToast') });
      }
    });
  };

  return (
    <>
      <StyledModal isVisible={isVisible} onClose={onModalClose} title={I18n.t('Add credits')}>
        <div>
          <Header>
            <Avatar>{`${firstName[0]}${familyName[0]}`}</Avatar>
            <UserInfoContainer>
              <UserName>{fullName}</UserName>
              <Label>{I18n.t('Your credits balance')}:</Label>
              <CurrentCredits>{credits}</CurrentCredits>
            </UserInfoContainer>
          </Header>
          <CreditsInputContainer>
            <InputWrapper>
              <CustomInput inputName={I18n.t('Credits amount')} value={creditsInputValue} onChange={onCreditsChange} />
            </InputWrapper>
          </CreditsInputContainer>
          <CustomInput
            inputName={I18n.t('Purchase ID')}
            value={purchaseIdInputValue}
            onChange={(e) => setPurchaseIdInputValue(e.target.value)}
          />
          <ButtonsWrapper>
            <StyledButton variant="secondary" handler={onModalClose}>
              {I18n.t('Cancel')}
            </StyledButton>
            <StyledButton variant="primary" disabled={!creditsInputValue} handler={onSubmit}>
              {I18n.t('Add credits')}
            </StyledButton>
          </ButtonsWrapper>
        </div>
        <Spinner isLoading={isLoading} />
      </StyledModal>
    </>
  );
};

AddCredits.propTypes = {
  isVisible: bool.isRequired,
  onModalClose: func.isRequired,
  user: object,
  addCredits: func.isRequired,
};

AddCredits.defaultProps = {
  user: null,
};

const StyledModal = styled(NewModal)`
  min-width: 45rem;
  height: 100%;
  max-height: 63rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  color: ${(props) => props.theme.colors.darkBlue2};
  line-height: 15px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
`;

const UserName = styled.span`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 20px;
  font-weight: bold;
`;

const CurrentCredits = styled.span`
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
`;

const Avatar = styled.div`
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 32px;
  font-weight: bold;
  background-color: ${(props) => props.theme.colors.lightBlue2};
  margin-right: 20px;
  color: ${(props) => props.theme.colors.white};
`;

const CreditsInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const InputWrapper = styled.div`
  position: relative;

  :hover {
    .a {
      fill: ${(props) => props.theme.colors.blue};
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 3.6rem;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledButton = styled(CustomButton)`
  width: 15rem;
  text-transform: uppercase;
`;

export default AddCredits;
