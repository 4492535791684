import React from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import styled, { keyframes, css } from 'styled-components';
import { capitalize } from 'lodash';
import { I18n } from 'react-redux-i18n';
import isChineseEnv from '../../utils/isChineseEnv';

import { caseInsensitiveSortingFunc } from './respondents';

import { ReactComponent as CompletedIcon } from '../../assets/icons/check.svg';
import { ReactComponent as UnreadIcon } from '../../assets/icons/unread.svg';
import { ReactComponent as ProgressIcon } from '../../assets/icons/progress.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as ExcelIcon } from '../../assets/icons/report-excel.svg';
import { ReactComponent as ZipIcon } from '../../assets/icons/report-zip.svg';
import { ReactComponent as PdfIcon } from '../../assets/icons/report-pdf.svg';
import { ReactComponent as DocIcon } from '../../assets/icons/report-doc.svg';
import { findReportFormat } from '../bulkActions/reports';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';

const iconsMap = {
  viewed: CompletedIcon,
  unread: UnreadIcon,
  pending: ProgressIcon,
  error: WarningIcon,
};

const styleFormatIcon = (Icon) => styled(Icon)`
  margin-right: 1rem;
`;

export const formatIconsMap = {
  zip: styleFormatIcon(ZipIcon),
  pdf: styleFormatIcon(PdfIcon),
  xlsx: styleFormatIcon(ExcelIcon),
  doc: styleFormatIcon(DocIcon),
};

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
   100% {
     transform: rotate(360deg);
   }
`;

const animation = () =>
  css`
    ${rotate} 2s infinite linear;
  `;

const styleIcon = (Icon, status) => styled(Icon)`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  animation: ${() => (status === 'pending' ? animation : 'none')};

  .a {
    ${() =>
      status === 'error' &&
      css`
        fill: ${(props) => props.theme.colors.red};
        stroke: ${(props) => props.theme.colors.red};
        stroke-width: 0.3px;
      `}
  }
`;

const StatusCell = ({ status, error }) => {
  const finalizedStatus = error ? 'error' : status;
  const Icon = status !== 'deleted' && styleIcon(iconsMap[finalizedStatus], finalizedStatus);
  return (
    <StatusContainer>
      {status !== 'deleted' && <Icon />}
      <StatusLabel error={error}>
        {finalizedStatus === 'error' ? I18n.t('Support ticket raised') : I18n.t(capitalize(finalizedStatus))}
      </StatusLabel>
    </StatusContainer>
  );
};

const NameCell = ({ data }) => {
  const mainName = isChineseEnv
    ? `${data.familyName || ''}${data.firstName}`
    : `${data.firstName} ${data.familyName || ''}`;
  const respondentsLength = data.respondentIDs.split(',').length - 1;
  const Icon = formatIconsMap[findReportFormat(data)];
  return (
    <Row>
      {data.downloadLink && Icon && <Icon />}
      <span>{mainName} </span>
      {respondentsLength > 0 && (
        <span style={{ fontStyle: 'italic', fontSize: '8px' }}>
          {I18n.t('and')} {respondentsLength} {I18n.t('more')}
        </span>
      )}
    </Row>
  );
};

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DateCell = ({ row }) => (
  <span>{format(utcToZonedTime(new Date(row.original.creationDate), currentTimezone), 'dd/M/y HH:mm')}</span>
);

// const columns = (rowActions, setSelectedReports) => [

const customAccessor = (data) => {
  const fullName = isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`;
  return fullName;
};

const columns = [
  {
    Header: I18n.t('PersonName'),
    accessor: customAccessor,
    name: 'name',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    Cell: ({ cell }) => <NameCell data={cell.row.original} />,
    filteringName: 'Name',
  },
  {
    Header: I18n.t('Report'),
    accessor: 'questionnaire',
    name: 'assessment',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Questionnaire',
  },
  {
    Header: I18n.t('Status'),
    accessor: 'status',
    name: 'status',
    Cell: ({ cell }) => {
      return <StatusCell status={cell.value} error={cell.row.original.error} />;
    },
    type: 'select',
  },
  {
    Header: I18n.t('Creation Date'),
    width: 35,
    name: 'creationDate',
    accessor: (data) => new Date(data.creationDate),
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
    type: 'date',
    filteringName: 'Date',
  },
  // {
  //   Header: I18n.t(''),
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler && item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedReports(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.error ? 'red' : props.theme.colors.primary)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default columns;
