const translations = {
  Launch: 'Launch (pt-br)',
  About: 'About (pt-br)',
  Tutorial: 'Tutorial (pt-br)',

  // vars
  LoginSubmit: 'Login',
  'Reset assessment deadline': 'Redefinir prazo',
  Update: 'atualizar',
  updateAssessmentInvitationToast: 'Convites atualizados(s)',
  updateProjectInvitationToast: 'Convites atualizados(s)',
  biodataTooltip:
    'Os modelos de biodados permitem a coleta personalizada de dados biográficos dos entrevistados. Crie modelos de biodados na área Configurações',
  'Default Biodata Template has been updated': 'O modelo padrão de biodados foi atualizado',
  DEFAULT: 'Predefinido',
  'Bio data template': 'Modelo de dados biográficos',
  biodataDefaultTemplateLabel: '** Sem modelo de dados biográficos (todas as categorias incluídas) **',
  defaultCompleteBioTemplate: '** Todos os modelos de biodata (Todas as categorias incluídas) **',
  defaultEmptyBioTemplate: '** Modelo de biodata vazio (Nenhuma categoria incluída) **',
  invitesSentToast: 'Convite(s) foi enviado',
  selectNormForReport: 'Por favor, selecione uma norma',
  selectIdealProfileForReport: 'Por favor, selecione o perfil ideal',
  visibleNormForReport: 'Usar norma para relatórios',
  hideNormForReport: 'Ocultar norma para relatórios',
  showNormTitle: 'Tornar visível para a lista de solicitações de relatórios',
  showConfirmationMessageTitle: 'Tem certeza de que deseja tornar visível',
  hideNormTitle: 'Ocultar para a lista de solicitações de relatórios',
  hideConfirmationMessageTitle: 'Tem certeza de que deseja remover',
  showhideConfirmationMessageDescription1: 'normas selecionadas para a geração de relatórios?',
  showhideConfirmationMessageDescription2: 'esta norma para a geração de relatórios?',
  visible: 'Visível',
  normsVisibilityToast: 'A visibilidade da norma foi atualizada',
  updateGroupToast: 'O grupo foi atualizado',
  updateGroupErrorToast: 'Erro durante a atualização do grupo',
  'Edit Group Details': 'Editar detalhes do grupo',
  'Duplicate Group': 'Duplicar grupo',
  emptyLanguageReports: 'Desculpe, não conseguimos encontrar relatórios disponíveis para este idioma',
  Notes: 'Notas',
  'Forgot password?': 'Esqueceu a senha?',
  'You should receive your reset link soon at': 'Você deve receber seu link de redefinição em breve em',
  "Can't find the email?": 'Não consegue encontrar o email?',
  'You should also check your spam folder': 'Você também deve verificar sua pasta de spam',
  'Email address': 'Endereço de email',
  'Request a reset link': 'Solicitar um link de redefinição',
  'Back to login page': 'Voltar para a página de login',
  downloadInvitesReport: 'Baixar csv',
  csvReportName: 'Relatório de Convites %{date}.csv',
  'Copy and share generated links for': 'Copiar e compartilhar links gerados para',
  'that have email address missing': 'que estão sem endereço de email',
  'You have chosen a group of': 'Você escolheu um grupo de',
  'have email address missing': 'estão sem endereço de email',
  'This 360 survey is used by one or more project, therefore can not be edited or deleted':
    'Esta pesquisa 360 é usada por um ou mais projetos, portanto não pode ser editada ou excluída',
  and: 'e',
  more: 'mais',
  missingRequestedNorms: 'Faltam normas e/ou perfis ideais para os relatórios selecionados',
  'You will be redirected shortly': 'Você será redirecionado em breve',
  oneremainingnorm: 'Pelo menos uma norma deve permanecer vis%C3%ADvel para este teste',
};

export default translations;
