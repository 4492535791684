/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { func, oneOfType, string, number } from 'prop-types';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { I18n, Translate } from 'react-redux-i18n';
import Papa from 'papaparse';
import { useSelector } from 'react-redux';

import { ReactComponent as FirstStepIcon } from '../../../assets/icons/import-respondents-step-1.svg';
import { ReactComponent as SecondStepIcon } from '../../../assets/icons/import-respondents-step-2.svg';
import { ReactComponent as ThirdStepIcon } from '../../../assets/icons/import-respondents-step-3.svg';
import { ReactComponent as SuccessfulImportIcon } from '../../../assets/icons/import-file-success.svg';

import createToastNotification from '../../../utils/createToastNotification';

import CustomButton from '../../reusable/Buttons/Button';

import { selectAvailableLanguages } from '../../../store/user/selectors';
import LanguagesSelect from '../../reusable/Selects/LanguagesSelect';
import competenciesSelectors from '../../../store/settings/competencies/selectors';

const instructionSteps = [
  {
    Icon: FirstStepIcon,
    Description: () => (
      <StyledDescription>
        {I18n.t('Prepare a csv file yourself or')}
        <PseudoLink href="/samples/sample_import_competencies.zip">
          {I18n.t('download the sample import file')}
        </PseudoLink>
      </StyledDescription>
    ),
    key: 1,
  },
  {
    Icon: SecondStepIcon,
    Description: () => (
      <StyledDescription>
        <Translate value="Make sure the data order is set out correctly" />
      </StyledDescription>
    ),
    key: 2,
  },
  {
    Icon: ThirdStepIcon,
    Description: () => (
      <StyledDescription>
        {' '}
        <Translate value="Upload the file" />
      </StyledDescription>
    ),
    key: 3,
  },
];

const asyncParse = async (file) => {
  let payload = file;
  try {
    payload = await getFileContentsWitchEncodingCheck(file);
  } catch (err) {
    console.error(err);
  }

  return new Promise((res, rej) => {
    try {
      Papa.parse(payload, {
        complete: (results) => {
          res(results.data);
        },
      });
    } catch (err) {
      rej(err);
    }
  });
};

async function getFileContentsWitchEncodingCheck(file) {
  return new Promise((res) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const arrayBuffer = event.target.result;

      let text = tryDecode(arrayBuffer, 'utf-8');

      if (text.includes('�')) {
        text = tryDecode(arrayBuffer, 'windows-1252');
      }
      res(text);
    };

    reader.readAsArrayBuffer(file);
  });
}

function tryDecode(arrayBuffer, encoding) {
  try {
    const decoder = new TextDecoder(encoding, { fatal: false });
    return decoder.decode(arrayBuffer);
  } catch (error) {
    console.error(`Error decoding with ${encoding}:`, error);
    return null;
  }
}

const csvMimeTypes =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values';

const ImportSessionsPrepareStep = ({ goNext, onClose, setImportedValues }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  const languages = useSelector(selectAvailableLanguages);
  const usedLanguagesCodes = files.reduce((acc, item) => (item.lang?.code ? [...acc, item.lang?.code] : acc), []);
  const filteredLanguages = languages.filter((lang) => !usedLanguagesCodes.includes(lang.code));

  const list = useSelector(competenciesSelectors.selectData);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    accept: csvMimeTypes,
    onDropAccepted: (files) =>
      setFiles(() => {
        setError('');
        const out = [];

        files.forEach((file) => {
          const fileNameLanugage =
            file.name.match(/_([A-Za-z]{2})\.csv/)?.[1] &&
            filteredLanguages.find((entry) => entry.code === file.name.match(/_([A-Za-z]{2})\.csv/)[1].toUpperCase());
          if (fileNameLanugage) {
            out.push({
              file,
              lang: fileNameLanugage,
              name: file.name,
            });
          } else {
            out.push({
              file,
              name: file.name,
            });
          }
        });

        return out;
      }),
  });

  const allowNextStep = !!files.length && files.reduce((acc, entry) => (entry.lang ? acc : false), true);

  function parseCsv(parsedCsv) {
    const mapper = {};
    const finalJson = { competencyCategories: [] };
    let dataItems = [];

    let parsedCsvCopy = JSON.parse(JSON.stringify(parsedCsv));

    parsedCsvCopy = parsedCsvCopy.map((language) => ({
      ...language,
      data:
        language.data[language.data.length - 1].length === 0 || language.data[language.data.length - 1].length === 1
          ? language.data.slice(0, -1)
          : language.data,
    }));

    parsedCsvCopy = parsedCsvCopy.map((language) => ({
      ...language,
      data: language.data.map((row) => {
        if (row.length > 6) {
          return row.slice(6).findIndex((entry) => entry === '' || entry.match(/^\s*$/g)) !== -1
            ? row.slice(0, 6)
            : row;
        }
        return row;
      }),
    }));

    parsedCsvCopy.forEach((language) => {
      language.data.forEach((row, idx) => {
        const fiveColumns = row.length === 6;
        if (!fiveColumns) {
          throw new Error(
            `${parsedCsvCopy.length > 1 ? `In "${language.name}" row` : 'Row'} ${idx + 1} is ${
              row.length < 6 ? 'shorter' : 'longer'
            } than six columns.`,
            {
              cause: true,
            },
          );
        }
      });
    });

    const equalRows = parsedCsvCopy.reduce((acc, entry, idx, arr) => {
      if (!acc) return false;
      if (idx === 0) return acc;
      return entry.data.length === arr[idx - 1].data.length;
    }, true);

    if (!equalRows) {
      throw new Error('One of the CSV files has more or fewer rows than the others.', { cause: true });
    }

    const headers = [];
    parsedCsvCopy.forEach((language) => headers.push(language.data.splice(0, 1)[0]));

    // eslint-disable-next-line no-unused-vars
    parsedCsvCopy.forEach((language, _idx, arr) => {
      const matrix = language.data;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; matrix.length > i; i++) {
        if (!/^\s*$/.test(matrix[i][0])) {
          if (!/^\s*$/.test(matrix[i][2]) || !/^\s*$/.test(matrix[i][3])) {
            throw new Error(
              `${
                arr.length > 1 ? `"${language.name}" ` : ''
              } CSV file does not conform to the structure outlined in the provided example files.`,
              { cause: true },
            );
          }
        }
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; matrix.length > i; i++) {
        if (!/^\s*$/.test(matrix[i][2])) {
          if (!/^\s*$/.test(matrix[i][4])) {
            throw new Error(
              `${
                arr.length > 1 ? `"${language.name}" ` : ''
              } CSV file does not conform to the structure outlined in the provided example files.`,
              { cause: true },
            );
          }
        }
      }
    });

    parsedCsvCopy.forEach((language) => {
      mapCategroyAndName(language.data);
      const items = language.data.map((entry) => entry[4]);

      dataItems.push([
        language.lang.code,
        items.reduce((acc, item, idx) => {
          if (!/^\s*$/.test(item)) {
            const category = findCategory(idx);
            const name = findName(idx);
            const isFreeField = item.match(/free_field:( ?)|Free_field:( ?)/);
            const itemm = isFreeField ? item.replace(isFreeField[0], '') : item;
            const type = isFreeField ? 1 : 0;
            const instruction = language.data[idx][5];

            return [
              ...acc,
              {
                item: itemm,
                cat: category[0],
                catDesc: category[1],
                name: name[0],
                nameDesc: name[1],
                type,
                ...(instruction ? { instruction } : {}),
              },
            ];
          }
          return acc;
        }, []),
      ]);
    });

    dataItems = dataItems.reduce((acc, item, idx) => {
      if (idx === 0) {
        return item[1].map((question) => [[item[0], question]]);
      }

      item[1].forEach((question, idxx) => {
        acc[idxx].push([item[0], question]);
      });

      return acc;
    }, []);

    parsedCsvCopy = parsedCsvCopy.map((language, idx) => {
      return { ...language, data: [headers[idx], ...language.data] };
    });

    const outJson = buildFinalJson(dataItems);

    checkForDuplicates(outJson.competencyCategories);

    return [outJson, parsedCsvCopy];

    function checkForDuplicates(outJson) {
      outJson.forEach((category) => {
        category.customLanguages.forEach((categoryLanguage) => {
          let foundName = '';
          const found = list.find((currentCategory) => {
            if (currentCategory.languages) {
              return currentCategory.languages.find((currentCategoryLanguage) => {
                if (
                  currentCategoryLanguage.langID === categoryLanguage.langID &&
                  currentCategoryLanguage.name === categoryLanguage.name
                ) {
                  foundName = categoryLanguage.name;
                }
                return (
                  currentCategoryLanguage.langID === categoryLanguage.langID &&
                  currentCategoryLanguage.name === categoryLanguage.name
                );
              });
            }

            if (currentCategory.name === categoryLanguage.name) {
              foundName = categoryLanguage.name;
            }
            return currentCategory.name === categoryLanguage.name;
          });
          if (found) {
            throw new Error(`Category with name "${foundName}" already exist.`, { cause: true });
          }
        });

        category.competencies.forEach((competency) => {
          competency.customLanguages.forEach((competencyLanguage) => {
            let foundName = '';
            const found = list.find((currentCategory) => {
              if (currentCategory.categories) {
                return currentCategory.categories.find((currentCompetency) => {
                  if (currentCompetency.languages) {
                    return currentCompetency.languages.find((currentCompetencyLanguage) => {
                      if (
                        currentCompetencyLanguage.langID === competencyLanguage.langID &&
                        currentCompetencyLanguage.name === competencyLanguage.name
                      ) {
                        foundName = competencyLanguage.name;
                      }
                      return (
                        currentCompetencyLanguage.langID === competencyLanguage.langID &&
                        currentCompetencyLanguage.name === competencyLanguage.name
                      );
                    });
                  }

                  if (currentCompetency.name === competencyLanguage.name) {
                    foundName = competencyLanguage.name;
                  }
                  return currentCompetency.name === competencyLanguage.name;
                });
              }
              return false;
            });
            if (found) {
              throw new Error(`Competency with name "${foundName}" already exist.`, { cause: true });
            }
          });
        });
      });
    }

    function buildFinalJson(items) {
      items.forEach((item) => {
        const mainItem = item[0][1];
        const mainLanguage = item[0][0];

        item.forEach((lanugageItem) => {
          const item = lanugageItem[1];
          const language = lanugageItem[0];

          let category = finalJson.competencyCategories.find((category) => {
            return category.customLanguages.find((lanuageCategory) => lanuageCategory.name === mainItem.cat);
          });

          if (!category) {
            const createdCategory = {
              name: mainItem.cat,
              description: mainItem.catDesc,
              customLanguages: [{ langID: mainLanguage, name: mainItem.cat, description: mainItem.catDesc }],
            };
            finalJson.competencyCategories = [...finalJson.competencyCategories, createdCategory];
            category = createdCategory;
          }

          const languageCategory = category.customLanguages.find(
            (lanuageCategory) => lanuageCategory.langID === language && lanuageCategory.name === item.cat,
          );

          if (!languageCategory) {
            category.customLanguages.push({ langID: language, name: item.cat, description: item.catDesc });
          }

          let competency = category?.competencies?.find((competency) => {
            return competency.customLanguages.find((lanuageCompetency) => lanuageCompetency.name === mainItem.name);
          });

          if (!competency) {
            const createdCompetency = {
              name: mainItem.name,
              description: mainItem.nameDesc,
              customLanguages: [{ langID: mainLanguage, name: mainItem.name, description: mainItem.nameDesc }],
            };
            category.competencies = [...(category.competencies || []), createdCompetency];
            competency = createdCompetency;
          }

          const languageCompetency = competency.customLanguages.find(
            (languageCompetency) => languageCompetency.langID === language && languageCompetency.name === item.name,
          );

          if (!languageCompetency) {
            competency.customLanguages.push({ langID: language, name: item.name, description: item.nameDesc });
          }

          let question = competency?.questions?.find((question) => {
            return question.customLanguages.find((lanuageQuestion) => lanuageQuestion.item === mainItem.item);
          });

          if (!question) {
            const createdQuestion = {
              type: mainItem.type,
              customLanguages: [
                {
                  langID: mainLanguage,
                  item: mainItem.item,
                  ...(mainItem.instruction ? { instruction: mainItem.instruction } : {}),
                },
              ],
            };
            competency.questions = [...(competency.questions || []), createdQuestion];
            question = createdQuestion;
          }

          if (mainItem !== item) {
            question.customLanguages.push({
              langID: language,
              item: item.item,
              ...(item.instruction ? { instruction: item.instruction } : {}),
            });
          }
        });
      });
      return finalJson;
    }

    function findCategory(idx) {
      return Object.entries(mapper.category).find((entry) => {
        return idx <= Number(entry[0]);
      })[1];
    }

    function findName(idx) {
      return Object.entries(mapper.name).find((entry) => {
        return idx <= Number(entry[0]);
      })[1];
    }

    function mapCategroyAndName(data) {
      let column = data.map((entry) => entry[0]).reverse();
      let descriptionColumn = data.map((entry) => entry[1]).reverse();
      mapper.category = mappingLoop(column, descriptionColumn);

      column = data.map((entry) => entry[2]).reverse();
      descriptionColumn = data.map((entry) => entry[3]).reverse();
      mapper.name = mappingLoop(column, descriptionColumn);
    }

    function mappingLoop(column, descriptionColumn) {
      let counter = 0;

      const out = column.reduce((acc, entry, idx, arr) => {
        // eslint-disable-next-line no-plusplus
        counter++;
        if (!/^\s*$/.test(entry)) {
          const index = arr.length - idx + counter - 2;
          counter = 0;
          return {
            ...acc,
            [index]: [entry, descriptionColumn[idx]],
          };
        }

        return acc;
      }, {});

      return out;
    }
  }

  const importFile = async () => {
    try {
      const parsedValues = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const out = {
          lang: files[i].lang,
          data: await asyncParse(files[i].file),
          name: files[i].name,
        };

        parsedValues.push(out);
      }

      try {
        const [parsedJson, parsedMatrix] = parseCsv(parsedValues);

        setImportedValues({
          matrix: parsedMatrix,
          json: parsedJson,
        });

        goNext();
      } catch (error) {
        if (error.cause) {
          setError(error.message);
        } else {
          console.log(error);
          setError('Unknown error occurred due to improper formatting in CSV file(s).');
        }
      }
    } catch (e) {
      console.log(e);
      createToastNotification({
        title: 'Error',
        message: I18n.t('importSessionsErrorToast'),
        type: 'error',
      });
      onClose();
    }
  };

  return (
    <>
      <ContentWrapper>
        <InstructionsWrapper>
          <Row>
            {instructionSteps.map((step) => (
              <IconWrapper key={step.key}>
                <step.Icon />
              </IconWrapper>
            ))}
          </Row>
          <ProgressBar>
            {instructionSteps.map((item, i) => {
              const leftOffset = 50 * i;
              return <Dot key={item.key} left={leftOffset} />;
            })}
          </ProgressBar>
          <Row>
            {instructionSteps.map((step) => (
              <step.Description key={step.key} />
            ))}
          </Row>
        </InstructionsWrapper>
        {error && (
          <div style={{ color: 'red', textAlign: 'center' }}>
            <div>{error}</div>
            <div>Please correct the error(s) in the CSV file(s) and reupload again.</div>
          </div>
        )}
        <StyledDropZone {...getRootProps()} isActive={isDragActive} isFileSelected={Boolean(files.length)}>
          {files.length ? (
            <StyledResults>
              {files.map((entry, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledResultsItem key={idx}>
                  <SuccessfulImportIcon />
                  <Label>{entry.file.name}</Label>
                  <LanguagesSelect
                    value={entry.lang}
                    name={`languages.${idx}.lang`}
                    languages={filteredLanguages}
                    onChange={(lang) => {
                      setFiles((prev) => prev.map((entry, idxx) => (idxx === idx ? { ...entry, lang } : entry)));
                    }}
                  />
                  <PseudoLink onClick={() => setFiles((prev) => prev.filter((_, idxx) => idxx !== idx))}>
                    {I18n.t('Delete')}
                  </PseudoLink>
                </StyledResultsItem>
              ))}
            </StyledResults>
          ) : (
            <>
              <input {...getInputProps()} accept={csvMimeTypes} />
              <MiniTitle>
                <Translate value="Drag and drop file(s) here or browse the file(s)" />
              </MiniTitle>
              <Label>
                <Translate value="Upload CSV file(s) to start import" />
              </Label>
              <ChooseButton onClick={open}>
                {' '}
                <Translate value="Choose files" />
              </ChooseButton>
            </>
          )}
        </StyledDropZone>
      </ContentWrapper>
      <ButtonsWrapper>
        <StyledButton handler={importFile} disabled={!allowNextStep}>
          <Translate value="Next" />
        </StyledButton>
      </ButtonsWrapper>
    </>
  );
};

ImportSessionsPrepareStep.propTypes = {
  goNext: func.isRequired,
  onClose: func.isRequired,
  setImportedValues: func.isRequired,
  projectId: oneOfType([string, number]),
};

ImportSessionsPrepareStep.defaultProps = {
  projectId: '',
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1.5rem;
`;

const StyledDescription = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  width: 18rem;
  text-align: center;
`;

const PseudoLink = styled.a`
  color: ${(props) => props.theme.colors.lightBlue};
  text-decoration: underline;
  font-weight: bold;

  :hover {
    cursor: pointer;
  }
`;

const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 18rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const ProgressBar = styled.div`
  height: 4px;
  width: 76%;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  margin: 0 auto 1rem;
  position: relative;
`;

const Dot = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  left: ${(props) => `${props.left}%`};
  top: 50%;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  transform: translateY(-50%);
`;

const StyledDropZone = styled.div`
  margin: 1rem 0;
  padding: 1rem 0;
  border: 2px dashed ${(props) => (props.isFileSelected ? '#27AE60' : '#3DB7E6')};
  background-color: ${(props) => {
    if (props.isFileSelected) return '#E7FFF1';
    return props.isActive ? props.theme.colors.lightBlue1 : '#f3fcff';
  }};
  border-radius: 0.8rem;
  ming-height: 12rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  margin-bottom: 1.5rem;
`;

const MiniTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: bold;
`;

const Label = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
  min-width: 100px;
`;

const ChooseButton = styled.button`
  outline: none;
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 6rem;
  transition: 0.2s all;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

const StyledResults = styled.div`
  padding: 10px 0;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledResultsItem = styled.div`
  display: flex;
  align-items: center;

  > svg {
    height: 24px;
  }

  > * {
    margin-left: 5px;
  }
`;

export default ImportSessionsPrepareStep;
