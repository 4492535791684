import React from 'react';
import { any, string, oneOfType, node, array, func, bool } from 'prop-types';
import styled, { css } from 'styled-components';

import { ReactComponent as BackArrow } from '../assets/icons/back-arrow.svg';

import CustomButton from './reusable/Buttons/Button';
import ActionsDropdown from './reusable/ActionsDropdown';
import Breadcrumbs from './reusable/Breadcrumbs';

const PageWrapper = ({
  title,
  additionalInfo,
  buttons,
  uppercaseButtons,
  backButtonHandler,
  dropdownActions,
  breadcrumbs,
  className,
  children,
  style,
}) => {
  return (
    <Container className={className} style={style}>
      {Boolean(breadcrumbs.length) && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Header>
        <TitleWrapper>
          {backButtonHandler && (
            <BackButtonWrapper onClick={backButtonHandler}>
              <BackArrow />
            </BackButtonWrapper>
          )}
          <Title>{title}</Title>
        </TitleWrapper>
        <ButtonsWrapper>
          {additionalInfo}
          {buttons.map((item) => (
            <StyledButton
              key={item.name}
              handler={item.handler}
              disabled={item.isDisabled}
              variant="rounded"
              type="button"
              uppercaseButtons={uppercaseButtons}
            >
              {item.label}
            </StyledButton>
          ))}
          {Boolean(dropdownActions.length) && <StyledActionsButton actions={dropdownActions} bigVariant />}
        </ButtonsWrapper>
      </Header>
      {children}
    </Container>
  );
};

PageWrapper.propTypes = {
  buttons: array,
  title: oneOfType([string, node]).isRequired,
  additionalInfo: oneOfType([string, node]),
  backButtonHandler: func,
  children: any.isRequired,
  dropdownActions: array,
  breadcrumbs: array,
  uppercaseButtons: bool,
  className: string,
  className: any,
};

PageWrapper.defaultProps = {
  buttons: [],
  dropdownActions: [],
  breadcrumbs: [],
  backButtonHandler: undefined,
  uppercaseButtons: false,
  additionalInfo: null,
  className: '',
  style: {},
};
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 2%;
  width: 100%;
  max-width: calc(100vw - 20rem);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 36px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: bold;
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 2rem;
  min-width: 12rem;
  ${(props) =>
    props.uppercaseButtons &&
    css`
      text-transform: uppercase;
    `}
`;

const BackButtonWrapper = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 3px solid ${(props) => props.theme.colors.darkBlue2};
  min-width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;
  transition: all 0.2s;

  :hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  :active {
    transform: scale(0.95);
  }
`;

const StyledActionsButton = styled(ActionsDropdown)`
  margin-left: 1rem;

  button {
    padding: 1.1rem 1.7rem;
  }
`;

export default PageWrapper;
