import { push } from 'redux-first-history';
import { subSeconds } from 'date-fns';
import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';

import apiInstance2 from '../../services/apiService';
import defaultTableState from '../../constants/defaultTableState';
import { fetchRespondentById } from '../respondents/actions';

import generateQueryString from '../generateQueryString';
import reportsColumns from '../../table/columns/reports';

import { fetchUser } from '../user/actions';

import createToastNotification from '../../utils/createToastNotification';
import isChineseEnv from '../../utils/isChineseEnv';

export const createReportNotification = (notification, options, dispatch) => {
  const { type, title } = options;
  const respondentName = isChineseEnv
    ? `${notification.familyName || ''}${notification.firstName}`
    : `${notification.firstName} ${notification.familyName}`;
  const successMessage = I18n.t('completedAssessmentToast', { respondentName, assessment: notification.questionnaire });
  const failureMessage = `${respondentName}, ${notification.questionnaire}`;
  createToastNotification({
    title,
    message: type === 'success' || type === 'info' ? successMessage : failureMessage,
    type,
    options: {
      onClick: type === 'info' ? () => undefined : () => dispatch(push('/reports')),
    },
  });
};

export const setSelectedReports = createAction('SET_SELECTED_REPORTS', (reports) => ({ reports }));

// ---------------- FILTERS -----------------
export const setReportsSearchValue = createAction('SET_REPORTS_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setReportsFilterValue = createAction('SET_REPORTS_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setReportsFilterType = createAction('SET_REPORTS_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteReportsAppliedFilter = createAction('DELETE_REPORTS_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applyReportsFilters = createAction('APPLY_REPORTS_FILTERS');
export const clearReportsFilters = createAction('CLEAR_REPORTS_FILTERS');

// ---------  NETWORK RELATED ----------
export const setActiveReportsTab = createAction('SET_ACTIVE_REPORTS_TAB', (tab) => ({ tab }));

export const fetchReportsStart = createAction('FETCH_REPORTS_START');
export const fetchReportsSuccess = createAction('FETCH_REPORTS_SUCCESS', (data, tableParams, shouldReset) => ({
  data,
  tableParams,
  shouldReset,
}));
export const fetchReportsFailure = createAction('FETCH_REPORTS_FAILURE');

export const fetchReportsCountStart = createAction('FETCH_REPORTS_COUNT_START');
export const fetchReportsCountSuccess = createAction('FETCH_REPORTS_COUNT_SUCCESS', (data) => ({ data }));
export const fetchReportsCountFailure = createAction('FETCH_REPORTS_COUNT_FAILURE');

// DELETE REPORT
export const deleteReportsStart = createAction('DELETE_REPORTS_START');
export const deleteReportsSuccess = createAction('DELETE_REPORTS_SUCCESS', (report) => ({ report }));
export const deleteReportsFailure = createAction('DELETE_REPORTS_FAILURE');

export const fetchReportsChangesStart = createAction('FETCH_REPORTS_CHANGES_START');
export const fetchReportsChangesSuccess = createAction('FETCH_REPORTS_CHANGES_SUCCESS');
export const fetchReportsChangesFailure = createAction('FETCH_REPORTS_CHANGES_FAILURE');

// MARK REPORTS AS VIEWED
export const markReportsAsViewedStart = createAction('MARK_REPORTS_AS_VIEWED_START');
export const markReportsAsViewedSuccess = createAction('MARK_REPORTS_AS_VIEWED_SUCCESS', (reportsIds) => ({
  reportsIds,
}));
export const markReportsAsViewedFailure = createAction('MARK_REPORTS_AS_VIEWED_FAILURE');

export const fetchAvailableReportsLanguagesStart = createAction('FETCH_AVAILABLE_REPORTS_LANGUAGES_START');
export const fetchAvailableReportsLanguagesSuccess = createAction(
  'FETCH_AVAILABLE_REPORTS_LANGUAGES_SUCCESS',
  (languages) => ({ languages }),
);
export const fetchAvailableReportsLanguagesFailure = createAction('FETCH_AVAILABLE_REPORTS_LANGUAGES_FAILURE');

export const requestReportStart = createAction('REQUEST_REPORT_START');
export const requestReportSuccess = createAction('REQUEST_REPORT_SUCCESS');
export const requestReportFailure = createAction('REQUEST_REPORT_FAILURE');

export const fetchReports = (tableState = defaultTableState, { callback, shouldReset } = {}) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(fetchReportsStart());
    const finalObj = { ...tableState };
    const { tabs, activeTab } = getState().reports;
    if (finalObj.sortBy) {
      const sortingObj = finalObj.sortBy[0];
      const ColumnIndex = reportsColumns.findIndex(
        (item) => item.Header === sortingObj.id || item.name === sortingObj.id,
      );
      const Order = sortingObj.desc ? 1 : 0;
      finalObj.sortBy = { ColumnIndex, Order };
    }
    const queryString = generateQueryString(finalObj, reportsColumns);
    const activeType = tabs.findIndex((item) => item.name === activeTab.name);
    const response = await apiInstance2.get(`/api/v2/Reports?reportType=${activeType}&${queryString}`);
    if (response.status === 200) {
      dispatch(fetchReportsSuccess(response.data, tableState, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchReportsFailure(e));
    if (callback) callback(e);
  }
};

export const fetchReportsChanges = () => async (dispatch, getState) => {
  try {
    dispatch(fetchReportsChangesStart());
    const {
      reports: { reports, lastTableParams },
      respondents: { respondentToEdit },
    } = getState();
    const currentDate = new Date();
    const from = subSeconds(currentDate, 60).toISOString();
    const to = currentDate.toISOString();
    const response = await apiInstance2.get(
      `/api/v2/Reports/changes?includeRespondents=false&fromDateTime=${from}&toDateTime=${to}`,
    );
    if (response.status === 200) {
      dispatch(fetchReportsChangesSuccess());
      response.data.notifications.forEach((notification) => {
        if (notification.notificationType === 1 || notification.error) return;
        const type = notification.error ? 'error' : 'success';
        if (type === 'success') {
          const isAlreadyInView = reports.find(
            (item) => (item.requestID || item.reportRequestID) === notification.requestID,
          );
          const isOnEditRespondentScreen =
            respondentToEdit &&
            respondentToEdit.reports.find(
              (item) => (item.requestID || item.reportRequestID) === notification.requestID,
            );
          if (isAlreadyInView) dispatch(fetchReports(lastTableParams, { shouldReset: true }));
          if (isOnEditRespondentScreen) dispatch(fetchRespondentById(respondentToEdit.respondentID));
        }
        const title = type === 'success' ? 'Report has been completed!' : 'Report generation error';
        createReportNotification(notification, { type, title }, dispatch);
      });
    }
  } catch (e) {
    console.log('e', e);
    dispatch(fetchReportsChangesFailure(e));
  }
};

export const deleteReports = (data, cb) => async (dispatch) => {
  dispatch(deleteReportsStart());
  try {
    const response = await apiInstance2.delete('/api/v2/Reports', { data });
    if (response.status === 200) {
      dispatch(deleteReportsSuccess());
      createToastNotification({ message: I18n.t('reportsDeletionToast') });
      cb();
    }
  } catch (err) {
    dispatch(deleteReportsFailure(err.message));
    cb(err);
  }
};

export const fetchAvailableReportsLanguages = () => async (dispatch) => {
  try {
    dispatch(fetchAvailableReportsLanguagesStart());
    const response = await apiInstance2.get(`/api/v2/Reports/languages`);
    if (response.status === 200) {
      dispatch(fetchAvailableReportsLanguagesSuccess(response.data));
    }
  } catch (e) {
    dispatch(fetchAvailableReportsLanguagesFailure(e));
  }
};

export const requestReport = (data, cb) => async (dispatch) => {
  try {
    dispatch(requestReportStart());
    const response = await apiInstance2.post(`/api/v2/Reports/reportrequests`, data);
    if (response.status === 200) {
      dispatch(requestReportSuccess());
      createToastNotification({ message: I18n.t('reportRequestCreationToast') });
      cb();
    }
  } catch (e) {
    dispatch(requestReportFailure(e));
    cb(e);
  } finally {
    dispatch(fetchUser());
  }
};

export const fetchReportsCount = () => async (dispatch) => {
  try {
    dispatch(fetchReportsCountStart());
    const response = await apiInstance2.get('/api/v2/Reports/count');
    if (response.status === 200) {
      dispatch(fetchReportsCountSuccess(response.data));
    }
  } catch (e) {
    dispatch(fetchReportsCountFailure(e));
  }
};

export const markReportsAsViewed = (reportsIds) => async (dispatch) => {
  try {
    dispatch(markReportsAsViewedStart());
    const response = await apiInstance2.post(`/api/v2/Notifications/viewed`, reportsIds);
    if (response.status === 200) {
      dispatch(markReportsAsViewedSuccess(reportsIds));
    }
  } catch (e) {
    dispatch(markReportsAsViewedFailure(e));
  }
};
