import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';
import raterRoles from '../../constants/raterRoles';
import isChineseEnv from '../../utils/isChineseEnv';

const statusesStyles = {
  invited: {
    title: 'Invited',
    background: '#DFF6FF',
    color: '#008AC0',
  },
  completed: {
    title: 'Completed',
    background: '#ECF6EF',
    color: '#27AE60',
  },
  notInvited: {
    title: 'Not invited',
    background: '#F9F9F9',
    color: '#5B5B5B',
  },
  notCompleted: {
    title: 'Not completed',
    background: '#F9F9F9',
    color: '#5B5B5B',
  },
};

const StatusCell = ({ row, type }) => {
  const inviteStatuses = ['notInvited', 'invited'];
  const completeStatuses = ['notCompleted', 'completed'];

  const status =
    type === 'invite' ? inviteStatuses[row.original.inviteStatus] : completeStatuses[row.original.completeStatus];
  const styles = statusesStyles[status];

  return (
    <StyledStatus style={{ color: styles.color, backgroundColor: styles.background }}>
      {I18n.t(styles.title)}
    </StyledStatus>
  );
};

const columns = (activeProjectInView) => {
  const roleAliases = raterRoles(activeProjectInView);

  return [
    {
      Header: I18n.t('PersonName'),
      accessor: (data) =>
        isChineseEnv ? `${data.familyName || ''}${data.firstName}` : `${data.firstName} ${data.familyName || ''}`,
      name: 'name',
      type: 'text',
      filteringName: 'Name',
      sortType: caseInsensitiveSortingFunc,
    },
    {
      Header: I18n.t('Email Address'),
      accessor: 'email',
      name: 'email',
      type: 'text',
      filteringName: 'Email',
      sortType: caseInsensitiveSortingFunc,
    },
    {
      Header: I18n.t('Role'),
      accessor: (data) => `${roleAliases[data.role].label}`,
      name: 'role',
      type: 'select',
      filteringName: 'Role',
      options: roleAliases.map((role, index) => ({ value: index, label: role.label })),
    },
    {
      Header: I18n.t('Invite Status'),
      accessor: 'inviteStatus',
      name: 'inviteStatus',
      type: 'select',
      options: [
        { value: 0, label: I18n.t('Not Invited') },
        { value: 1, label: I18n.t('Invited') },
      ],
      filteringName: 'InviteStatus.Value',
      Cell: (props) => <StatusCell {...props} type="invite" />,
      width: 120,
    },
    {
      Header: I18n.t('Complete Status'),
      accessor: 'completeStatus',
      name: 'completeStatus',
      type: 'select',
      options: [
        { value: 0, label: I18n.t('Not completed') },
        { value: 1, label: I18n.t('Completed') },
      ],
      Cell: (props) => <StatusCell {...props} type="complete" />,
      filteringName: 'CompleteStatus.Value',
      width: 120,
    },
    // {
    //   Header: I18n.t(''),
    //   name: 'actions',
    //   type: 'actions',
    //   id: 'actions',
    //   disableSortBy: true,
    //   Cell: ({ row }) => (
    //     <ActionsDropdown
    //       actions={rowActions.map((item) => ({
    //         ...item,
    //         handler: () => {
    //           console.log('item', item);
    //           item.handler(row.original);
    //         },
    //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
    //       }))}
    //       clickHandler={() => setSelectedRaters(row.original)}
    //     />
    //   ),
    //   width: 50,
    // },
  ];
};

const StyledStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 2px 14px;
  height: 14px;
  min-width: 11rem;
  display: inline;
  align-self: flex-start;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 4px;
`;

export default columns;
