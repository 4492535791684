import React, { useState, useMemo, useEffect, useRef } from 'react';
import { array, object, func, bool, number } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import CustomSelect from '../../reusable/Selects/Select';
import CompactSelect from '../../reusable/Selects/CompactSelect';

import ReportsSet, { findIfReportIsDisabled } from '../../Respondents/RequestReportModal/Set';
import Solution from '../../Respondents/RequestReportModal/Solution';

import ReportsCreditsCost from '../../reusable/ReportsCreditsCost';
import CustomButton from '../../reusable/Buttons/Button';

import {
  parseSolutionNorms,
  generateReportNorms,
  generateReportIdealProfile,
  getTestsWithoutNormSelected,
  selectedTestsNeedsIdealProfile,
} from '../../Respondents/RequestReportModal';

const defineDependencies = (sets) => {
  const reports = sets.reduce((acc, item) => [...acc, ...item.reports], []);
  const checkedReports = reports.filter((item) => item.isChecked);
  if (!checkedReports.length)
    return {
      normsDependencies: [],
      profilesDependencies: [],
    };
  const normsDependencies = checkedReports
    .filter((item) => !item.isSolutionReport)
    .reduce((acc, item) => [...acc, ...item.dependencies.split(';')], []);
  const profilesDependencies = checkedReports
    .filter((item) => item.isIdealProfileReport)
    .reduce((acc, item) => [...acc, ...item.ipDependencies.split(';')], []);
  return {
    normsDependencies: new Array(...new Set(normsDependencies)),
    profilesDependencies: new Array(...new Set(profilesDependencies)),
  };
};

const GroupReportsList = ({
  languages,
  language,
  setLanguage,
  reports,
  commonTests,
  norms,
  idealProfiles,
  setParentReportSets,
  onNormChange,
  onIdealProfileChange,
  credits,
  totalCost,
  isEnoughCredits,
  toPrevStep,
  multiStep,
  isLoading,
  onSubmit,
  commonAssessment,
}) => {
  const [data, setData] = useState(reports.map((item) => ({ ...item, isOpen: false, isChecked: false })));
  const hasSelectedItems = data.filter((report) => report.isChecked).length > 0;
  const reportsListRef = useRef(null);

  const [shoudDisableRequestButton, setRequestButtonStatus] = useState(true);

  useEffect(() => {
    setData(reports.map((item) => ({ ...item, isOpen: false, isChecked: false })));
  }, [reports]);

  useEffect(() => {
    setParentReportSets(data);
  }, [data]);

  const onSetClick = (id) => {
    setData((prev) => prev.map((item) => ({ ...item, isOpen: id === item.reportSetID ? !item.isOpen : item.isOpen })));
    const isLast = data[data.length - 1].reportSetID === id;
    if (isLast && !data[data.length - 1].isOpen)
      setTimeout(() => reportsListRef.current.scrollBy({ top: 200, behavior: 'smooth' }), 350);
  };

  const onSetCheckboxClick = (setId) => {
    setData((prev) => {
      return prev.map((report) =>
        report.reportSetID === setId
          ? {
              ...report,
              isChecked: !report.isChecked,
              reports: report.reports.map((item) => ({
                ...item,
                isChecked: findIfReportIsDisabled(item, commonTests, idealProfiles) ? false : !report.isChecked,
              })),
            }
          : report,
      );
    });
  };

  const onSetReportClick = (setId, reportId) => {
    setData((prev) =>
      prev.map((report) => {
        if (report.reportSetID !== setId) return report;
        const wasChildChecked = report.reports.find((report) => report.reportID === reportId).isChecked;
        const checkedChildren = report.reports.filter((report) => report.isChecked);
        return {
          ...report,
          isChecked: !(report.isChecked && wasChildChecked && checkedChildren.length <= 1),
          reports: report.reports.map((report) =>
            report.reportID === reportId ? { ...report, isChecked: !report.isChecked } : report,
          ),
        };
      }),
    );
  };
  const filteredByType = useMemo(() => {
    return data.filter((item) => item.type === 'set');
  }, [data]);

  const deps = defineDependencies(data);
  const normsToShow = deps.normsDependencies.map((key) => (norms[key] ? norms[key] : undefined)).filter((item) => item);
  const profilesToShow = deps.profilesDependencies
    .map((key) => (idealProfiles[key] ? idealProfiles[key] : undefined))
    .filter((item) => item);

  const showOptions = Boolean(normsToShow.length || profilesToShow.length);

  const isRequestButtonDisabled = showOptions
    ? shoudDisableRequestButton || isLoading || !hasSelectedItems || !isEnoughCredits
    : isLoading || !hasSelectedItems || !isEnoughCredits;

  const requestValidation = () => {
    const hasCheckedReports = data.filter((item) => item.isChecked).length > 0;

    if (!hasCheckedReports) return;

    const individualReports = data
      .filter((item) => item.isChecked)
      .reduce((acc, item) => {
        const items = item.reports
          .filter((item) => item.isChecked)
          .map((reportItem) => ({
            reportID: reportItem.reportID,
            reportSetID: item.reportSetID,
            normIDs: reportItem.isSolutionReport
              ? parseSolutionNorms(reportItem.solutionNorms)
              : generateReportNorms(reportItem, norms),
            idealProfileID: generateReportIdealProfile(reportItem, idealProfiles),
            reportType: 0,
          }));
        return [...acc, ...items];
      }, []);

    const payload = {
      reportRequests: individualReports,
      testIDs: commonAssessment.testIDs,
    };

    const missingNormPerReport = getTestsWithoutNormSelected(payload.reportRequests);
    const missingIdealProfilePerReport = selectedTestsNeedsIdealProfile(payload.reportRequests);

    if (missingNormPerReport.length > 0 || missingIdealProfilePerReport.length > 0) {
      setRequestButtonStatus(true);
    } else {
      setRequestButtonStatus(false);
    }
  };

  useEffect(() => {
    requestValidation();
  }, [norms, idealProfiles, data]);
  return (
    <Container>
      <CustomSelect
        options={languages}
        placeholder={I18n.t('Language')}
        selectProps={{
          value: language,
          onChange: setLanguage,
        }}
      />
      <MainSectionWrapper>
        <ReportsWrapper showOptions={showOptions}>
          {Boolean(filteredByType.length) && <ListTitle>{I18n.t('Available Reports')}</ListTitle>}
          <ReportsItemsList ref={reportsListRef}>
            {filteredByType.map((item) => {
              return item.isSolutionReportSet ? (
                <Solution
                  key={item.reportSetID}
                  solution={item}
                  onSolutionClick={onSetClick}
                  onReportClick={onSetReportClick}
                  onSolutionCheckboxClick={onSetCheckboxClick}
                  idealProfiles={idealProfiles}
                />
              ) : (
                <ReportsSet
                  key={item.reportSetID}
                  set={item}
                  onReportClick={onSetReportClick}
                  onSetCheckboxClick={onSetCheckboxClick}
                  onSetClick={onSetClick}
                  commonTests={commonTests}
                  idealProfiles={idealProfiles}
                />
              );
            })}
          </ReportsItemsList>
        </ReportsWrapper>
        <OptionsWrapper showOptions={showOptions}>
          {showOptions && <ListTitle>{I18n.t('Options')}</ListTitle>}
          {Boolean(normsToShow.length) && (
            <NormsList areProfilesVisible={Boolean(profilesToShow.length)}>
              {normsToShow.map((norm) => {
                return (
                  <CompactSelect
                    key={`${norm.normID}${norm.name}`}
                    options={norm.options}
                    label={`${norm.name} ${I18n.t('Norm')}`}
                    selectProps={{ value: norm.value, onChange: (val) => onNormChange(norm.name, val) }}
                  />
                );
              })}
            </NormsList>
          )}
          {Boolean(profilesToShow.length) && (
            <IdealProfilesList areNormsVisible={Boolean(normsToShow.length)}>
              {profilesToShow.map((idealProfile) => {
                return (
                  <CompactSelect
                    key={idealProfile.idealProfileID}
                    options={idealProfile.options}
                    label={`${idealProfile.name} ${I18n.t('Ideal Profile')}`}
                    selectProps={{
                      value: idealProfile.value,
                      onChange: (val) => onIdealProfileChange(idealProfile.name, val),
                    }}
                  />
                );
              })}
            </IdealProfilesList>
          )}
        </OptionsWrapper>
      </MainSectionWrapper>
      <Footer>
        <ReportsCreditsCost creditsCost={totalCost} creditsAvailable={credits} />
        {multiStep ? (
          <ButtonsWrapper>
            <PrevButton variant="secondary" handler={toPrevStep}>
              {I18n.t('Previous')}
            </PrevButton>
            <CustomButton variant="primary" disabled={isRequestButtonDisabled} handler={() => onSubmit(data)}>
              {I18n.t('Request')}
            </CustomButton>
          </ButtonsWrapper>
        ) : (
          <CustomButton variant="primary" disabled={isRequestButtonDisabled} handler={() => onSubmit(data)}>
            {I18n.t('Request')}
          </CustomButton>
        )}
      </Footer>
    </Container>
  );
};

GroupReportsList.propTypes = {
  languages: array.isRequired,
  language: object,
  setLanguage: func.isRequired,
  reports: array.isRequired,
  commonTests: array.isRequired,
  norms: object.isRequired,
  idealProfiles: object.isRequired,
  setParentReportSets: func.isRequired,
  onNormChange: func.isRequired,
  onIdealProfileChange: func.isRequired,
  credits: number.isRequired,
  totalCost: number.isRequired,
  isEnoughCredits: bool.isRequired,
  toPrevStep: func.isRequired,
  multiStep: bool.isRequired,
  isLoading: bool.isRequired,
  onSubmit: func.isRequired,
  commonAssessment: object.isRequired,
};

GroupReportsList.defaultProps = {
  language: {
    value: 'EN',
    code: 'EN',
    label: 'English',
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1rem;
`;

const MainSectionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const ReportsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.showOptions ? '60%' : '100%')};
  transition: width 0.3s;
  max-height: 36rem;
  height: calc(100vh - 30rem);
`;

const ReportsItemsList = styled.div`
  display: block;
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.showOptions ? '38%' : '0')};
  opacity: ${(props) => (props.showOptions ? 1 : 0)};
  transition: width, opacity 0.3s;
  max-height: 36rem;
`;

const NormsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 7px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
  height: ${(props) => {
    const baseHeight = 340;
    return props.areProfilesVisible ? `${baseHeight - 100}px` : 'auto';
  }};
  margin-bottom: ${(props) => (props.areProfilesVisible ? '5px' : 0)};
  flex-grow: 3;
`;

const IdealProfilesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 7px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
  height: ${(props) => (props.areNormsVisible ? `100px` : 'auto')};
  flex-grow: 1;
`;

const ListTitle = styled.span`
  font-size: ${(props) => props.theme.fontSizes.normal};
  line-height: 2rem;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    text-transform: uppercase;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const PrevButton = styled(CustomButton)`
  margin-right: 10px;
`;

export default GroupReportsList;
