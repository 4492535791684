import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import CustomInput from '../reusable/FormComponents/Input';
import Spinner from '../reusable/Spinner';
import CustomButton from '../reusable/Buttons/Button';
import { errorMessages } from '../../constants/errorMessages';

import { sendResetEmail } from '../../store/user/actions';
import storageService from '../../services/storageService';
import LogoSVG from '../reusable/Logo_SVG';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.emailField)
    .required(I18n.t(errorMessages.inputField)),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const [isLoading, setLoadingState] = useState(false);
  const [showSuccessStep, setSuccessStepStatus] = useState(false);

  const partnerInfo = useSelector((state) => state.user.partnerInfo);
  const [customLogo, setCustomLogo] = useState('');
  useEffect(() => {
    let localstorageFlag = false;
    let userCustomLogo = '';
    const storageCustomLogos = JSON.parse(storageService.getItem('customLogos'));
    const partnerLogo = storageCustomLogos.fullColor;
    if (partnerLogo.toLowerCase().includes('psytech')) {
      setCustomLogo('');
      return;
    }
    if (storageCustomLogos && !localstorageFlag) {
      userCustomLogo = partnerLogo;
      setCustomLogo(userCustomLogo);
      localstorageFlag = true;
    }
    if (!localstorageFlag && partnerInfo?.logoUrlColor) {
      userCustomLogo = partnerInfo?.logoUrlColor;
      setCustomLogo(userCustomLogo);
    }
    return () => {};
  }, [partnerInfo]);

  const { handleSubmit, handleChange, values, errors, setErrors } = useFormik({
    validationSchema,
    validateOnChange: false,
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      setLoadingState(true);
      dispatch(
        sendResetEmail(email, (e) => {
          if (e) {
            setErrors({ email: e.message.includes('404') ? 'User was not found' : e.message });
          } else {
            setSuccessStepStatus(true);
          }
          setLoadingState(false);
        }),
      );
    },
  });

  const onInputChange = (event) => {
    handleChange(event);
    setErrors({ email: '' });
  };

  return (
    <BgContainer>
      <Spinner isLoading={isLoading} />
      <ContentWrapper>
        {/* <Logo marginBottom="0" /> */}
        {customLogo ? <LogoImage src={customLogo} alt="Custom Logo" /> : <StyledLogo />}
        <Title showSuccessStep={showSuccessStep}>
          {' '}
          {showSuccessStep ? I18n.t('Email sent') : I18n.t('Forgot password?')}
        </Title>
        {showSuccessStep ? (
          <>
            <Paragraph>
              {I18n.t('You should receive your reset link soon at')} <b>{values.email}</b>.
            </Paragraph>
            <Divider />
            <TinyTitle>{I18n.t("Can't find the email?")}</TinyTitle>
            <TinyParagraph>{I18n.t('You should also check your spam folder')}.</TinyParagraph>
          </>
        ) : (
          <>
            <StyledInput
              error={errors.email}
              inputName={I18n.t('Email address')}
              id="email"
              onChange={onInputChange}
              value={values.email}
            />
            <StyledButton handler={handleSubmit}>{I18n.t('Request a reset link')}</StyledButton>
            <BackToLoginLink onClick={() => dispatch(push('/login'))}>{I18n.t('Back to login page')}</BackToLoginLink>
          </>
        )}
      </ContentWrapper>
    </BgContainer>
  );
};

ForgotPassword.propTypes = {};

const BgContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.2rem;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 44px #00000012;
  border-radius: 10px;
  width: 42rem;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin: ${(props) => (props.showSuccessStep ? '4rem 0 0.8rem 0' : '4rem 0 2.4rem 0')};
`;

const StyledInput = styled(CustomInput)`
  margin-bottom: 3.2rem;
`;

const StyledButton = styled(CustomButton)`
  margin-bottom: 2.4rem;
  text-transform: uppercase;
`;

const BackToLoginLink = styled.span`
  font-size: ${(props) => props.theme.fontSizes.default};
  color: ${(props) => props.theme.colors.mediumBlue};
  text-align: center;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Paragraph = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.default};
`;

const Divider = styled.div`
  background-color: rgba(0, 0, 0, 0.12);
  width: 8rem;
  height: 1px;
  margin: 1.6rem 0;
`;

const TinyTitle = styled.h3`
  margin: 0 0 0.8rem 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: bold;
`;

const TinyParagraph = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
`;

const LogoImage = styled.img`
  scale: 0.6;
  margin-top: 20px;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.menuBg};
`;

const StyledLogo = styled(LogoSVG)`
  margin-bottom: 30px;
  margin-left: 1rem;
  min-height: 35px;
  svg {
    fill: ${(props) => props.theme.colors.menuBg};
    height: 80px;
    scale: 1.3;
  }
`;

export default ForgotPassword;
