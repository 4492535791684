import React, { useState, useEffect } from 'react';
import { func, number, string, oneOfType } from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';

import Modal from '../../reusable/NewModal';
import CustomSelect from '../../reusable/Selects/Select';
import AsyncSelect from '../../reusable/Selects/AsyncSelect';
import CustomButton from '../../reusable/Buttons/Button';

import { fetchBriefGroups } from '../../../store/norms/actions';
import { fetchUser } from '../../../store/user/actions';
import { addRespondentToAssessmentProject, addGroupToAssessmentProject } from '../../../store/projects/actions';
import createToastNotification from '../../../utils/createToastNotification';

import { ReactComponent as InfoIcon } from '../../../assets/icons/warning-blue.svg';
import isChineseEnv from '../../../utils/isChineseEnv';

const PAGE_SIZE = 20;

const additionOptions = [
  {
    value: 'respondent',
    label: I18n.t('Respondent'),
  },
  {
    value: 'group',
    label: I18n.t('Group'),
  },
];

const AssessmentProjectAddRespondentsModal = ({ onClose, projectId, onSuccess, costPerRespondent }) => {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.norms.groups);

  const [selectedRespondent, setSelectedRespondent] = useState(null);
  const [isSubmitting, setSubmittingState] = useState(false);

  const [additionType, setAdditionType] = useState(additionOptions[1]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    dispatch(fetchBriefGroups());
  }, []);

  const fetchRespondents = async (inputValue, pageIndex) => {
    try {
      const response = await apiInstance2.get(
        `/api/v2/360/projects/${projectId}/available-respondents-for-sessions?PageNumber=${pageIndex}&PageSize=${PAGE_SIZE}&SearchText=${inputValue}`,
      );
      return { respondents: response.data.data, totalCount: response.data.totalCount };
    } catch (e) {
      console.log('Error while fetching respondents');
    }
  };

  const loadOptions = async (inputValue, loadedOptions) => {
    const { respondents, totalCount } = await fetchRespondents(inputValue, pageIndex);
    const options = respondents.map((item) => ({
      value: item.respondentId,
      label: `${isChineseEnv ? `${item.familyName || ''}${item.firstName}` : `${item.firstName} ${item.familyName}`} 
      (${item.email ? item.email : I18n.t('No email')})`,
      email: item.email,
    }));
    setPageIndex((prev) => prev + 1);
    return {
      options,
      hasMore: loadedOptions.length < totalCount - PAGE_SIZE,
    };
  };

  const onChange = (data) => {
    setSelectedRespondent(data);
  };

  const onTypeChange = (newType) => {
    setAdditionType(newType);
    setSelectedGroup(null);
    setSelectedRespondent(null);
    setPageIndex(0);
    setSelectValue('');
  };

  const onSave = () => {
    setSubmittingState(true);
    const func = additionType.value === 'respondent' ? addRespondentToAssessmentProject : addGroupToAssessmentProject;
    const arg = additionType.value === 'respondent' ? [selectedRespondent.value] : selectedGroup.value;
    dispatch(
      func(projectId, arg, (err) => {
        setSubmittingState(false);
        if (err?.message.includes('422'))
          createToastNotification({
            title: 'Error',
            message: 'Respondent is already added to the project',
            type: 'error',
          });
        if (!err) {
          dispatch(fetchUser());
          onSuccess();
          onClose();
        }
      }),
    );
  };

  const onSelectInputChange = (value) => {
    setSelectValue(value);
    setPageIndex(0);
  };

  return (
    <StyledModal title={I18n.t('Add respondents groups or respondents')} isVisible onClose={onClose}>
      <Description>{I18n.t('Select group or respondents to be added to this project')}</Description>
      <SelectsWrapper>
        <StyledSelect
          placeholder={I18n.t('Add')}
          options={additionOptions}
          selectProps={{ value: additionType, onChange: onTypeChange }}
        />
        {additionType.value === 'respondent' ? (
          <StyledAsyncSelect
            placeholder={I18n.t('Select respondent')}
            loadOptions={loadOptions}
            selectProps={{ onChange, onInputChange: onSelectInputChange, inputValue: selectValue }}
          />
        ) : (
          <StyledSelect
            placeholder={I18n.t('Select group')}
            options={groups}
            selectProps={{ value: selectedGroup, onChange: setSelectedGroup }}
          />
        )}
      </SelectsWrapper>
      <InfoBox>
        <StyledInfoIcon />
        <CreditsCost>{costPerRespondent}</CreditsCost>
        <span>
          {I18n.t('credits per respondent will be frozen from your balance to ensure the project completion')}
        </span>
      </InfoBox>
      <ButtonsWrapper>
        <Button handler={onSave} disabled={(!selectedRespondent && !selectedGroup) || isSubmitting}>
          {I18n.t('Add respondents')}
        </Button>
      </ButtonsWrapper>
    </StyledModal>
  );
};

AssessmentProjectAddRespondentsModal.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
  projectId: oneOfType([string, number]).isRequired,
  costPerRespondent: number.isRequired,
};

const StyledModal = styled(Modal)`
  height: 36rem;
  width: 66rem;
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.small};
  margin-bottom: 2.4rem;
`;

const SelectsWrapper = styled.div`
  //flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelect = styled(CustomSelect)`
  width: 48%;
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  width: 48%;
`;

const InfoBox = styled.div`
  background-color: #e0f1f7;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.mediumBlue};
  display: flex;
  align-items: center;
  padding: 1.4rem;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.mediumBlue};
  font-weight: 500;
  margin-bottom: 4rem;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-right: 1rem;
`;

const CreditsCost = styled.span`
  font-weight: bold;
  margin-right: 0.4rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  min-width: 12rem;
  text-transform: uppercase;
  letter-spacing: 1.25px;
`;

export default AssessmentProjectAddRespondentsModal;
